import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import DataContext from "../../utils/Context/DataContext";
import { api } from "../../utils/Api";
import Pickup from "./Pickup";
import Delivery from "./Delivery";
import PickupFeedback from "./PickupFeedback";
import Products from "./Products";
import Package from "./Package";
import DeliveryFeedback from "./DeliveryFeedback";
import CollectCashDelivery from "./CollectCashDelivery";
import { mobileApp } from "../../utils/MobileApp";
import Settlement from "./Settlement";
import OrderChat from "./OrderChat";
import ModalOrderDelayed from "./ModalOrderDelayed";

let app_platform = "web";

export default function Order(props: any) {
  const navigate = useNavigate();
  const context: any = useContext(DataContext);
  const [orderDetails, setOrderDetails]: any = useState({});
  const [allData, setAllData]: any = useState({});
  const [params, setParmas] = useState(useParams());
  const [showPickup, setShowPickup]: any = useState(false);
  const [showPayment, setShowPayment]: any = useState(false);
  const [showDelivery, setShowDelivery]: any = useState(false);
  const [statusBtnLoader, setStatusBtnLoader] = useState(false);

  const [chatModal, SetChatModal]: any = useState({
    is_open: false,
    id: "",
    data: {},
  });
  const [odelayedModal, SetOdelayedModal]: any = useState({
    is_open: false,
    id: "",
    order: {},
  });

  useEffect(() => {
    order_details();

    return () => {};
  }, []);
  useEffect(() => {
    if(orderDetails?.id){
      if(orderDetails?.delayed_message?.length > 0){
        SetOdelayedModal((prevValue: any) => {
          prevValue.is_open = true;
          prevValue.order = orderDetails;
          return { ...prevValue };
        });
      }
    }
  }, [orderDetails]);

  async function order_details() {
    let pass_data = {
      get: {
        order_id: params.id,
      },
    };
    let data: any = await api("/company/orders", pass_data);

    if (data.status_code == 200) {
      setAllData(data.response);
      setOrderDetails(data.response.data[0]);
      if(data.response.data[0].status_id == "0" && orderDetails.cancelled == false && orderDetails.expired == false){
        navigate("/order_details/" + data.response.data[0].id);
      }
    }
  }
  function handleClick(type: any) {
    if (type == "pickup") {
      if (orderDetails.status_id >= 6 || orderDetails.cancelled || orderDetails.expired || orderDetails.status_id == 2) {
        setShowPickup(true);
      } else {
        navigate("/order_details/" + orderDetails.id);
      }
    } else if (type == "payment") {
      setShowPayment(true);
    } else if (type == "delivery") {
      if ((orderDetails.status_id > 5 && orderDetails.status_id < 9) && !orderDetails.cancelled && !orderDetails.expired && orderDetails.status_id != 2) {
        navigate("/order_details/" + orderDetails.id);
      } else {
        setShowDelivery(true);
      }
    }
  }
  async function status_update_mobile(status: any = '', ack:any = false) {
    let pass_data: any;
    if(ack){
      pass_data = {
        post: {
          order_id: params.id,
          seen_cancelled: 1,
        },
      };
    } else {
      pass_data = {
        post: {
          order_id: params.id,
          status: status,
        },
      };
    }
    
    let data: any = await api("/company/order_status", pass_data);

    if (data.status_code == 200) {
      let msg = "";
      if(data?.response?.status && ack){
        // msg = "Order Completed";
        if (context.app_data.application.platform == "android") {
          const ring = {
            function: "refreshringnotifications",
            app_type: "react_web",
          };
          mobileApp(ring);
        }
        if(data?.response?.company_details?.brand_id){
          navigate("/company/" + data?.response?.company_details?.brand_id);
        }
      } else if (status == 10) {
        if (data?.response?.status) {
          msg = "Order Completed";
          if(data?.response?.company_details?.brand_id){
            navigate("/company/" + data?.response?.company_details?.brand_id);
          }
        }
      }
      // if (msg != "") {
      //   toast.success(msg);
      // }
      if (data?.response?.data[0]) {
        setOrderDetails(data?.response?.data[0]);
        setAllData(data);
      }
      setStatusBtnLoader(false);
    } else {
      setStatusBtnLoader(false);
    }
  }
  function update_status(status: any = '', ack:any = false) {
    setStatusBtnLoader(true);
    app_platform = context.app_data.application.platform;
    // status_update_mobile(statusId, imgId);
    if (
      context.app_data.application.platform == "android" ||
      context.app_data.application.platform == "ios" ||
      context.app_data.application.platform == "web"
    ) {
      // mobileApp({
      //   function: "checkdeliverypartnerbglocation",
      //   app_type: "react_web",
      // });
      app_platform = context.app_data.application.platform;
      status_update_mobile(status, ack);

      // mobileApp({
      //   function: "getlocationlatlng",
      //   app_type: "react_web"
      // });
    } else {
      toast.error("This Feature Not Available in Web");
    }
  }
  async function openChat(data:any) {
    SetChatModal({
      is_open: true,
      data: data,
    });
  }
  return (
    <div className="container py-3">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="d-flex mb-3">
        {(showPickup || showPayment || showDelivery) && (
          <div>
            <button
              type="button"
              className="btn text-muted"
              onClick={() => {
                setShowPickup(false);
                setShowPayment(false);
                setShowDelivery(false);
              }}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
          </div>
        )}
        <div>
          {(parseInt(orderDetails.status_id) < 7 && orderDetails.cancelled == false && orderDetails.expired == false && orderDetails.status_id != 2) ? (
            <div className="d-flex">
              <div className="me-3 fw-500">
                <i className="fas fa-biking fs-13 text-muted"></i>{" "}
                {orderDetails.distance} kms
              </div>
              <div className="fw-500">
                <i className="far fa-clock fs-13 text-muted me-1"></i>
                {Math.floor(orderDetails.duration / 60)} mins
              </div>
            </div>
          ) : (
            <div className="fs-13">{orderDetails.time}</div>
          )}
          <div className="fs-13">#{orderDetails.id}</div>
        </div>
        <div className="ms-auto fw-500 text-end">
        {orderDetails.expired == true ? (
          <div className="fs-13 text-white badge alert-badge bg-danger text-uppercase mt-1">{orderDetails.status}</div>
        ):orderDetails.cancelled == true ? (
          <div className="fs-13 text-white badge alert-badge bg-danger text-uppercase mt-1">{orderDetails.status}</div>
        ):(
          <div className="fs-12 text-uppercase badge badge-primary bg-primary">{orderDetails.status}</div>
        )}
          <div>&#x20B9; {orderDetails.amount}</div>
        </div>
      </div>
      {!showPickup && !showPayment && !showDelivery && (
        <div>
          {orderDetails.status_id >= 6 ? (
            <div
              className="box-1 fw-500 p-3 mb-3 cursor"
              onClick={() => handleClick("pickup")}
            >
              <i className="fas fa-check-circle text-green me-1"></i> Pickup
              Completed
            </div>
          ) : (
            <div className="cursor" onClick={() => handleClick("pickup")}>
              <Pickup
                data={orderDetails}
                outer_data={allData}
                order_page={true}
                chatCallback={(data:any) => openChat(data)}
              />
            </div>
          )}

          {orderDetails?.payment_type == 2 &&
            orderDetails?.payment_collected == true && (
              <div
                className="box-1 fw-500 p-3 mb-3 cursor"
                onClick={() => handleClick("payment")}
              >
                <i className="fas fa-check-circle text-green me-1"></i> Payment
                Successfull
              </div>
            )}
          {orderDetails.status_id >= 9 ? (
            <div
              className="box-1 fw-500 p-3 mb-3 cursor"
              onClick={() => handleClick("delivery")}
            >
              <i className="fas fa-check-circle text-green me-1"></i> Delivery
              Completed
            </div>
          ) : (
            <div className="cursor" onClick={() => handleClick("delivery")}>
              <Delivery
                data={orderDetails}
                outer_data={allData}
                order_page={true}
                chatCallback={(data:any) => openChat(data)}
              />
            </div>
          )}
        </div>
      )}
      {showPickup && (
        <div>
          <Pickup data={orderDetails} outer_data={allData} chatCallback={(data:any) => openChat(data)} />
          <PickupFeedback data={orderDetails} outer_data={allData} />
          <Products data={orderDetails} outer_data={allData} />
          <Package data={orderDetails} outer_data={allData} />
        </div>
      )}
      {showPayment && (
        <div>
          <CollectCashDelivery data={orderDetails} outer_data={allData} />
        </div>
      )}
      {showDelivery && (
        <div>
          <Delivery data={orderDetails} outer_data={allData} chatCallback={(data:any) => openChat(data)} />
          <DeliveryFeedback data={orderDetails} outer_data={allData} />
        </div>
      )}

      <Settlement settlement={orderDetails} />

      <ModalOrderDelayed
        open={odelayedModal.is_open}
        order={odelayedModal.order}
        close={(data: any) => {
          SetOdelayedModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          
          if (data.action == "save") {
            order_details();
          }
        }}
      />
      <OrderChat
        open={chatModal.is_open}
        data={chatModal.data}
        close={(data: any) => {
          SetChatModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            // new_order();
          }
        }}
      />

      {(orderDetails.hasOwnProperty("expired") &&
        orderDetails.expired == false && orderDetails.hasOwnProperty("cancelled") &&
        orderDetails?.cancelled == false) && (
          <div className="bottom-buttons">
            {orderDetails.status_id == "9" && (
              <button
                type="button"
                className="btn btn-primary btn-lg br-0 w-100 py-13"
                onClick={() => {
                  update_status(10);
                }}
              >
                {statusBtnLoader ? (
                  <span className="btn-loader"></span>
                ) : (
                  <span>
                    Order Completed
                  </span>
                )}
              </button>
            )}
          </div>
        )}
        { (orderDetails?.cancelled == true && orderDetails.cancelled_seen == false) && (
          <div className="bottom-buttons">
            <button
              type="button"
              className="btn btn-primary btn-lg br-0 w-100 py-13"
              onClick={() => {
                update_status('', true);
              }}
            >
              {statusBtnLoader ? (
                <span className="btn-loader"></span>
              ) : (
                <span>
                  Acknowledge
                </span>
              )}
            </button>
          </div>
        )}
    </div>
  );
}
