import { useState, useEffect } from "react";
import { Rating } from "@mui/material";

function PickupFeedback(props: any) {
  const [data, SetData]: any = useState({});
  const [imageUrl, SetImageUrl]: any = useState("");
  useEffect(() => {
    let image_url = "";
    if (props) {
      SetData(props.data);
      if (props?.outer_data?.image_base_url) {
        image_url += props.outer_data.image_base_url;
      }
      if (props?.data?.package_images?.length > 0) {
        image_url += props.data.package_images[0];
        SetImageUrl(image_url);
      }
    }
  }, [props]);
  return (
    <>
      {(data?.package_images?.length > 0 || data?.seller_rating != null) && (
        <div className="box-1 p-3 mb-3">
          <div className="fw-500 mb-1 text-dark">Package & Pickup Reviews</div>
          <div className="row">
            {data?.package_images?.length > 0 && (
              <div className="col-4">
                <img src={imageUrl} className="img-fluid package-img" />
              </div>
            )}
            <div className="col-8 ps-0">
              <Rating
                name="hover-feedback"
                value={Math.round(data?.seller_rating)}
                className="fs-16"
                style={{ fontSize: "14px" }}
              />
              <div className="fs-13 fw-500 text-muted">
                {data?.seller_feedback_label}
              </div>
              <div className="fs-12 text-muted">{data?.seller_feedback}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default PickupFeedback;
