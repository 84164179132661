import { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { redirect, useNavigate } from "react-router-dom";

import SwipeableDrawer from "../../component/Drawer/SwipeableDrawer";
import { AppHelper } from "../../utils/Helper";
import Close from "../../component/Svg";
import DataContext from "../../utils/Context/DataContext";
import { api } from "../../utils/Api";
import { mobileApp } from "../../utils/MobileApp";
import {LazyLoader, ImageLazyLoader} from "../../utils/LazyLoader/index"

let screenHeight = window.screen.height;
let app_platform = "web";
export default function Menu(props: any) {
  const navigate = useNavigate();
  const context: any = useContext(DataContext);
  app_platform = context.app_data.application.platform;
  let clickedData: any = { menu: "", reloadData: false };
  const [companyLogo, setCompanyLogo]:any = useState();

  const swipeableDrawer: any = {
    settings: { freeMode: true },
    events: {},
    first: false,
    open: false,
    clickBottomOpen: false,
  };
  const [propsData, SetPropsData]: any = useState({
    ...swipeableDrawer,
    first: true,
    settings: {
      //   initialBreak: "bottom",
      // breaks: {
      //   top: { enabled: true, height: screenHeight },
      // },
      backdrop: true,
      lowerThanBottom: false,
      fastSwipeClose: true,
      freeMode: false,
    },
  });

  useEffect(()=>{
    return () => {
      let html: any = document.querySelector("html");
      let body: any = document.querySelector("body");
      html.style.overflowY = "auto";
      body.style.overflowY = "auto";
      // html.style.overscrollBehavior = "auto";
      // body.style.overscrollBehavior = "auto";
    }
  }, [])
  useEffect(()=>{
    if(context.app_data.image_base_url && props?.company_data.logo){
      setCompanyLogo(context.app_data.image_base_url+props?.company_data.logo);
    }
  }, [props?.company_data.logo, context.app_data.image_base_url])

  async function startStopWorking(status: any) {
    if (
      props?.company_data?.brand_id &&
      (app_platform == "android" ||
        app_platform == "ios" ||
        app_platform == "web")
    ) {
      if (status == "stop") {
        await update_company_status();

        var mobile_func = "stopdeliverypartnerbglocation";
        mobileApp({
          function: mobile_func,
          app_type: "react_web",
        });
      }
    }
  }
  async function update_company_status(s = false) {
    let pass_data: any = {},
      call = false;
    if (s && props?.company_data?.brand_id) {
      pass_data = {
        post: {
          brand_id: props?.company_data?.brand_id,
        },
      };
      call = true;
    } else if (s == false) {
      call = true;
    }
    if (call) {
      let data: any = await api("/company/status", pass_data);
      if (data?.status_code == 200) {
        let message = data.response?.message;
        clickedData.reloadData = true;
        if (s == false){
          clickedData.workingStopped = true;
        }
        if (message != undefined) {
          clickedData.error_message = message;
        }
        handleClick(clickedData);
      }
    }
  }
  async function update_reservations(block_id: any) {
    if (props?.company_data?.brand_id) {
      let pass_data = {
        post: {
          brand_id: props?.company_data?.brand_id,
          status: block_id,
        },
      };
      let data: any = await api("/company/block_new_reservations", pass_data);
      if (data.status_code == 200) {
        clickedData.reloadData = true;
        if (block_id == 1) {
          clickedData.success_message = 'Reservation blocked';
        } else {
          clickedData.success_message = 'Reservation unblocked';
        }
        handleClick(clickedData);
      }
    }
  }

  function handleClick(data: any) {
    props.clicked(data);
    SetPropsData({
      ...propsData,
      open: false,
    });
  }
  return (
    <div>
      <i
        className="fas fa-ellipsis-h"
        onClick={() => {
          SetPropsData({
            ...propsData,
            open: true,
          });
        }}
      ></i>
      <div className="swipeable-drawer-container">
        <SwipeableDrawer
          refreshToken={AppHelper.randomStr(10)}
          propsData={propsData}
          parentClass={".swipeable-drawer-container"}
          drawerEvent={(data:any) => {
            if(data == 'closed'){
              SetPropsData({
                ...propsData,
                open: false,
              });
            }
          }}
        >
          <div className="swipeable-drawer-content rounded-corner pt-2 pb-3 px-3">
            <div className="bar"></div>
            <div className="d-flex align-items-center py-2">
              {(props?.company_data?.name) ? (
                <>
                {companyLogo ? (
                  <ImageLazyLoader parentClassName="profile-div" className="img-fluid" src={companyLogo} />
                ) : (
                  <ImageLazyLoader
                  parentClassName="profile-div"
                    className="img-fluid"
                    src={require("../../assets/img/profile.jpg")}
                  />
                )}
                </>
              ): (
                <LazyLoader show={(props?.company_data?.name)?false:true} className="profile-div" />
              )}
              <div className="ms-1">
                <div className="d-flex align-items-center my-1">
                  <h6 className="mb-0">{props?.company_data.name}</h6>
                  {props?.company_data?.checked && (
                    <i className="ms-1 fas fa-circle text-success fs-10"></i>
                  )}
                </div>
                <div>
                {props?.company_data?.has_active_order && (
                  <span className="light-badge">Active Order</span>
                )}
                {props?.company_data?.reservation_blocked && (
                  <span className="light-badge">Reservation Blocked</span>
                )}
                </div>
              </div>
              <div
                className="ms-auto"
                onClick={() => {
                  SetPropsData({
                    ...propsData,
                    open: false,
                  });
                }}
              >
                <Close width="13" fill="#888888" />
              </div>
            </div>
            <div className="px-35">
              <div
                className="menu-item"
                onClick={() => {
                  navigate('/company/'+props?.company_data?.brand_id);
                }}
              >
                Home
              </div>
              {props?.company_data.checked && (
                <>
                  <div
                    className="menu-item"
                    onClick={() => {
                      startStopWorking('stop');
                    }}
                  >
                    Stop Working
                  </div>
                  {!props?.company_data?.reservation_blocked ? (
                    <div
                      className="menu-item"
                      onClick={() => {
                        update_reservations(1);
                      }}
                    >
                      Block Reservation
                    </div>
                  ) : (
                    <div
                      className="menu-item"
                      onClick={() => {
                        update_reservations(0);
                      }}
                    >
                      Unblock Reservation
                    </div>
                  )}
                </>
              )}
              <div
                className="menu-item"
                onClick={() => {
                  navigate('/orders/active/'+props?.company_data.brand_id);
                }}
              >
                My Orders
              </div>

         
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    </div>
  );
}
