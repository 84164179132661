import { useState, useEffect } from "react";
import { Rating } from "@mui/material";

function DeliveryFeedback(props: any) {
  const [data, SetData]: any = useState({});
  useEffect(() => {
    if (props) {
      SetData(props.data);
    }
  }, [props]);
  return (
    <>
      {data.customer_rating != null && (
        <div className="box-1 p-3 mb-3 d-flex">
          <div className="me-2">
            <div className="bg-circle violet-color">
              <i className="fas fa-comments"></i>
            </div>
          </div>
          <div>
            <div className="fw-500 mb-1">Delivery Review</div>
            <div className="">
              <div>
                <Rating
                  name="hover-feedback"
                  value={Math.round(data?.customer_rating)}
                  className="fs-16"
                  style={{ fontSize: "14px" }}
                />
                <div className="fs-13 fw-500">
                  {data?.customer_feedback_label}
                </div>
                <div className="fs-12">{data?.customer_feedback}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default DeliveryFeedback;
