import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import DataContext from "../../utils/Context/DataContext";
// import { FontAwesome5, Feather, AntDesign, SimpleLineIcons } from '@expo/vector-icons';
import { Modal } from "@mui/material";
import SideMenu from "../SideMenu/SideMenu";

export default function Header(props: any) {
  const context: any = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());
  const [have_back, SetHaveBack] = useState(false);
  const [menu_open, SetMenuOpen] = useState(false);
  const [work, SetWork] = useState(false);
  const [data, SetData] = useState("");

  useEffect(() => {
    SetData(props.title);
    SetHaveBack(props.back);
    SetWork(props.start);
  }, [props]);

  const styles: any = {
    navbar: {
      justifyContent: "flex-start",
      background: "#fff",
      height: "50px",
      borderBottom: "1px solid #f3f3f3",
      zIndex: "10",
      boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
      padding: "1rem",
      position: "fixed",
      top: "0px",
      width: "100%",
    },
    alignCenter: {
      display: "flex",
      alignItemsCenter: "center",
    },
    arrow: {
      paddingRight: "1rem",
      cursor: "pointer",
    },
    para: {
      marginBottom: "0px",
      color: "#000",
      fontWeight: "600",
    },
    cursor: {
      cursor: "pointer",
    },
    msAuto: {
      marginLeft: "auto",
    },
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          Delivery Partner
        </Link>
        {/* {(context.app_data.ibtn_link || context.app_data.show_chat_btn) && ( */}
          <div className="ms-auto">
            <a
              href="tel:+919514333916"
              className="btn text-muted py-0 fw-500 fs-12 text-uppercase pe-1"
            >
              {/* <img className="img-fluid" width="20" src={require('../../assets/img/user-headset.svg').default}/>  */}
              Help
            </a>
            {context.app_data.ibtn_link && (
              <Link
                to={context.app_data.ibtn_link}
                className="btn text-muted py-0 fs-15"
              >
                <i className="fas fa-info-circle"></i>
              </Link>
            )}
            {/* {context.app_data.show_chat_btn && (
              <button
                type="button"
                className="btn text-muted py-0 fs-15 pe-1"
                onClick={() => {
                  window.fcWidget.open();
                }}
              >
                <i className="fas fa-question-circle"></i>
              </button>
            )} */}
              
          </div>
        {/* )} */}
      </div>
    </nav>

    // <div style={styles.navbar}>
    //     <div style={styles.alignCenter}>
    //     <Link to="/" >Delivery Partner</Link>

    //         {/* {have_back ? (
    //             <div style={styles.arrow} onClick={() => { window.history.go(-1); }}>
    //                 <i className="fas fa-arrow-left"></i>
    //             </div>
    //         ) : (
    //             <div style={styles.cursor}
    //                 onClick={() => {
    //                     // props.menu_click(true);
    //                     SetMenuOpen(true);
    //                 }}>

    //             </div>
    //         )} */}

    //         {have_back ? (
    //             null
    //         ) : (
    //             <div style={styles.msAuto}>
    //                 {/* <AntDesign name="customerservice" size={18} color="#444" /> */}
    //             </div>
    //         )}

    //         <Modal
    //             open={menu_open}
    //             onClose={() => { SetMenuOpen(false); }}
    //         >
    //             <SideMenu open={(data:any) => {
    //                 SetMenuOpen(data)
    //             }}
    //                 start={work} />
    //         </Modal>
    //     </div>
    // </div>
  );
}
