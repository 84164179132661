import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
// import Footer from "../Footer/Footer";

import DataContext from "../../utils/Context/DataContext";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "../../utils/Loader";
// import { Toaster } from "react-hot-toast";
import { api } from "../../utils/Api";
import Footer from "../Footer/Footer";
import SideMenu from "../SideMenu/SideMenu";
import Header from "../Header/Header";
// import Header from "../Header/Header";
// import SideMenu from "../SideMenu/SideMenu";
import "../../assets/css/style.css";

export default function HomeLayout(props: any) {
  const context: any = useContext(DataContext);
  const [is_menu_open, SetIsMenuOpen] = useState(false);
  const [work, SetWork] = useState(false);
  const location = useLocation();
  const [outlet_context_data, SetOutletContextData]: any = useState({
    have_footer: true,
  });

  useEffect(() => {
    // get_init();
  }, []);

  function on_menu_click(data: any) {
    SetIsMenuOpen(data);
  }

  async function get_init() {
    let response: any = await api("/init");
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("user_details")) {
        localStorage.setItem(
          "user_data",
          JSON.stringify(response.response.user_details)
        );
      }
    }
  }

  return (
    <>
      {/* <Loader promiseTracker={usePromiseTracker} /> */}
      <div className="page-body">
        {/* <SideMenu
          active={is_menu_open}
          menu_click={(data: any) => {
            on_menu_click(data);
          }}
        /> */}
        <div className="page-content-home">
          {location.pathname == "/order_map" ? null : (
            <Header
              menu_click={(data: any) => {
                on_menu_click(data);
              }}
              start={work}
            />
          )}

          <Outlet context={{ SetOutletContextData }} />
          {/* <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              className: "",
              style: {
                border: "1px solid #171546",
                padding: "10px",
              },
              duration: 1500,
            }}
          /> */}
        </div>
      </div>
      {context.app_data.have_footer && <Footer />}
    </>
  );
}
