import { useState, useEffect } from "react";

function CapturePackageImage(props: any) {
  const [data, SetData]: any = useState({});
  useEffect(() => {
    if (props) {
      SetData(props.data);
    }
  }, [props]);
  return (
    <div className="box-1 p-3 mb-3">
      <div className="d-flex mb-2">
        <div className="fw-500 text-dark">Pickup</div>
        <div className="ms-auto">
          <button
            type="button"
            className="btn btn-sm text-muted"
            onClick={() => props.skipCapture()}
            disabled={props?.statusBtnLoader?true:false}
          >
            Skip
          </button>
        </div>
      </div>
      <div>Take a picture of package</div>
    </div>
  );
}
export default CapturePackageImage;
