import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import DataContext from "../../utils/Context/DataContext";

import SwipeableDrawer from "../Drawer/SwipeableDrawer";
import { AppHelper } from "../../utils/Helper";
import {LazyLoader, ImgLazyLoader, ImageLazyLoader} from "../../utils/LazyLoader/index"
import { api } from "../../utils/Api";

// import { FontAwesome, Ionicons, MaterialIcons, AntDesign, SimpleLineIcons } from '@expo/vector-icons';

export default function Footer(props: any) {
  const context: any = useContext(DataContext);
  const [id_value, SerIdValue] = useState(useParams());
  const [active, SetActive] = useState(false);
  const [badge, SetBadge] = useState("");

  let badgeTimer:any;

  const swipeableDrawer: any = {
    settings: { freeMode: true },
    events: {},
    open: false,
    clickBottomOpen: false,
  };
  const [propsData, SetPropsData]: any = useState({
    ...swipeableDrawer,
    customData: {
      subMenu: null,
      subMenuTitle: "",
      style: {
        zIndex: 2,
      },
    },
    settings: {
      breaks: {
        // top: { enabled: true, height: window.screen.height },
        middle: { enabled: true, height: 290 },
        bottom: false,
      },
      backdrop: false,
      bottomClose: true,
    },
  });

  useEffect(() => {
    setBadgeTimer();
    return () => {
      if(badgeTimer){
        clearInterval(badgeTimer);
      }
    }
  }, []);

  async function close1IconTutorial() {
    context.SetAppData((prevValue: any) => {
      prevValue.tutorials.tutorial_1icon = false;
      return { ...prevValue };
    });
    let pass_data = {
      post: {
        tutorial: context.app_data?.tutorials?.tutorial_1icon_key,
      },
    };
    let data: any = await api("/init/tutorial_viewed", pass_data);

    if (data.status_code == 200) {
      if(data.response?.status){
        
      }
    }
  }
  function setBadgeTimer(){
    getNotificationBadge();
    badgeTimer = setInterval(() => {
      getNotificationBadge();
    }, 20000);
  }
  async function getNotificationBadge() {
    let data: any = await api("/company/badges");
    if (data.status_code == 200) {
      SetBadge(data.response.notifications);
    }
  }
  

  return (
    <>
      {(context.app_data.home_page && context.app_data?.tutorials?.tutorial_1icon && window.screen.width < 990) && (
        <>
          <div className="tutorial-tooltip-overlay"></div>
          <div className="tutorial-tooltip">
            <div className="d-flex p-2">
              <button type="button" className="btn btn-primary btn-sm text-uppercase br-20 fw-500 px-3 ms-auto" onClick={()=>{
                close1IconTutorial();
              }}>Got It 
              {/* <i className="fas fa-chevron-right"></i> */}
              </button>
            </div>
            <div className="text-white mt-auto">
              <h6>Click "1" logo to</h6>
              <h6 className="mt-1">Navigate To MiniApps Easily</h6>
            </div>
            <div className="mt-auto">
              <img className="img-fluid" src="https://cdn3.1superapp.com/superapp/tutorial_preview_1icon.gif" />
            </div>
          </div>
        </>
      )}
      <div
        className="bottom-tabs d-lg-none d-flex justify-content-center align-items-center text-center border-top"
        style={propsData?.customData?.style}
      >
        {propsData?.customData?.subMenu != null ? (
          <div className="w-100 d-flex align-items-center">
            <button
              type="button"
              className="btn btn-menu-back"
              onClick={() => {
                SetPropsData({
                  ...propsData,
                  customData: {
                    subMenu: null,
                    subMenuTitle: "",
                    style: {
                      zIndex: 2,
                    },
                  },
                });
              }}
            >
              <i className="fas fa-chevron-left"></i>
            </button>
            <div
              className="fs-13 fw-500 text-dark"
              onClick={() => {
                SetPropsData({
                  ...propsData,
                  customData: {
                    subMenu: null,
                    subMenuTitle: "",
                    style: {
                      zIndex: 2,
                    },
                  },
                });
              }}
            >
              {propsData?.customData?.subMenuTitle}
            </div>
          </div>
        ) : (
          <>
            {!props?.only_one_icon && (
              <>
                <Link to="/" className="flex-fill p-2 link-loader">
                  <i className="fas fa-home"></i>
                </Link>
                <Link to={"/settlement"} className="flex-fill p-2 link-loader">
                  <i className="fas fa-building"></i>
                </Link>
              </>
            )}
            <button
              type="button"
              className="btn flex-fill p-2 link-loader lazy-load"
              onClick={() => {
                if (propsData.open) {
                  window.location.href = "https://1superapp.com/dashboard";
                } else {
                  SetPropsData({
                    ...propsData,
                    open: true,
                    customData: {
                      subMenu: null,
                      subMenuTitle: "",
                      style: {
                        zIndex: 1032,
                      },
                    },
                  });
                }
              }}
            >
              <ImgLazyLoader
                src="https://cdn3.1superapp.com/superapp/assets/images/1superapp-logo-plain.png"
                width="25"
              />
            </button>
            {!props?.only_one_icon && (
              <>
                <Link
                  to={"/notification"}
                  className="flex-fill p-2 link-loader  position-relative"
                >
                  <i className="fas fa-bell"></i>

                  {badge && (
                    <span className="position-absolute top-5 translate-middle badge rounded-circle bg-prime">
                      <span className="">{badge}</span>
                    </span>
                  )}
                </Link>
                <Link to={"/profile"} className="flex-fill p-2 link-loader">
                  <i className="fas fa-user-circle"></i>
                </Link>
              </>
            )}
          </>
        )}
      </div>
      <div className="swipeable-drawer-container-footer">
        <SwipeableDrawer
          refreshToken={AppHelper.randomStr(10)}
          propsData={propsData}
          parentClass={".swipeable-drawer-container-footer"}
          drawerEvent={(data: any) => {
            if (data == "closed") {
              SetPropsData({
                ...propsData,
                open: false,
                customData: {
                  subMenu: null,
                  subMenuTitle: "",
                  style: {
                    zIndex: 2,
                  },
                },
              });
            }
          }}
        >
          <div className="swipeable-drawer-content rounded-corner pt-1 pb-3 px-1">
            <div className="bar mb-1"></div>
            <div>
              {propsData?.customData?.subMenu == null && (
                <>
                  <div className="dashboard-app-btns">
                    <div className="dashboard-app-btn-col">
                      <a
                        className="dashboard-app-btn link-loader lazy-load"
                        href="https://shop.1superapp.com/food"
                      >
                        <ImgLazyLoader
                          className="img-fluid pt-2"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/soup.svg"
                        />
                        <div className="mt-2 text-dark dashboard-app-btn-title">
                          Food
                        </div>
                      </a>
                    </div>
                    <div className="dashboard-app-btn-col">
                      <a
                        className="dashboard-app-btn link-loader lazy-load"
                        href="https://shop.1superapp.com/app/login?r=https://shop.1superapp.com/pickupdrop"
                      >
                        <ImgLazyLoader
                          className="img-fluid pt-2"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/delivery-pickupdrop.png"
                        />
                        <div className="mt-2 text-dark dashboard-app-btn-title">
                          Pickup Drop
                        </div>
                      </a>
                    </div>
                    <div className="dashboard-app-btn-col">
                      <a
                        className="dashboard-app-btn link-loader lazy-load"
                        href="https://shop.1superapp.com/groceries"
                      >
                        <ImgLazyLoader
                          className="img-fluid pt-2"
                          width=""
                          src="https://cdn2.1superapp.com/images/2bd8ff574a746aa2195f1e5aa884be15.png?format=webp&width=100"
                        />
                        <div className="mt-2 text-dark dashboard-app-btn-title">
                         Groceries
                        </div>
                      </a>
                    </div>
                    <div className="dashboard-app-btn-col">
                      <a
                        className="dashboard-app-btn link-loader lazy-load"
                        href="https://shop.1superapp.com/nearby-stores"
                      >
                        <ImgLazyLoader
                          className="img-fluid pt-2"
                          width=""
                          src="https://cdn2.1superapp.com/images/ea060b2a62dd087a79cbbadc09c142e1.png?format=webp&width=100"
                        />
                        <div className="mt-2 text-dark dashboard-app-btn-title">
                          Nearby Stores
                        </div>
                      </a>
                    </div>
                    
                  </div>
                  <div className="dashboard-app-btns">
                    <div className="dashboard-app-btn-col">
                      <a
                        className="dashboard-app-btn link-loader lazy-load"
                        href="https://1blood.1superapp.com/login"
                      >
                        <ImgLazyLoader
                          className="img-fluid"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-1blood.png"
                        />
                        <div className="mt-2 text-dark dashboard-app-btn-title">
                          Blood
                        </div>
                      </a>
                    </div>
                    <div className="dashboard-app-btn-col">
                      <a
                        className="dashboard-app-btn link-loader lazy-load"
                        href="https://events.1superapp.com"
                      >
                        <ImgLazyLoader
                          className="img-fluid"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/dance.png"
                        />
                        <div className="mt-2 text-dark dashboard-app-btn-title">
                          Events
                        </div>
                      </a>
                    </div>
                    <div className="dashboard-app-btn-col">
                      <button
                        type="button"
                        className="btn dashboard-app-btn lazy-load"
                        onClick={() => {
                          SetPropsData({
                            ...propsData,
                            customData: {
                              subMenu: "earn",
                              subMenuTitle: "Earn",
                              style: {
                                zIndex: 1032,
                              },
                            },
                          });
                        }}
                      >
                        <ImgLazyLoader
                          className="img-fluid"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/money-bill-wave-green.svg"
                        />
                        <div className="mt-2 text-dark dashboard-app-btn-title">
                          Earn
                        </div>
                      </button>
                    </div>
                    <div className="dashboard-app-btn-col">
                    <button
                      type="button"
                      className="btn dashboard-app-btn lazy-load"
                      onClick={() => {
                        SetPropsData({
                          ...propsData,
                          customData: {
                            subMenu: "business_suite",
                            subMenuTitle: "Business Suite",
                            style: {
                              zIndex: 1032,
                            },
                          },
                        });
                      }}
                    >
                      <ImgLazyLoader
                        className="img-fluid pt-2"
                        width=""
                        src="https://cdn3.1superapp.com/superapp/assets/images/briefcase.svg"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Business Suite
                      </div>
                    </button>
                    </div>
                  </div>
                </>
              )}
              {propsData?.customData?.subMenu == "earn" && (
                <div className="dashboard-app-btns">
                  <div className="dashboard-app-btn-col">
                  <a
                    className="dashboard-app-btn link-loader lazy-load"
                    href="https://deliverypartner.1superapp.com/app/login"
                  >
                    <ImgLazyLoader
                      className="img-fluid"
                      width=""
                      src="https://cdn3.1superapp.com/superapp/assets/images/delivery-man.png"
                    />
                    <div className="mt-2 text-dark dashboard-app-btn-title">
                      Delivery Partner
                    </div>
                  </a>
                  </div>
                  <div className="dashboard-app-btn-col">
                  <a
                    className="dashboard-app-btn link-loader lazy-load"
                    href="https://serviceprovider.1superapp.com"
                  >
                    <ImgLazyLoader
                      className="img-fluid"
                      width=""
                      src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-sp.png"
                    />
                    <div className="mt-2 text-dark dashboard-app-btn-title">
                      Service Providers
                    </div>
                  </a>
                  </div>
                </div>
              )}
              {propsData?.customData?.subMenu == "business_suite" && (
                <div className="dashboard-app-btns">
                  <div className="dashboard-app-btn-col">
                  <a
                    className="dashboard-app-btn link-loader lazy-load"
                    href="https://1studio.1superapp.com/dashboard"
                  >
                    <ImgLazyLoader
                      className="img-fluid"
                      width=""
                      src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-studio.png"
                    />
                    <div className="mt-2 text-dark dashboard-app-btn-title">
                      Studio
                    </div>
                  </a>
                  </div>
                  <div className="dashboard-app-btn-col">
                  <a
                    className="dashboard-app-btn link-loader lazy-load"
                    href="https://postproduction.1superapp.com"
                  >
                    <ImgLazyLoader
                      className="img-fluid"
                      width=""
                      src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-postproduction.png"
                    />
                    <div className="mt-2 text-dark dashboard-app-btn-title">
                      Post Production
                    </div>
                  </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    </>
  );
}
