import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
// import { Entypo, MaterialIcons, Ionicons } from "@expo/vector-icons";
import Header from "../../component/Header/Header";
import { LazyLoader, ImageLazyLoader } from "../../utils/LazyLoader/index";

export default function Profile(props: any) {
  const context: any = useContext(DataContext);
  const [background_loc_allowed, SetBackgroundLocAllowed] = useState(false);
  const [camera_allowed, SetCameraAllowed] = useState(false);
  const [user_data, SetUserData]: any = useState({});
  const [data, SetData]: any = useState([]);

  useEffect(() => {
    settings();
  }, []);

  async function settings() {
    let data: any = await api("/account/settings");
    if (data.status_code == 200) {
      SetBackgroundLocAllowed(
        data.response.settings.background_location_allowed
      );
      SetCameraAllowed(data.response.settings.camera_allowed);
      SetUserData(data.response.user_details);
      SetData(data.response);
    }
  }

  async function save_setting(from: any, loc: any) {
    let pass_data: any;
    if (from == "bglocation") {
      pass_data = {
        post: {
          background_location_allowed: loc ? 0 : 1,
        },
      };
    } else if (from == "camera") {
      pass_data = {
        post: {
          camera_allowed: loc ? 0 : 1,
        },
      };
    }
    if (pass_data.post) {
      let data: any = await api("/account/save_settings", pass_data);

      if (data.status_code == 200) {
        SetBackgroundLocAllowed(
          data.response.settings.background_location_allowed
        );
        SetCameraAllowed(data.response.settings.camera_allowed);
        context.SetAppData((prevValue: any) => {
          prevValue.settings = data.response.settings;
          return { ...prevValue };
        });
      }
    }
  }

  return (
    <div>
      <>
        {/* <Header title="Profile" back={true} /> */}

        <div className="p-2">
          <div className="card p-3">
            <div className="text-center">
              {user_data.name ? (
                <>
                  {user_data.dp_url ? (
                    <ImageLazyLoader
                      src={user_data.dp_url}
                      className="img-radius w-100px"
                    />
                  ) : (
                    <ImageLazyLoader
                      src={require("../../assets/img/profile.png")}
                      className="img-radius w-100px"
                    />
                  )}
                </>
              ) : (
                <LazyLoader
                  className="img-radius w-100px mx-auto"
                  style={{ height: "100px" }}
                />
              )}
              {/* <div className="pt-2">
                            <small className="text-prime cursor">Change Profile</small>
                        </div> */}
              {user_data.name ? (
                <>
                  <p className="mb-1 mt-3 text-dark fw-bold">
                    {user_data.name}
                    {user_data.verified && (
                      <i className="fas fa-check-circle text-primary ms-1"></i>
                    )}
                  </p>
                  <p className="mb-0 text-gray">@{user_data.username}</p>
                </>
              ) : (
                <>
                  <LazyLoader className="mx-auto mb-1 mt-3" style={{ height: "20px", width: "200px" }} />
                  <LazyLoader className="mx-auto" style={{ height: "20px", width: "150px" }} />
                </>
              )}
            </div>

            {/* <div className="d-flex align-items-center pb-2 border-bottom mt-3">
                        <p className="mb-0">Your ratings</p>
                        <div className="ms-auto">
                            <div className="d-flex">
                                <div className="pe-1">
                                    <Entypo name="star" size={13} color="#4b4abe" />
                                </div>
                                <div className="pe-1">
                                    <Entypo name="star" size={13} color="#4b4abe" />
                                </div>
                                <div className="pe-1">
                                    <Entypo name="star" size={13} color="#4b4abe" />
                                </div>
                                <div className="pe-1">
                                    <Entypo name="star" size={13} color="lightgray" />
                                </div>
                                <div className="pe-1">
                                    <Entypo name="star" size={13} color="lightgray" />
                                </div>
                            </div>
                        </div>
                    </div> */}
            {/* <div className="d-flex my-2 align-items-center">
                        <MaterialIcons name="delivery-dining" size={20} color="#aaa" />
                        <p className="text-gray mb-0 ps-2">Delivery Company name</p>
                    </div> */}
          </div>

          <div className="card p-3 mt-3">
            <h6 className="">Settings</h6>

            <div className="py-2 mb-3 cursor">
              {/* <p className="mb-1 text-gray">License Card Number</p> */}
              <div className="row align-items-center pt-2 pb-1">
                <div className="col-8">
                  <p className="mb-0 text-darkgray">
                    Background location permission
                  </p>
                </div>
                <div className="col-4 text-end">
                  {/* <small className="text-darkgray">Exp: 4/20/25</small> */}
                  <div className="form-check form-switch">
                    <>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        disabled={data.has_active_order}
                        onChange={() => {
                          save_setting("bglocation", background_loc_allowed);
                        }}
                        checked={background_loc_allowed}
                      />
                    </>{" "}
                  </div>
                </div>
              </div>
              {data.has_active_order && (
                <p>You have active order so can't turn off</p>
              )}
            </div>
            <div className="py-2 cursor">
              <div className="row align-items-center pt-2 pb-1">
                <div className="col-8">
                  <p className="mb-0 text-darkgray">Camera Permission</p>
                </div>
                <div className="col-4 text-end">
                  <div className="form-check form-switch">
                    <>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked2"
                        onChange={() => {
                          save_setting("camera", camera_allowed);
                        }}
                        checked={camera_allowed}
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
