import { useState, useEffect } from "react";

function InstructionsList(props: any) {
  const [data, SetData]: any = useState({});
  useEffect(() => {
    if (props) {
      SetData(props.data);
      console.log(props);
      
    }
  }, [props]);
  return (
    <div className="box-1 p-3 mb-3">
      <div className="d-flex">
        <div className="me-2">
          <div className="bg-circle violet-color">
            <i className="fas fa-poll-h"></i>
          </div>
        </div>
        <div>
          <div className="fw-500">Instructions</div>
          {data?.delivery_instructions?.map((d:any, i:any) => (
            <div className="text-muted">{d.message}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default InstructionsList;
