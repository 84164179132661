import React, { useState, useEffect, useContext } from "react";

import SwipeableDrawer from "../../component/Drawer/SwipeableDrawer";

import DataContext from "../../utils/Context/DataContext";
import { AppHelper } from "../../utils/Helper";
import Map from "./Map";
import Pickup from "./Pickup";
import Delivery from "./Delivery";

import { Link, useParams, useNavigate } from "react-router-dom";

import { api } from "../../utils/Api";

import { Dialog, Modal, Rating } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import Webcam from "react-webcam";
import { useRef } from "react";
import ModalDeclineOrder from "./ModalDeclineOrder";
import ModalCod from "./ModalCod";
import { mobileApp } from "../../utils/MobileApp";

import Header from "./Header";
import Instructions from "./Instructions";
import Package from "./Package";
import Products from "./Products";
import PickupFeedback from "./PickupFeedback";
import DeliveryFeedback from "./DeliveryFeedback";
import PackageImage from "./PackageImage";
import CapturePackageImage from "./CapturePackageImage";
import CollectCashDelivery from "./CollectCashDelivery";
import CameraPermission from "./CameraPermission";
import ShipmentOrderId from "./ShipmentOrderId";
import OrderChat from "./OrderChat";
import SwipeableButton, { SwipeableButtonRef } from "../../component/SwipeableButton";
import InstructionsList from "./InstructionsList";
import ModalOrderDelayed from "./ModalOrderDelayed";

// var lat: any, long: any;
let statusId = "",
  imgId = "";
// app_platform = "web";
let collected_feedback_value = {
  rating: "",
  feedback_list: "",
  feedback: "",
};

let delevered_feedback_value = {
  rating: "",
  feedback_list: "",
  feedback: "",
};

export default function OrderDetails(props: any) {
  const navigate = useNavigate();
  const context: any = useContext(DataContext);
  const [save_data, SetSaveData]: any = useState({});
  const [collected_issue, SetCollectedIssue] = useState([]);
  const [delevered_issue, SetDeleverdIssue] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [web_cam, setWebcam] = useState(false);
  const [outer_data, SetOuterData]: any = useState([]);
  let screenHeight = window.screen.height;
  const [showPickupFeedbackDrawer, setShowPickupFeedbackDrawer] =
    useState(false);
  const [showDeliveryFeedbackDrawer, setShowDeliveryFeedbackDrawer] =
    useState(false);
  const [showCameraDrawer, setShowCameraDrawer] = useState(false);
  const [mountCamera, setMountCamera] = useState(false);
  const [orderStatusId, setOrderStatusId]: any = useState();
  const [skipPackageImage, setSkipPackageImage]: any = useState(false);
  const [cameraPermissionModal, setCameraPermissionModal]: any = useState({
    is_open: false,
    id: "",
  });
  const [app_platform, setAppPlatform] = useState("web");
  const [statusBtnLoader, setStatusBtnLoader] = useState(false);

  const [moveToBreak, setMoveToBreak] = useState('');
  const swipeableDrawer: any = {
    settings: { freeMode: true },
    events: {},
    first: false,
    open: false,
    clickBottomOpen: false,
  };
  const [propsData, SetPropsData]: any = useState({
    ...swipeableDrawer,
    first: true,
    settings: {
      initialBreak: "bottom",
      breaks: {
        // top: { enabled: true, height: screenHeight },
        bottom: { enabled: true, height: 300 },
      },
      lowerThanBottom: false,
    },
  });
  const [vendorFeedBackData, SetVendorFeedBackData]: any = useState({
    ...swipeableDrawer,
    settings: {
      freeMode: false,
      cssClass: "z-index-50",
      initialBreak: "middle",
      breaks: {
        top: { enabled: false },
        middle: { enabled: true, height: 430 },
        bottom: { enabled: false },
      },
      backdrop: true,
    },
  });
  const [buyerFeedBackData, SetBuyerFeedBackData]: any = useState({
    ...swipeableDrawer,
    settings: {
      freeMode: false,
      cssClass: "z-index-50",
      initialBreak: "middle",
      breaks: {
        top: { enabled: false },
        middle: { enabled: true, height: 400 },
        bottom: { enabled: false },
      },
      backdrop: true,
    },
  });
  const [cameraDrawerData, SetCameraDrawerData]: any = useState({
    ...swipeableDrawer,
    settings: {
      freeMode: false,
      cssClass: "z-index-1050",
      initialBreak: "top",
      fitScreenHeight: true,
      fitHeight: true,
      fastSwipeClose: true,
      breaks: {
        top: { enabled: true, height: screenHeight },
      },
    },
  });

  const [id_value, SerIdValue] = useState(useParams());
  const [pickup_feedback, SetPickupFeedback] = useState(false);
  // const classes = useStyles();
  const [value, SetValue] = useState(0);
  const [del_value, SetDelValue]: any = useState(null);
  const [delivery_feedback, SetDeliveryFeedback] = useState(false);

  const [res_locc, SetResLoc]: any = useState({});
  const [deliver_loc, SetDeliverLoc]: any = useState({});
  const [curr_loc, SetCurrLoc]: any = useState({});
  const [startCameraApp, setStartCameraApp]: any = useState(false);

  const [deleteModal, SetDeleteModal]: any = useState({
    is_open: false,
    id: "",
  });

  const [codModal, SetCodModal]: any = useState({
    is_open: false,
    id: "",
    cod_amount: "",
  });
  const [odelayedModal, SetOdelayedModal]: any = useState({
    is_open: false,
    id: "",
    order: {},
  });
  const [chatModal, SetChatModal]: any = useState({
    is_open: false,
    id: "",
    data: {},
  });

  const webcamRef: any = useRef(null);
  const [capture_img, SetCaptureImg] = useState("");
  const SwipeableButtonRef = useRef<SwipeableButtonRef>(null);
  const SwipeableButtonRefCod = useRef<SwipeableButtonRef>(null);
  const SwipeableButtonRefDelivery = useRef<SwipeableButtonRef>(null);

  function putCameraFromMobile(message: any) {
    if (message.data.function == "opencamera") {
      if (message.data.status) {
        // setWebcam(true);
        if (!context.app_data.settings.camera_allowed) {
          grantedCameraPermisson();
        } else {
          setStartCameraApp(true);
        }
      }
    } else if (message.data.function == "checkdeliverypartnerbglocation") {
      if (message.data.status) {
        // alert(statusId)
        // if (statusId) {
        //   status_update_mobile(statusId, imgId);
        //   statusId = "";
        // }
      } else {
        if (app_platform == "android" || app_platform == "ios") {
          mobileApp({
            function: "startdeliverypartnerbglocation",
            app_type: "react_web",
          });
        }
      }
    } else if (message.data.function == "startdeliverypartnerbglocation") {
    } else if (message.data.function == "getlocationlatlng") {
      // SetCurrLoc({
      //   lat: message.data.lat,
      //   lng: message.data.lng,
      // });
    }
  }

  useEffect(() => {
    document.addEventListener("message", putCameraFromMobile);

    context.SetAppData((prevValue: any) => {
      prevValue.have_footer = false;
      prevValue.ibtn_link = "/order/" + id_value.id;
      prevValue.show_chat_btn = true;
      return { ...prevValue };
    });

    if (
      context.app_data.application.platform == "android" ||
      context.app_data.application.platform == "ios" ||
      context.app_data.application.platform == "web"
    ) {
      setAppPlatform(context.app_data.application.platform);
    }

    new_order();

    Coll_issue();
    Del_issue();

    SetPropsData({ ...propsData, open: true, close: false });

    // const timer = setTimeout(() => console.log("Initial timeout!"), 1000);

    return () => {
      if (
        document.querySelectorAll(".cupertino-pane-wrapper.rendered").length ==
        0
      ) {
        let html: any = document.querySelector("html");
        let body: any = document.querySelector("body");
        html.style.overflowY = "auto";
        body.style.overflowY = "auto";
        // html.style.overscrollBehaviorY = "auto";
        // body.style.overscrollBehaviorY = "auto";
      }

      context.SetAppData((prevValue: any) => {
        prevValue.have_footer = true;
        prevValue.ibtn_link = "";
        prevValue.show_chat_btn = false;
        return { ...prevValue };
      });
    };
  }, []);

  useEffect(() => {
    if (startCameraApp) {
      SetCameraDrawerData({ ...cameraDrawerData, open: true });
      setMountCamera(true);
    }
  }, [startCameraApp]);
  useEffect(() => {
    var x: any = document.getElementById("ring");
    if (
      save_data.status_id == "0" &&
      id_value?.alert &&
      app_platform != "android"
    ) {
      if (x) {
        x.play();
      }
    } else {
      if (x) {
        x?.pause();
        x.currentTime = 0;
      }
    }
  }, [save_data, app_platform]);
  useEffect(() => {
    if (save_data.status_id >= 3 && save_data.status_id <= 5 || save_data.status_id >= 7) {
      setMoveToBreak('top')
    } else {
      setMoveToBreak('middle')
    }

    if (save_data?.delayed_message?.length > 0) {
      SetOdelayedModal((prevValue: any) => {
        prevValue.is_open = true;
        prevValue.order = save_data;
        return { ...prevValue };
      });
    }

  }, [save_data]);

  const capture = () => {
    if (!statusBtnLoader) {
      setStatusBtnLoader(true);

      const imageSrc = webcamRef?.current?.getScreenshot();

      const contentType = "image/jpeg";
      const b64Data = imageSrc;

      const blob = AppHelper.base64toBlob(b64Data, contentType);
      upload_img(blob);

      SetCaptureImg(imageSrc);
    }
  };

  // function getLocation() {
  //   if (context.app_data.application.platform == "web") {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(showPosition);
  //     }
  //   } else if (
  //     context.app_data.application.platform == "android" ||
  //     context.app_data.application.platform == "ios"
  //   ) {
  //     mobileApp({
  //       function: "getlocationlatlng",
  //       app_type: "react_web",
  //     });
  //   }
  // }
  // function showPosition(position: any) {
  //   SetCurrLoc({
  //     lat: position.coords.latitude,
  //     lng: position.coords.longitude,
  //   });
  // }

  async function Coll_issue() {
    let pass_data = {
      get: {
        type: "collected",
      },
    };
    let data: any = await api("/company/feedback", pass_data);

    if (data.status_code == 200) {
      SetCollectedIssue(data.response.data);
    }
  }

  async function Del_issue() {
    let pass_data = {
      get: {
        type: "delivered",
      },
    };
    let data: any = await api("/company/feedback", pass_data);

    if (data.status_code == 200) {
      SetDeleverdIssue(data.response.data);
    }
  }

  async function upload_img(blob: any) {
    let data: any = await api(
      "/company/upload_image",
      {},
      false,
      false,
      "form_data",
      blob,
      "image"
    );

    if (data.status_code == 201) {
      // SetPackageId(data.response.id);

      update_status(5, data.response.id);
    }
  }

  async function new_order() {
    let pass_data = {
      get: {
        order_id: id_value.id,

        page: 1,
      },
    };
    let data: any = await api("/company/orders", pass_data);

    if (data.status_code == 200) {
      SetOuterData(data.response);
      SetSaveData(data.response.data[0]);
      updateStates(data);

      // getLocation();

      SetDeliverLoc({
        lat: data.response.data[0].delivery_address.lat,
        lng: data.response.data[0].delivery_address.lng,
      });
      if (data.response.data[0].sector_id == 4) {
        SetResLoc({
          lat: data.response?.data[0]?.pickup_address.lat,
          lng: data.response?.data[0]?.pickup_address.lng,
        });
      } else {
        SetResLoc({
          lat: data.response?.data[0]?.outlet.lat,
          lng: data.response?.data[0]?.outlet.lng,
        });
      }
    }
  }
  function updateStates(data: any) {
    if (data.response.data[0]?.status_id == 3) {
      setShowPickupFeedbackDrawer(true);
    }
    if (data.response.data[0]?.status_id == 4) {
      setShowCameraDrawer(true);
    }
    if (
      data.response.data[0]?.status_id == 8 ||
      data.response.data[0]?.status_id == 7
    ) {
      setShowDeliveryFeedbackDrawer(true);
    }
    if (
      data.response.data[0]?.status_id == 1 ||
      (data.response.data[0]?.status_id >= 3 &&
        data.response.data[0]?.status_id <= 10)
    ) {
      setOrderStatusId(parseInt(data.response.data[0].status_id));
    }
  }
  async function status_update_mobile(status: any, pack_img_id: any) {
    let pass_data: any = {
      post: {
        order_id: id_value.id,
        status: status,
      },
    };
    if (status == 4) {
      pass_data.post.rating = collected_feedback_value.rating;
      pass_data.post.feedback_list = collected_feedback_value.feedback_list;
      pass_data.post.feedback = collected_feedback_value.feedback;
    } else if (status == 5) {
      pass_data.post.images = [pack_img_id];
    } else if (status == 9) {
      pass_data.post.rating = delevered_feedback_value.rating;
      pass_data.post.feedback_list = delevered_feedback_value.feedback_list;
      pass_data.post.feedback = delevered_feedback_value.feedback;
    }

    let data: any = await api("/company/order_status", pass_data);

    if (data.status_code == 200) {
      let msg = "";
      if (data?.response?.status == true) {
        if (status == 1) {
          msg = "Order Accepted";
        } else if (status == 3) {
          msg = "Reached Pickup Location";
        } else if (status == 4) {
          msg =
            save_data.sector_id == 4 ? " Collected Package" : "Collected Orders";

          SetVendorFeedBackData({ ...vendorFeedBackData, open: false });
        } else if (status == 5) {
          msg = "Package Photo Uploaded";
          SetCameraDrawerData({ ...cameraDrawerData, open: false });
        } else if (status == 6) {
          msg = "Pickup Completed";
          setSkipPackageImage(false);
        } else if (status == 7) {
          msg = "Reached Location";
        } else if (status == 8) {
          msg = "Payment Collected";
        } else if (status == 9) {
          // msg = "Delivered";
          SetBuyerFeedBackData({ ...buyerFeedBackData, open: false });
          // navigate("/order/" + id_value.id);

          // msg = "Order Completed";
          if (data?.response?.company_details?.brand_id) {
            navigate("/company/" + data?.response?.company_details?.brand_id);
          } else {
            msg = "Delivered";
          }
        } else if (status == 10) {
          msg = "Order Completed";
        }
      }
      if (msg != "") {
        toast.success(msg);
      }

      if (data?.response?.data[0]) {
        if (status == 1 && context.app_data.application.platform == "android") {
          const ring = {
            function: "refreshringnotifications",
            app_type: "react_web",
          };
          mobileApp(ring);
        }
        SetSaveData(data?.response?.data[0]);
        updateStates(data);
        setStatusBtnLoader(false);
      } else {
        setStatusBtnLoader(false);
      }
    }
  }

  function openMap(dir_lat: any, dir_lng: any) {
    window.open(
      "https://www.google.com/maps/search/?api=1&query=" +
      dir_lat +
      "," +
      dir_lng,
      "_blank"
    );
  }

  function Permission() {
    if (
      context.app_data.application.platform == "android" ||
      context.app_data.application.platform == "ios"
    ) {
      mobileApp({
        function: "opencamera",
        app_type: "react_web",
        permission_description:
          "To capture a photo of delivery package, let us allow to access your camera",
      });
    }
  }

  async function grantedCameraPermisson() {
    let pass_data = {
      post: {
        camera_allowed: 1,
      },
    };
    let data: any = await api("/account/save_settings", pass_data);

    if (data.status_code == 200) {
      if (data.response?.settings) {
        setStartCameraApp(true);
        context.SetAppData((prevValue: any) => {
          prevValue.settings = data.response.settings;
          return { ...prevValue };
        });
      }
    }
  }

  async function update_status(status: any, pack_img_id: any = "") {
    if (!statusBtnLoader || pack_img_id != "") {
      setStatusBtnLoader(true);
      statusId = status;
      imgId = pack_img_id;
      setAppPlatform(context.app_data.application.platform);
      // status_update_mobile(statusId, imgId);
      if (
        context.app_data.application.platform == "android" ||
        context.app_data.application.platform == "ios" ||
        context.app_data.application.platform == "web"
      ) {
        // mobileApp({
        //   function: "checkdeliverypartnerbglocation",
        //   app_type: "react_web",
        // });
        statusId = status;
        imgId = pack_img_id;
        setAppPlatform(context.app_data.application.platform);
        await status_update_mobile(statusId, imgId);

        // mobileApp({
        //   function: "getlocationlatlng",
        //   app_type: "react_web"
        // });
      } else {
        toast.error("This Feature Not Available in Web");
      }
    }
  }

  const styles: any = {
    pageContent: {
      width: "100%",
      marginTop: "50px",
      marginBottom: "60px",
    },
    divPx: {
      paddingBottom: "2rem",
      marginBottom: "2rem",
    },
    card: {
      backgroundColor: "#fff",
      border: "none",
      boxShadow:
        "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
      borderRadius: "6px",
      textAlign: "center",
      marginBottom: "0.8rem",
      padding: "1rem",
      // fontFamily: customFonts.Celias,
    },
    row: {
      display: "flex",
    },
    col8: {
      flex: "0 0 auto",
      width: "66.66666667%",
    },
    col4: {
      flex: "0 0 auto",
      width: "33.33333333%",
      textAlign: "end",
    },
    para: {
      textAlign: "left",
      fontWeight: "500",
      color: "#000",
      fontSize: "13px",
      marginBottom: "0rem",
    },
    paras: {
      textAlign: "left",
      fontWeight: "500",
      color: "#000",
      fontSize: "13px",
      marginBottom: "0rem",
      paddingLeft: "0.2rem",
    },
    alignCenter: {
      display: "flex",
      alignItems: "center",
    },
    alignCentermt2: {
      display: "flex",
      alignItems: "center",
      marginTop: "0.5rem",
    },
    small: {
      color: "gray",
      fontSize: "11px",
      paddingLeft: "0.2rem",
      fontWeight: "500",
    },
    gray: {
      color: "gray",
      padding: "0rem 0.5rem",
    },
    end: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    paraDark: {
      marginBottom: "0rem",
      color: "#000",
      fontWeight: "bold",
      fontSize: "13px",
    },
    py3: {
      padding: "1rem 0rem",
    },
    maptest: {
      position: "relative",
      width: "100%",
      height: "200px",
      mapTypeControl: false,
      borderRadius: "10px",
    },
    "maptest:after": {
      width: "22px",
      height: "40px",
      display: "block",
      content: " ",
      position: "absolute",
      top: "50%",
      left: "50%",
      margin: "-40px 0 0 -11px",
      backgroundSize: "22px 40px",
      pointerEvents: "none",
    },
    mb2: {
      marginBottom: "1rem",
    },
    mt1: {
      marginTop: "0.5rem",
    },
    msautopx2: {
      marginLeft: "auto",
      padding: "0rem 0.5rem",
      cursor: "pointer",
    },
    smallPara: {
      color: "gray",
      marginBottom: "0px",
      fontWeight: "500",
      fontSize: "13px",
      textAlign: "start",
      marginTop: "5px",
      width: "100%",
    },
    grayPara: {
      color: "gray",
      fontWeight: "500",
      textTransform: "capitalize",
      fontSize: "11px",
    },
    borderbottom: {
      borderBottom: "1px solid #ccc",
      paddingBottom: "1rem",
    },
    mob: {
      display: "flex",
      alignItems: "center",
      margin: "0.5rem 0rem",
      cursor: "pointer",
    },
    greenSmall: {
      color: "green",
      fontSize: "13px",
      paddingLeft: "0.5rem",
    },
    brand: {
      color: "#000",
      margin: "5px 0px 0px",
      fontWeight: "600",
      fontSize: "13px",
      textAlign: "left",
    },
    link: {
      textDecoration: "none",
    },
    rightBar: {
      color: "gray",
      padding: "0px 0.5rem",
    },
    pt2: {
      paddingTop: "0.5rem",
    },
    mt2: {
      marginTop: "0.5rem",
    },
    mt3: {
      marginTop: "1rem",
    },
    w100: {
      width: "100%",
    },
    orderITem: {
      display: "flex",
      alignItems: "cemter",
      borderBottom: "1px solid #ccc",
      paddingBottom: "1rem",
    },
    delsmallPara: {
      color: "gray",
      marginBottom: "0px",
      fontWeight: "500",
      fontSize: "13px",
      textAlign: "start",
      marginTop: "5px",
      width: "100%",
      display: "flex",
    },
    primeText: {
      color: "#eef0f5",
      fontWeight: "500",
    },
    col9: {
      flex: "0 0 auto",
      width: "75%",
      textAlign: "left",
    },
    col3end: {
      flex: "0 0 auto",
      width: "25%",
      textAlign: "right",
    },
    alignEnd: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    primeEnd: {
      color: "#4a5fbe",
      marginLeft: "auto",
      fontSize: "11px",
      fontWeight: "600",
      marginBottom: "0px",
    },
    rowpt1: {
      display: "flex",
      alignItems: "center",
      paddingTop: "0.5rem",
    },
    black: {
      color: "#000",
    },
    blacksmall: {
      fontSize: "11px",
      color: "#000",
    },
    darkgraysmall: {
      color: "#525252",
      fontSize: "11px",
    },
    star: {
      color: "#ffb400",
      cursor: "pointer",
      display: "inline-flex",
      position: "relative",
      fontSize: "1.2rem",
      textAlign: "left",
      width: "100%",
    },
    Feedback: {
      marginBottom: "0rem",
      color: "#000",
      fontWeight: "bold",
      fontSize: "13px",
      textAlign: "Left",
    },
    customBottom: {
      position: "fixed",
      bottom: "0px",
      width: "100%",
      zIndex: 20,
    },
    bottomRow: {
      display: "flex",
      // padding: "1rem",
      background: "#fff",
    },
    bottom6: {
      width: "50%",
      textAlign: "center",
    },
    btnPrimeRadius: {
      backgroundColor: "#4a5fbe",
      color: "#fff",
      borderRadius: "20px",
      padding: "1rem",
      fontSize: "14px",
      fontWeight: "700",
      cursor: "pointer",
      textTransform: "none",
      border: "none",
      textAlign: "center",
      textDecoration: "none",
      verticalAlign: "middle",
      width: "100%",
      boxShadow:
        "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
    },
    primeBtn: {
      backgroundColor: "#4a5fbe",
      color: "#fff",
      fontWeight: "700",
      // borderRadius: "20px",
      padding: "1rem 1.5rem",
      fontSize: "14px",
      cursor: "pointer",
      textTransform: "none",
      border: "none",
      textAlign: "center",
      textDecoration: "none",
      verticalAlign: "middle",
      width: "100%",
      boxShadow:
        "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      marginBottom: "45px",
    },
    whiteBtn: {
      backgroundColor: "#fff",
      color: "#000",
      fontWeight: "700",
      // borderRadius: "20px",
      padding: "1rem 1.5rem",
      fontSize: "14px",
      cursor: "pointer",
      textTransform: "none",
      border: "none",
      textAlign: "center",
      textDecoration: "none",
      verticalAlign: "middle",
      width: "100%",
      boxShadow:
        "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
    },
    textCenter: {
      textAlign: "center",
    },
    vSmall: {
      fontWeight: "600",
      fontSize: "12px",
      textAlign: "center",
      paddingTop: "0.5rem",
    },
    delivFeedback: {
      backgroundColor: "#fff",
      padding: "0px 1.5rem 1.5rem",
      borderRadiusTop: "6px",
    },
    feedHead: {
      display: "flex",
      padding: "1rem 0rem",
      backgroundColor: "#f9f9f9",
      alignITems: "center",
      borderBottom: "1px solid #ccc",
    },
    col10: {
      flex: "0 0 auto",
      width: "83.33333333%",
    },
    col2End: {
      flex: "0 0 auto",
      width: "16.66666667%",
      marginLeft: "auto",
      cursor: "pointer",
    },
    deliIssue: {
      display: "flex",
      cursor: "pointer",
      marginBottom: "1rem",
    },
    ps2: {
      paddingLeft: "0.5rem",
    },
    formControl: {
      display: "block",
      width: "100%",
      padding: "0.375rem 0.75rem",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "1.5",
      color: "#212529",
      backgroundColor: "#fff",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      marginTop: "0.5rem",
    },
    submit: {
      padding: "1rem 0.5rem 0.5rem 0.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      paddingRight: "0.75rem",
      paddingLeft: "0.75rem",
      marginRight: "auto",
      marginLeft: "auto",
    },
    webcam: {
      textCenter: "center",
      cursor: "pointer",
      padding: "1rem",
    },
  };

  async function openChat(data: any) {
    SetChatModal({
      is_open: true,
      data: data,
    });
  }

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      {id_value?.alert &&
        save_data.status_id == "0" &&
        save_data.hasOwnProperty("expired") &&
        save_data.expired == false && (
          <audio id="ring">
            <source
              src={require("../../assets/audio/ringing.mp3")}
              type="audio/mpeg"
            />
          </audio>
        )}

      {/* {curr_loc?.lat &&  */}
      {(save_data.hasOwnProperty("expired") &&
        save_data.expired == false && save_data.hasOwnProperty("cancelled") &&
        save_data?.cancelled == false) &&
        (save_data.status_id == 0 ||
          (orderStatusId > 0 && orderStatusId < 10)) && (
          <Map
            loc={[deliver_loc, res_locc]}
            status_id={parseInt(save_data.status_id)}
          />
        )}
      {/* } */}
      {(save_data.hasOwnProperty("expired") && save_data.expired == false && save_data.hasOwnProperty("cancelled") &&
        save_data?.cancelled == false) && (
          <>
            {save_data.status_id == "0" && (
              <div className="bottom-buttons">
                <div className="d-flex">
                  <button
                    className="btn btn-warning btn-lg br-0 w-100 py-13"
                    onClick={() => {
                      SetDeleteModal({
                        is_open: true,
                        id: id_value.id,
                      });
                    }}
                    disabled={statusBtnLoader ? true : false}
                  >
                    {/* {statusBtnLoader ? (
                        <span className="btn-loader"></span>
                      ) : ( */}
                    <span>Decline Order</span>
                    {/* )} */}
                  </button>
                  <button
                    className="btn btn-success btn-lg br-0 w-100 py-13"
                    onClick={() => {
                      update_status(1, "");
                    }}
                  >
                    {statusBtnLoader ? (
                      <span className="btn-loader"></span>
                    ) : (
                      <span>Accept Order</span>
                    )}
                  </button>
                </div>
              </div>
            )}

            {save_data.status_id == "1" && (
              <div className="bottom-buttons p-3 bg-white">
                <SwipeableButton
                  onSuccess={() => {
                    update_status(3, "");
                  }}
                  color='#6ab04c' text="Reached Pickup Location" text_slid="Reached Pickup Location" showLoader={statusBtnLoader}
                />
              </div>
              // <button
              //   type="button"
              //   className="btn btn-primary btn-lg br-0 w-100 py-13"
              //   onClick={() => {
              //     update_status(3, "");
              //   }}
              // >
              //   {statusBtnLoader ? (
              //     <span className="btn-loader"></span>
              //   ) : (
              //     <span>Reached Pickup Location</span>
              //   )}
              // </button>
            )}

            {save_data.status_id == "3" && (
              <div className="bottom-buttons p-3 bg-white">
                <SwipeableButton
                  ref={SwipeableButtonRef}
                  onSuccess={() => {
                    SetVendorFeedBackData({
                      ...vendorFeedBackData,
                      open: true,
                    });
                  }}
                  color='#6ab04c' text={save_data.sector_id == 4 ? " Collected Package" : "Collected Orders"} text_slid={save_data.sector_id == 4 ? " Collected Package" : "Collected Orders"} showLoader={statusBtnLoader}
                />
              </div>
              // <button
              //   type="button"
              //   className="btn btn-primary btn-lg br-0 w-100 py-13"
              //   onClick={() => {
              //     SetVendorFeedBackData({
              //       ...vendorFeedBackData,
              //       open: true,
              //     });
              //   }}
              // >
              //   {statusBtnLoader ? (
              //     <span className="btn-loader"></span>
              //   ) : (
              //     <span>
              //       {save_data.sector_id == 4
              //         ? " Collected Package"
              //         : "Collected Orders"}
              //     </span>
              //   )}
              // </button>
            )}

            {save_data.status_id == "4" && !skipPackageImage && (
              <div className="bottom-buttons">
                <button
                  type="button"
                  className="btn btn-light btn-lg br-0 w-100 py-13"
                  onClick={() => {
                    if (
                      context.app_data.application.platform == "android" ||
                      context.app_data.application.platform == "ios"
                    ) {
                      Permission();
                    } else {
                      if (context.app_data.settings.camera_allowed == true) {
                        // setWebcam(true);
                        SetCameraDrawerData({ ...cameraDrawerData, open: true });
                        setMountCamera(true);
                      } else {
                        setCameraPermissionModal((prevValue: any) => {
                          prevValue.is_open = true;
                          return { ...prevValue };
                        });
                      }
                    }
                  }}
                >
                  {statusBtnLoader ? (
                    <span className="btn-loader"></span>
                  ) : (
                    <span>Take Package Image</span>
                  )}
                </button>
              </div>
            )}
            {(save_data.status_id == "5" || skipPackageImage) && (
              <div className="bottom-buttons p-3 bg-white">
                <SwipeableButton
                  onSuccess={() => {
                    update_status(6, "");
                  }}
                  color='#6ab04c' text="Pickup Completed" text_slid="Pickup Completed" showLoader={statusBtnLoader}
                />
              </div>
              // <button
              //   type="button"
              //   className="btn btn-primary btn-lg br-0 w-100 py-13"
              //   onClick={() => {
              //     update_status(6, "");
              //   }}
              // >
              //   {statusBtnLoader ? (
              //     <span className="btn-loader"></span>
              //   ) : (
              //     <span>Pickup Completed</span>
              //   )}
              // </button>
            )}

            {save_data.status_id == "6" && (
              <div className="bottom-buttons p-3 bg-white">
                <SwipeableButton
                  onSuccess={() => {
                    update_status(7, "");
                  }}
                  color='#6ab04c' text="Reached Delivery Location" text_slid="Reached Delivery Location" showLoader={statusBtnLoader}
                />
              </div>
              // <button
              //   type="button"
              //   className="btn btn-primary btn-lg br-0 w-100 py-13"
              //   onClick={() => {
              //     update_status(7, "");
              //   }}
              // >
              //   {statusBtnLoader ? (
              //     <span className="btn-loader"></span>
              //   ) : (
              //     <span>Reached Delivery Location</span>
              //   )}
              // </button>
            )}

            {save_data.status_id == "7" &&
              save_data.payment_type == "2" &&
              save_data.payment_collected == false && (
                <div className="bottom-buttons p-3 bg-white">
                  <SwipeableButton
                    ref={SwipeableButtonRefCod}
                    onSuccess={() => {
                      SetCodModal({
                        is_open: true,
                        id: id_value.id,
                        cod_amount: save_data.cod_amount,
                      });
                    }}
                    color='#6ab04c' text="Payment Collected" text_slid="Payment Collected" showLoader={statusBtnLoader}
                  />
                </div>
                // <button
                //   type="button"
                //   className="btn btn-primary btn-lg br-0 w-100 py-13"
                //   onClick={() => {
                //     SetCodModal({
                //       is_open: true,
                //       id: id_value.id,
                //       cod_amount: save_data.cod_amount,
                //     });
                //   }}
                // >
                //   {statusBtnLoader ? (
                //     <span className="btn-loader"></span>
                //   ) : (
                //     <span>Payment Collected</span>
                //   )}
                // </button>
              )}

            {(save_data.status_id == "8" ||
              (save_data.status_id == "7" && save_data.payment_type == "1")) && (
                <div className="bottom-buttons p-3 bg-white">
                  <SwipeableButton
                    ref={SwipeableButtonRefDelivery}
                    onSuccess={() => {
                      SetBuyerFeedBackData({ ...buyerFeedBackData, open: true });
                    }}
                    color='#6ab04c' text="Delivery Completed" text_slid="Delivery Completed" showLoader={statusBtnLoader}
                  />
                </div>
                // <button
                //   type="button"
                //   className="btn btn-primary btn-lg br-0 w-100 py-13"
                //   onClick={() => {
                //     // SetDeliveryFeedback(true);
                //     SetBuyerFeedBackData({ ...buyerFeedBackData, open: true });
                //   }}
                // >
                //   {statusBtnLoader ? (
                //     <span className="btn-loader"></span>
                //   ) : (
                //     <span>Delivery Completed</span>
                //   )}
                // </button>
              )}

            {save_data.status_id == "9" && (
              <div className="bottom-buttons p-3 bg-white">
                <SwipeableButton
                  onSuccess={() => {
                    update_status(10, "");
                  }}
                  color='#6ab04c' text="Order Completed" text_slid="Order Completed" showLoader={statusBtnLoader}
                />
              </div>
              // <button
              //   type="button"
              //   className="btn btn-primary btn-lg br-0 w-100 py-13"
              //   onClick={() => {
              //     update_status(10, "");
              //   }}
              // >
              //   {statusBtnLoader ? (
              //     <span className="btn-loader"></span>
              //   ) : (
              //     <span>Order Completed</span>
              //   )}
              // </button>
            )}
            {/* )} */}
          </>
        )}

      <ModalDeclineOrder
        open={deleteModal.is_open}
        id={deleteModal.id}
        done={(data: any) => {
          const ring = {
            function: "refreshringnotifications",
            app_type: "react_web",
          };
          mobileApp(ring);
          SetDeleteModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data?.status) {
            if (data?.company_details?.brand_id) {
              setTimeout(() => {
                navigate("/company/" + data?.company_details?.brand_id);
              }, 500);
            } else {
              new_order();
            }
          } else {
            new_order();
          }
        }}
        close={(data: any) => {
          SetDeleteModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          new_order();
        }}
      />

      <ModalCod
        open={codModal.is_open}
        id={codModal.id}
        cod_amount={codModal.cod_amount}
        close={(data: any) => {
          SetCodModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          SwipeableButtonRefCod?.current?.refresh();
          if (data.action == "save") {
            new_order();
          }
        }}
      />
      <ModalOrderDelayed
        open={odelayedModal.is_open}
        order={odelayedModal.order}
        close={(data: any) => {
          SetOdelayedModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });

          if (data.action == "save") {
            new_order();
          }
        }}
      />

      <OrderChat
        open={chatModal.is_open}
        data={chatModal.data}
        close={(data: any) => {
          SetChatModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            // new_order();
          }
        }}
      />

      <div className="swipeable-drawer-container-1 ord_detail_drawer">
        <SwipeableDrawer
          refreshToken={AppHelper.randomStr(10)}
          propsData={propsData}
          preventBackDropClose={true}
          parentClass={".swipeable-drawer-container-1"}
          positionCallback={(data: any) => { }}
          moveToBreak={moveToBreak}
        >
          <div className="swipeable-drawer-content px-2">
            <div className="px-2 pt-3 pb-100">
              <Header data={save_data} />
              <div>
                {orderStatusId > 0 && orderStatusId < 10 ? (
                  <>
                    {orderStatusId == 1 && (
                      <Pickup data={save_data} outer_data={outer_data} chatCallback={(data: any) => openChat(data)} />
                    )}
                    {orderStatusId == 3 && (
                      <>
                        <ShipmentOrderId data={save_data} />
                        <Products data={save_data} />
                        <Pickup data={save_data} outer_data={outer_data} chatCallback={(data: any) => openChat(data)} />
                        <Package data={save_data} />
                      </>
                    )}
                    {orderStatusId == 4 && !skipPackageImage && (
                      <CapturePackageImage
                        data={save_data}
                        outer_data={outer_data}
                        skipCapture={() => {
                          setSkipPackageImage(true);
                        }}
                        statusBtnLoader={statusBtnLoader}
                      />
                    )}
                    {(orderStatusId == 5 || skipPackageImage) && (
                      <PackageImage data={save_data} outer_data={outer_data} />
                    )}
                    {orderStatusId == 6 && (
                      <Delivery data={save_data} outer_data={outer_data} chatCallback={(data: any) => openChat(data)} />
                    )}
                    {orderStatusId == 7 && (
                      <>
                        <Delivery data={save_data} outer_data={outer_data} chatCallback={(data: any) => openChat(data)} />
                        {save_data?.payment_type == 2 &&
                          save_data?.payment_collected == false && (
                            <CollectCashDelivery
                              data={save_data}
                              outer_data={outer_data}
                            />
                          )}
                      </>
                    )}
                    {orderStatusId == 8 && (
                      <>
                        <Delivery data={save_data} outer_data={outer_data} chatCallback={(data: any) => openChat(data)} />
                        {save_data?.payment_type == 2 &&
                          save_data?.payment_collected == true && (
                            <div className="box-1 fw-500 p-3 mb-3">
                              <div className="mb-1">
                                <i className="fas fa-check-circle text-green me-1"></i>{" "}
                                Cash collected from delivery person
                              </div>
                              {save_data.collected_amount != 0 && (
                                <div className="text-dark fs-20 text-center">
                                  &#x20B9; {save_data.collected_amount}
                                </div>
                              )}
                            </div>
                          )}
                      </>
                    )}
                    {orderStatusId == 9 && (
                      <>
                        <div className="box-1 fw-500 p-3 mb-3">
                          <i className="fas fa-check-circle text-green me-1"></i>{" "}
                          Pickup Completed
                        </div>
                        {save_data?.payment_type == 2 &&
                          save_data?.payment_collected == true && (
                            <div className="box-1 fw-500 p-3 mb-3">
                              <i className="fas fa-check-circle text-green me-1"></i>{" "}
                              Payment Successfull
                            </div>
                          )}
                        <div className="box-1 fw-500 p-3 mb-3">
                          <i className="fas fa-check-circle text-green me-1"></i>{" "}
                          Delivery Completed
                        </div>
                      </>
                    )}

                    {/* {parseInt(save_data.status_id) >= 1 &&
                      parseInt(save_data.status_id) <= 5 && (
                        <Pickup data={save_data} outer_data={outer_data} />
                      )}
                    {parseInt(save_data.status_id) >= 6 &&
                      parseInt(save_data.status_id) <= 10 && (
                        <Delivery data={save_data} outer_data={outer_data} />
                      )} */}
                  </>
                ) : (
                  <>
                    <Pickup data={save_data} outer_data={outer_data} chatCallback={(data: any) => openChat(data)} />
                    <PickupFeedback data={save_data} outer_data={outer_data} />
                    <Delivery data={save_data} outer_data={outer_data} chatCallback={(data: any) => openChat(data)} />
                    <DeliveryFeedback
                      data={save_data}
                      outer_data={outer_data}
                    />
                    <Products data={save_data} outer_data={outer_data} />
                    <Package data={save_data} outer_data={outer_data} />
                  </>
                )}
              </div>

              {parseInt(save_data.sector_id) == 4 &&
                save_data.status_id < 8 &&
                save_data?.instructions && (
                  <div className="mt-3">
                    <Instructions data={save_data} />
                  </div>
                )}
              {save_data?.delivery_instructions?.length > 0 && (
                <div className="mt-3">
                  <InstructionsList data={save_data} />
                </div>
              )}
            </div>
          </div>
        </SwipeableDrawer>
      </div>
      <div className="swipeable-drawer-container ord_detail_drawer2">
        {showPickupFeedbackDrawer && (
          <SwipeableDrawer
            refreshToken={AppHelper.randomStr(10)}
            propsData={vendorFeedBackData}
            parentClass={".swipeable-drawer-container"}
            preventBackDropClose={true}
          >
            <div className="swipeable-drawer-content p-3">
              <div className="d-flex">
                <h6>Pickup Feedback</h6>
                <div className="ms-auto">
                  <svg
                    onClick={() => {
                      SetVendorFeedBackData({
                        ...vendorFeedBackData,
                        open: false,
                      });
                      SwipeableButtonRef?.current?.refresh();
                    }}
                    width="15"
                    fill="#888888"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                  </svg>
                </div>
              </div>
              <div>
                <div style={styles.mt2}>
                  <small style={styles.darkgraysmall}>
                    Please provide a rating of this pickup
                  </small>
                  <div style={styles.mt1}>
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event: any, newValue: any) => {
                        SetValue(newValue);
                        collected_feedback_value.rating = newValue;
                      }}
                    />
                  </div>
                </div>

                {value < 5 ? (
                  <div style={styles.mt2}>
                    <small style={styles.darkgraysmall}>
                      Tell us the issues
                    </small>
                    <div style={styles.mt2}>
                      {collected_issue?.map((data: any, o_index: any) => (
                        <label style={styles.deliIssue} key={o_index}>
                          <input
                            type="checkbox"
                            value={data.id}
                            onChange={(e) => {
                              collected_feedback_value.feedback_list =
                                e.target.value;
                            }}
                          />
                          <span style={styles.ps2}>{data.label}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                ) : null}

                <div style={styles.mt2}>
                  <small style={styles.darkgraysmall}>Give any feedback?</small>
                  <textarea
                    name="review"
                    id=""
                    cols={30}
                    rows={2}
                    onChange={(e) => {
                      collected_feedback_value.feedback = e.target.value;
                    }}
                    style={styles.formControl}
                  ></textarea>
                </div>

                <div
                  style={styles.submit}
                  onClick={() => {
                    // SetPickupFeedback(false);
                    update_status(4, "");
                  }}
                >
                  <button style={styles.btnPrimeRadius}>
                    {statusBtnLoader ? (
                      <span className="btn-loader"></span>
                    ) : (
                      <span>
                        Submit
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </SwipeableDrawer>
        )}

        {showDeliveryFeedbackDrawer && (
          <SwipeableDrawer
            refreshToken={AppHelper.randomStr(10)}
            propsData={buyerFeedBackData}
            parentClass={".swipeable-drawer-container"}
            preventBackDropClose={true}
          >
            <div className="swipeable-drawer-content p-3">
              <div className="d-flex">
                <h6>Delivery Feedback</h6>
                <div className="ms-auto">
                  <svg
                    onClick={() => {
                      SetBuyerFeedBackData({
                        ...buyerFeedBackData,
                        open: false,
                      });
                      SwipeableButtonRefDelivery?.current?.refresh();
                    }}
                    width="15"
                    fill="#888888"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                  </svg>
                </div>
              </div>
              <div>
                <div style={styles.mt2}>
                  <small style={styles.darkgraysmall}>
                    Please provide a rating of this delivery
                  </small>
                  <div>
                    <Rating
                      name="simple-controlled2"
                      value={del_value}
                      onChange={(event: any, newValue: any) => {
                        SetDelValue(newValue);
                        delevered_feedback_value.rating = newValue;
                      }}
                    />
                  </div>
                </div>

                {del_value < 5 ? (
                  <div style={styles.mt3}>
                    <small style={styles.darkgraysmall}>
                      Tell us the issues
                    </small>
                    <div style={styles.mt3}>
                      {delevered_issue?.map((data: any, o_index: any) => (
                        <label style={styles.deliIssue} key={o_index}>
                          <input
                            type="checkbox"
                            value={data.id}
                            onChange={(e) => {
                              delevered_feedback_value.feedback_list =
                                e.target.value;
                            }}
                          />
                          <span style={styles.ps2}>{data.label}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                ) : null}

                <div style={styles.mt2}>
                  <small style={styles.darkgraysmall}>Give any feedback?</small>
                  <textarea
                    name="review"
                    id=""
                    cols={30}
                    rows={2}
                    onChange={(e) => {
                      delevered_feedback_value.feedback = e.target.value;
                    }}
                    style={styles.formControl}
                  ></textarea>
                </div>

                <div
                  style={styles.submit}
                  onClick={() => {
                    // SetDeliveryFeedback(false);
                    update_status(9, "");
                  }}
                >
                  <button style={styles.btnPrimeRadius}>
                    {statusBtnLoader ? (
                      <span className="btn-loader"></span>
                    ) : (
                      <span>
                        Submit
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </SwipeableDrawer>
        )}
        {showCameraDrawer && (
          <SwipeableDrawer
            refreshToken={AppHelper.randomStr(10)}
            propsData={cameraDrawerData}
            parentClass={".swipeable-drawer-container"}
          >
            <div className="swipeable-drawer-content">
              <div className="text-end py-1 px-3">
                <svg
                  onClick={() => {
                    SetCameraDrawerData({ ...cameraDrawerData, open: false });
                  }}
                  width="15"
                  fill="#888888"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                </svg>
              </div>
              {mountCamera ? (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  style={{
                    width: "100%",
                    height: "80vh",
                    objectFit: "cover",
                    background: "#000000",
                  }}
                  videoConstraints={{ facingMode: { exact: "environment" } }}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "80vh",
                    objectFit: "cover",
                    background: "#000000",
                  }}
                >
                  &nbsp;
                </div>
              )}
              <div
                className="text-center p-2"
                onClick={() => {
                  capture();
                }}
              >
                {statusBtnLoader ? (
                  <span className="btn-loader"></span>
                ) : (
                  <i
                    className="fas fa-circle"
                    style={{ fontSize: "50px", color: "#010092" }}
                  ></i>
                )}
              </div>
            </div>
          </SwipeableDrawer>
        )}
      </div>
      <CameraPermission
        open={cameraPermissionModal.is_open}
        close={(data: any) => {
          setCameraPermissionModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            if (data.settings) {
              context.SetAppData((prevValue: any) => {
                prevValue.settings = data.settings;
                return { ...prevValue };
              });
              if (data.settings.camera_allowed) {
                if (
                  context.app_data.application.platform == "android" ||
                  context.app_data.application.platform == "ios"
                ) {
                  Permission();
                  // setMountCamera(true);
                } else {
                  SetCameraDrawerData({ ...cameraDrawerData, open: true });
                  setMountCamera(true);
                }
              }
            }
          }
        }}
      />
    </div>
  );
}
