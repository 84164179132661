import React, { useState, useEffect } from "react";
// import bootstrap from "../../../public/libraries/bootstrap/js/bootstrap";
import * as bootstrap from "bootstrap";

import { useParams } from "react-router-dom";

import { api } from "../../utils/Api";

const cod_initial_value = {
  collected_amount: "",
};

let myModal: any;
export default function ModalCod(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });

  const [id_value, SerIdValue] = useState(useParams());
  const [cod_value, SetCodValue] = useState(cod_initial_value);

  const [statusBtnLoader, setStatusBtnLoader] = useState(false);

  useEffect(() => {
    let myModal1: any = document.getElementById("Cod");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {

      outlet_value_on_change("collected_amount", props.cod_amount);
      let cod: any = document.getElementById("Cod");
      myModal = new bootstrap.Modal(cod, {});
      myModal.show();
    }
  }, [props.open]);

  async function deleteUserRole() {
    if(!statusBtnLoader){
      setStatusBtnLoader(true);
      let pass_data = {
        post: {
          order_id: id_value.id,
          status: "8",
          collected_amount: cod_value.collected_amount,
        },
      };
      let data: any = await api("/company/order_status", pass_data);
    
      if (data.status_code == 200) {
        let item: any = close_data;
        item.action = "save";
        item.value = data.response.data;
        SetCloseData(item);
        setStatusBtnLoader(false);
        // SetOutletData(outlet_initial_value);
    
        myModal.hide();
      } else {
        setStatusBtnLoader(false);
      }
    }
  }

  function outlet_value_on_change(name: any, value: any) {
    SetCodValue((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  return (
    <div
      className="modal fade"
      id="Cod"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 br-20 mx-3">
          <div className="modal-body">
            <h6 className="mb-4 mt-2">Collect Amount ₹{props.cod_amount}</h6>
            <div className="mb-4">
              <label className="form-label mb-0">Amount</label>
              <input
                type="number"
                //   value={outlet_data.email}
                //   disabled={props.type == "view" ? true : false}
                value={cod_value.collected_amount}
                className="form-control"
                onChange={(e) => {
                  outlet_value_on_change("collected_amount", e.target.value);
                }}
              />
            </div>
            <div className="">
              <button
                type="button"
                className="btn btn-success fw-500 w-100 br-20"
                name="channel_id"
                value={props.id}
                onClick={(e) => {
                  deleteUserRole();
                  //  deleteUserRole(e.target.name,e.target.value)
                }}
              >
                {statusBtnLoader ? (
                  <span className="btn-loader"></span>
                ) : (
                  <span>
                    Collected
                  </span>
                )}
              </button>
              <button
                type="button"
                className="btn text-muted btn-sm w-100 mt-3"
                data-bs-dismiss="modal"
                disabled={statusBtnLoader?true:false}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
