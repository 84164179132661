import { useEffect, useRef, useState } from "react";
import useIntersection from "./useIntersection";

const LazyLoader = (props: any) => {
  const [data, setData]: any = useState({});
  const [divClass, setdivClass] = useState("lazy-div");
  useEffect(() => {
    if (props) {
      setData(props);
    }
  }, [props]);
  useEffect(() => {
    if (data) {
      setdivClass(data?.className ? data?.className + " lazy-div" : "lazy-div");
    }
  }, [data]);

  return (
    <>
      {((data.hasOwnProperty("show") && data?.show) ||
        !data.hasOwnProperty("show")) && (
        <>
          {data?.type === "span" ? (
            <span className={divClass} style={data?.style}></span>
          ) : (
            <div className={divClass} style={data?.style}></div>
          )}
        </>
      )}
    </>
  );
};

const ImageLazyLoader = (props: any) => {
  const [data, setData]: any = useState();
  const [src, setSrc]: any = useState("");
  const [tSrc, setTSrc]: any = useState("");
  const [imgLoaded, setImgLoaded]: any = useState(false);
  const [imgTLoaded, setImgTLoaded]: any = useState(false);
  const [parentClass, setParentClass] = useState("");
  const img_ref = useRef(null);
  const isInViewport = useIntersection(img_ref, "0px");
  useEffect(() => {
    if (props) {
      setData(props);
    }
  }, [props]);
  useEffect(() => {
    if (isInViewport) {
      setSrc(props?.src);
    }
  }, [props?.src, isInViewport]);
  useEffect(() => {
    if (isInViewport) {
      if (!imgLoaded) {
        setTSrc(props?.tSrc);
      }
    }
  }, [props?.tSrc, isInViewport, imgLoaded]);

  useEffect(() => {
    function changeParent() {
      if (imgLoaded || imgTLoaded) {
        setParentClass(data?.parentClassName ? data?.parentClassName : " ");
      } else {
        setParentClass(
          data?.parentClassName
            ? data?.parentClassName + " lazy-img"
            : "lazy-img"
        );
      }
    }
    changeParent();
    if (imgLoaded) {
      setTSrc("");
    }
  }, [data?.parentClassName, imgLoaded, imgTLoaded]);

  return (
    <>
      <div ref={img_ref} className={parentClass} style={data?.parentStyle}>
        {tSrc && (
          <img
            className={data?.className}
            src={tSrc}
            width={data?.width}
            height={data?.height}
            style={data?.style}
            alt={data?.alt}
            onLoad={() => {
              setImgTLoaded(true);
            }}
          />
        )}
        {src !== "" && (
          <img
            className={data?.className}
            src={src}
            width={data?.width}
            height={data?.height}
            style={data?.style}
            alt={data?.alt}
            onLoad={() => {
              setImgLoaded(true);
            }}
          />
        )}
      </div>
      {props.children}
    </>
  );
};

const ImgLazyLoader = (props: any) => {
  const [data, setData]: any = useState();
  const [src, setSrc]: any = useState("");
  const [imgLoaded, setImgLoaded]: any = useState(false);
  const [parentClass, setParentClass] = useState("");
  const img_ref = useRef(null);
  const isInViewport = useIntersection(img_ref, "0px");
  useEffect(() => {
    if (props) {
      setData(props);
    }
  }, [props]);
  useEffect(() => {
    if (isInViewport) {
      setSrc(props?.src);
    }
  }, [props?.src, isInViewport]);

  useEffect(() => {
    function changeParent() {
      if (imgLoaded) {
        setParentClass(data?.className ? data?.className : "");
      } else {
        setParentClass(data?.className ? data?.className + " lazy" : "lazy");
      }
    }
    changeParent();
  }, [data?.className, imgLoaded]);

  return (
    <>
      <img
        ref={img_ref}
        className={parentClass}
        src={src}
        width={data?.width}
        height={data?.height}
        style={data?.style}
        alt={data?.alt}
        onLoad={() => {
          setImgLoaded(true);
        }}
      />
    </>
  );
};

export { LazyLoader, ImageLazyLoader, ImgLazyLoader };
