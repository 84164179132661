import { useState, useEffect } from "react";

export default function ShipmentOrderId(props: any) {
  const [data, SetData]: any = useState({});
  useEffect(() => {
    if (props) {
      SetData(props.data);
    }
  }, [props]);
  return (
    <>
      {(data?.seller_shipment_id) && (
        <div className="box-1 p-3 mb-3 text-center">
          <div className="fw-500 text-uppercase">
            Order ID
          </div>
          <div className="fs-30 fw-500 text-primary">
            {data.seller_shipment_id}
          </div>
        </div>
      )}
    </>
  );
}
