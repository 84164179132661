import React, { useState, useEffect } from "react";
// import bootstrap from "bootstrap/dist/js/bootstrap";
import * as bootstrap from "bootstrap";

import { useParams } from "react-router-dom";

import { api } from "../../utils/Api";

const outlet_initial_value = {
  channel_id: "",
};

export default function ModalDeclineOrder(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  let myModal: any;
  const [statusBtnLoader, setStatusBtnLoader] = useState(false);

  const [id_value, SerIdValue] = useState(useParams());
  const [outlet_data, SetOutletData] = useState(outlet_initial_value);

  useEffect(() => {
    let myModal1: any = document.getElementById("DeclineOrderModalDet");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      let DeclineOrderModalDet: any = document.getElementById("DeclineOrderModalDet");

      myModal = new bootstrap.Modal(DeclineOrderModalDet, {});
      myModal.show();
    }
  }, [props.open]);

  async function deleteUserRole() {
    if (!statusBtnLoader) {
      setStatusBtnLoader(true);
      let pass_data = {
        post: {
          order_id: props.id,
          status: "2",
        },
      };
  
      let data: any = await api("/company/order_status", pass_data);
  
      if (data.status_code == 200) {
        let item: any = close_data;
        item.action = "save";
        item.value = data.response.data;
        item.status = data.response.status;
        item.company_details = data.response?.company_details;
        props.done(item);

        // SetCloseData(item);
        setStatusBtnLoader(false);
        // SetOutletData(outlet_initial_value);
        myModal.hide();
      } else {
        setStatusBtnLoader(false);
      }
    }
  }

  return (
    <div
      className="modal fade"
      id="DeclineOrderModalDet"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              Decline Order
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              disabled={statusBtnLoader ? true : false}
            ></button>
          </div>
          <div className="modal-body">Are you sure want to decline order?</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              name="channel_id"
              value={props.id}
              onClick={(e) => {
                deleteUserRole();
                //  deleteUserRole(e.target.name,e.target.value)
              }}
            >
              {statusBtnLoader ? (
                <span className="btn-loader"></span>
              ) : (
                <span>Decline</span>
              )}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              disabled={statusBtnLoader ? true : false}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
