import { useParams } from "react-router-dom";
import Footer from "../../component/Footer/Footer";

export default function SignupSuccess() {
  const params = useParams();
  return (
    <div
      className="d-flex flex-column justify-content-center bg-white p-3"
      style={{ height: window.innerHeight }}
    >
      <div>
        <a
          href={"https://1superapp.com/dashboard"}
          className="btn text-muted btn-sm"
        >
          <i className="fas fa-arrow-left me-2"></i>
          Back
        </a>
      </div>
      <div className="text-center my-auto">
        <img
          src="https://1superapp.com/assets/images/1superapp-logo-plain.png"
          className="img-fluid mb-2"
          width="60"
        />
        <h4 className="mb-3">Delivery Partner</h4>
        {params?.alert ? (
          <>
            <h6 className="mb-1">
            {params?.alert == "re_applied" ? "Your application resubmitted successfully":"Your application has been received successfully"}
              
            </h6>
            <div>Our executive will contact you shortly</div>
          </>
        ) : (
          <>
            <h6 className="mb-1">Your application is in processing</h6>
          </>
        )}
      </div>
      <Footer only_one_icon={true} />
    </div>
  );
}
