import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import toast, { Toaster } from "react-hot-toast";
import { LazyLoader, ImageLazyLoader } from "../../utils/LazyLoader/index";

export default function Companies(props: any) {
  const context: any = useContext(DataContext);
  const [companies_data, SetCompaniesData] = useState([]);
  const [noCompany, setNoCompany] = useState(false);
  const [imgBaseUrl, setImgBaseUrl]: any = useState();

  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (context.app_data.image_base_url) {
      setImgBaseUrl(context.app_data.image_base_url);
    }
  }, [context.app_data.image_base_url]);

  async function init() {
    let data: any = await api("/init");
    console.log("brand_users response :", data);
    if (data.status_code == 200) {
      SetCompaniesData(data.response.companies);
      if (data.response?.companies?.length == 0) {
        setNoCompany(true);
      }
    }
  }

  return (
    <div className="container py-3">
      <Toaster />
      <h6>Companies</h6>
      {companies_data.map((ele: any, index: any) => (
        <Link
          to={"/company/" + ele.brand_id + ""}
          className="link-text"
          key={index}
        >
          <div className="d-flex align-items-center box-1 p-3">
            {imgBaseUrl && ele.logo ? (
              <ImageLazyLoader
                parentClassName="profile-div"
                className="img-fluid"
                src={imgBaseUrl + ele.logo}
              />
            ) : (
              <ImageLazyLoader
                parentClassName="profile-div"
                className="img-fluid"
                src={require("../../assets/img/profile.jpg")}
              />
            )}
            <div className="ms-2">
              <div className="fw-500">
                {ele.name}
                {ele.checked && (
                  <i className="ms-1 fas fa-circle text-success fs-10"></i>
                )}
                {ele.has_active_order && (
                  <i className="ms-2 fas fa-biking text-success"></i>
                )}
              </div>
            </div>
            <i className="ms-auto fas fa-chevron-right"></i>
          </div>
        </Link>
      ))}
      {noCompany && (
        <div className="box-1 p-3 pb-3 text-center">
          <img src={require("../../assets/icon/delay.png")} width="60" />
          <div className="mt-2 fw-500">No Company</div>
        </div>
      )}
    </div>
  );
}
