import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";

import { mobileApp } from "../../utils/MobileApp";

import toast, { Toaster } from "react-hot-toast";

import Menu from "../Company/Menu";
import OrderCard from "../CompanyOrders/OrderCard";

let timer: any;
let brand_id: any,
  app_platform = "web";

export default function Orders(props: any) {
  const navigate = useNavigate();
  const context: any = useContext(DataContext);
  const [params, setParams]: any = useState(useParams());
  const [companyData, setCompanyData]: any = useState({});
  const [orderData, setOrderData]: any = useState([]);
  let page_no = 1;
  const [next_page, SetNextPage] = useState(false);
  const [noOrderData, SetNoOrderData] = useState(false);

  function handleMenuClick(data: any) {
    if (data?.error_message) {
      toast.error(data.error_message);
    }
    if (data?.success_message) {
      toast.success(data.success_message);
    }
    if (data?.reloadData) {
      getOrders();
    }
  }

  useEffect(() => {
    app_platform = context.app_data.application.platform;

    getOrders();
    return () => {
      setOrderData([]);
      SetNoOrderData(false);
      SetNextPage(false);
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [props.type]);
  useEffect(() => {
    if (props.type == "active" && companyData?.checked && !companyData?.reservation_blocked) {
      timer = setInterval(() => getOrders('first'), 10000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [props.type, companyData?.checked, companyData?.reservation_blocked]);

  async function getOrders(type: any = "first") {
    if (type == "next") {
      page_no++;
    } else if (type == "next") {
      page_no = 1;
    }
    let pass_data = {
      get: {
        company_id: params.id,
        view: props.type,
        page: page_no,
      },
    };
    let data: any = await api("/company/orders", pass_data);

    if (data.status_code == 200) {
      setCompanyData(data.response.company_details);
      if (type == "first") {
        setOrderData(data.response.data);
        if(props.type == "active" && data?.response?.data.length > 0 && data?.response?.data[0].id && data?.response?.data[0].status_id == 0){
          navigate('/order_details/'+data.response.data[0].id+'/ring');
        } else if(data?.response?.cancelled_ring_orders.length > 0 && data?.response?.cancelled_ring_orders[0]) {
          navigate('/order/'+data.response.cancelled_ring_orders[0]);
        }
        if(data.response?.data?.length == 0){
          SetNoOrderData(true);
        }
      } else if (type == "next") {
        setOrderData((prevValue: any) => [...prevValue, ...data.response.data]);
      }
      SetNextPage(data.response.next_page);
    }
  }

  return (
    <div className="pb-3">
      <Toaster />
      <div className="bg-white pt-3">
        <div className="container">
          <div className="d-flex align-items-center mb-3">
            <div className="profile-div">
              <img
                className="img-fluid"
                src={require("../../assets/img/profile.jpg")}
              />
            </div>
            <div className="ms-2 d-flex align-items-center">
              <h6 className="fw-500 mb-0">{companyData?.name}</h6>
              {companyData?.checked && (
                <i className="ms-1 fas fa-circle text-success fs-10"></i>
              )}
              {companyData?.has_active_order && (
                <i className="ms-2 fas fa-biking text-success"></i>
              )}
              {companyData?.reservation_blocked && (
                <i className="ms-2 fas fa-ban text-danger fs-12"></i>
              )}
            </div>
            <div className="ms-auto">
              <div className="py-1 px-2">
                {companyData?.name && (
                  <Menu
                    company_data={companyData}
                    clicked={(data: any) => {
                      handleMenuClick(data);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h6>Your Orders</h6>
          </div>
          <div>
            <div className="tabs-menu">
              <Link
                className={
                  props.type == "active"
                    ? "tab-menu-item active"
                    : "tab-menu-item"
                }
                to={"/orders/active/" + params.id}
              >
                Active
              </Link>
              <Link
                className={
                  props.type == "history"
                    ? "tab-menu-item active"
                    : "tab-menu-item"
                }
                to={"/orders/history/" + params.id}
              >
                History
              </Link>
              <Link
                className={
                  props.type == "cancelled"
                    ? "tab-menu-item active"
                    : "tab-menu-item"
                }
                to={"/orders/cancelled/" + params.id}
              >
                Cancelled
              </Link>
              <Link
                className={
                  props.type == "expired"
                    ? "tab-menu-item active"
                    : "tab-menu-item"
                }
                to={"/orders/expired/" + params.id}
              >
                Expired
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-3">
        {orderData.length != 0 ? (
          orderData?.map((data: any, index: any) => (
            <OrderCard
              key={index}
              order_data={data}
              hide={props.type != "active" && { distance_duration: true }}
            />
          ))
        ) : (
          <>
            {props.type == "active" &&
            companyData?.brand_id &&
            !companyData?.has_active_order &&
            companyData?.checked ? (
              <>
                {companyData?.reservation_blocked ? (
                  <div className="box-1 p-5 pb-3 text-center">
                    <i className="fas fa-ban text-danger fs-30"></i>
                    <div className="mt-4 fw-500">Reservation Blocked</div>
                  </div>
                ) : (
                  <div className="box-1 p-5 text-center pt-70">
                    <div className="circle pulse bg-primary mx-auto"></div>
                    <div className="mt-4 fw-500">Searching Order</div>
                  </div>
                )}
              </>
            ) : (
              <>
                {companyData?.brand_id && noOrderData == true && (
                  <div className="box-1 p-5 pb-3 text-center">
                    <img src={require("../../assets/icon/delay.png")} />
                    <div className="mt-4 fw-500">No Orders</div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        <div className="my-3 mb-6">
          {next_page ? (
            <p
              className="text-prime fs-7 fw-bold text-center cursor mb-5"
              onClick={() => {
                getOrders("next");
              }}
            >
              Load More
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
}
