import React, { useEffect, useState } from "react";
import { CupertinoPane } from "cupertino-pane";
import { AppHelper } from "../../utils/Helper";

let paneNum = 0,
  html: any = document.querySelector("html"),
  body: any = document.querySelector("body");

let radClass = AppHelper.randomStr(10);

// type Props = { parentClass: string; refreshToken: any, children: any, propsData:any };
// { parentClass, refreshToken, children, propsData={} }
const SwipeableDrawer = (props: any) => {
  const [className] = useState(paneNum);
  const [drawer, setDrawer]: any = useState<CupertinoPane>();
  if (className === paneNum) paneNum++;

  let drawer_static: any;

  let settings: any = {
    parentElement: props.parentClass, // Parent container
    events: {},
    cssClass: "swipeable-drawer swipeable-drawer" + radClass,
    showDraggable: false,
    buttonDestroy: false,
  };
  if (props?.propsData?.settings) {
    if (Object.keys(props?.propsData?.settings).length > 0) {
      Object.entries(props?.propsData?.settings).forEach(
        ([key, value], index) => {
          if (key == "cssClass") {
            settings[key] = settings[key] + " " + value;
          } else {
            settings[key] = value;
          }
        }
      );
    }
  }

  settings.events = {
    onDrag: () => {
      if (drawer_static) {
        if (props?.positionCallback) {
          props?.positionCallback(drawer_static.currentBreak());
        }
      }
    },
    onDidPresent: () => {
      html.style.overflowY = "hidden";
      // html.style.overscrollBehavior = "none";
      body.style.overflowY = "hidden";
      // body.style.overscrollBehavior = "none";
      let paneDiv: any = document.querySelectorAll(
        "div.swipeable-drawer" + radClass + " .pane"
      );
      paneDiv.forEach((e: any) => {
        e.style.boxShadow = "";
        e.style.zIndex = new Date().valueOf();
      });
      if (props?.drawerEvent) {
        props?.drawerEvent("opened");
      }
    },
    onDidDismiss: () => {
      if (document.querySelectorAll('.cupertino-pane-wrapper.rendered').length == 0) {
        html.style.overflowY = "auto";
        // html.style.overscrollBehavior = "auto";
        body.style.overflowY = "auto";
        // body.style.overscrollBehavior = "auto";
      }
      let paneDiv: any = document.querySelectorAll(
        "div.swipeable-drawer" + radClass + " .pane"
      );
      paneDiv.forEach((e: any) => {
        e.style.boxShadow = "none";
      });
      if (props?.drawerEvent) {
        props?.drawerEvent("closed");
      }
    },
    onBackdropTap: () => {
      if (drawer_static) {
        if (!props?.preventBackDropClose) {
          setTimeout(() => {
            drawer_static.destroy({ animate: true });
          });
        }
      }
    },
  };

  useEffect(() => {
    // console.log(props?.parentClass, props?.propsData?.open);
    if (!drawer) {
      setDrawer(() => {
        drawer_static = new CupertinoPane(
          ".cupertinoPane" + className + props.refreshToken, // Pane container selector
          settings
        );
        return drawer_static;
      });
    } else {
      if (props?.propsData?.open) {
        setTimeout(() => {
          drawer.present({ animate: true });
          if (props?.openedCallback) {
            props?.openedCallback(drawer);
          }
        });
      }
    }
  }, [drawer, className]);

  useEffect(() => {
    if (drawer) {
      if (props?.propsData?.open) {
        if (!drawer.isPanePresented()) {
          drawer.present({ animate: true });
          if (props?.openedCallback) {
            props?.openedCallback(drawer);
          }
        }
      }
      // else if (drawer.isHidden()) drawer.moveToBreak("bottom");
      if (props?.propsData?.open == false) {
        if (drawer.isPanePresented()) {
          setTimeout(() => drawer.destroy({ animate: true }));
        }
      }
    }
  }, [props.refreshToken, drawer]);
  useEffect(() => {
    if(props.moveToBreak && drawer){
      drawer.moveToBreak(props.moveToBreak);
    }
  }, [props.moveToBreak]);

  // useEffect(() => {
  //   return () => {
  //     html.style.overflowY = "auto";
  //     html.style.overscrollBehavior = "auto";
  //     body.style.overflowY = "auto";
  //     body.style.overscrollBehavior = "auto";
  //   };
  // }, []);

  return (
    <div className={"cupertinoPane" + className + props.refreshToken}>
      {props.children}
    </div>
  );
};

export default SwipeableDrawer;
