import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import './SwipeableButton.css'


const isTouchDevice = 'ontouchstart' in document.documentElement
interface Props {}

// Define the ref type
export type SwipeableButtonRef = {
	refresh: () => void;
};

const SwipeableButton = forwardRef(<SwipeableButtonRef, Props>(props: any, ref: any) => {
    const [unmounted, setUnmounted] = useState(false);
    const [unlocked, setUnlocked]: any = useState(false);
    const [startX, setStartX]: any = useState(0);
    const [isDragging, setIsDragging]: any = useState(true);
    const [buttonText, setButtonText]: any = useState('');
    const [buttonTextSlid, setButtonTextSlid]: any = useState('');
    const [btnLoader, setBtnLoaderd]: any = useState(false);
    const slider: any = useRef();
    const container: any = useRef();
    const sliderLeft: any = useRef(0);

    useEffect(() => {
        if (isTouchDevice) {
            slider.current.addEventListener('touchmove', onDrag);
            slider.current.addEventListener('touchend', stopDrag);
        } else {
            slider.current.addEventListener('mousemove', onDrag);
            slider.current.addEventListener('mouseup', stopDrag);
        }
        return () => {
            setUnmounted(true);
        };
    }, []);
    useEffect(() => {
        if (unlocked == false) {
            console.log('unlock false');

            // setSliderLeft(0);
            sliderLeft.current = 0;
            updateSliderStyle();
        }
    }, [unlocked]);
    useEffect(() => {
        slider.current.style.left = (sliderLeft.current + 50) + 'px';
        console.log('sliding', sliderLeft.current);

    }, [sliderLeft]);
    useEffect(() => {
        setButtonText(props?.text);
        setButtonTextSlid(props?.text_slid);
        if(props?.showLoader){
            setBtnLoaderd(true);
        } else {
            setBtnLoaderd(false);
        }
    }, [props]);

    useImperativeHandle(ref, () => ({
		refresh: () => {
			reset();
		}
	}));

    const onDrag = (e: any) => {
        if (unmounted || unlocked) return;
        if (isDragging) {

            if (isTouchDevice) {
                // setSliderLeft(Math.min(Math.max(0, e.touches[0].clientX - startX), container.current.clientWidth))
                sliderLeft.current = Math.min(Math.max(0, e.touches[0].clientX - startX), container.current.clientWidth - 50)
                console.log(container.current.clientWidth);

            } else {
                // setSliderLeft(Math.min(Math.max(0, e.clientX - startX), container.current.clientWidth));
                sliderLeft.current = Math.min(Math.max(0, e.clientX - startX), container.current.clientWidth - 50)
            }
            updateSliderStyle();
        }
    }

    const updateSliderStyle = () => {
        if (unmounted || unlocked) return;
        // console.log('slider update', sliderLeft);

        slider.current.style.left = (sliderLeft.current + 50) + 'px';
    }

    const stopDrag = () => {
        if (unmounted || unlocked) return;
        if (isDragging) {
            setIsDragging(false);
            console.log('drag stop ' + sliderLeft.current + ' ' + container.current.clientWidth);
            if (sliderLeft.current > (container.current.clientWidth - 50) * 0.9) {
                console.log('onsuccess trigger');

                // setSliderLeft(container.current.clientWidth);
                sliderLeft.current = container.current.clientWidth - 50
                if (props.onSuccess) {
                    props.onSuccess();
                    onSuccess();
                }
            } else {
                console.log('onfail');

                // setSliderLeft(0);
                sliderLeft.current = 0;
                if (props.onFailure) {
                    props.onFailure();
                }
            }
            updateSliderStyle();
        }
    }

    const startDrag = (e: any) => {
        if (unmounted || unlocked) return;
        setIsDragging(true);
        console.log('drag start');

        if (isTouchDevice) {
            setStartX(e.touches[0].clientX);
        } else {
            setStartX(e.clientX)
        }
    }

    const onSuccess = () => {
        container.current.style.width = (container.current.clientWidth - 50) + 'px';
        setUnlocked(true);
        console.log('locked');

    }

    const getText = () => {
        return unlocked ? (props?.text_slid || '') : (props?.text || '')
    }

    const reset = () => {
        // if (unmounted) return;
        if(unlocked){
            container.current.style.width = '100%';
            slider.current.style.left = 50 + 'px';
        }
        setUnlocked(false);
    }

    return (
        <div className='ReactSwipeButton'>
            <div className={'rsbContainer ' + (unlocked ? 'rsbContainerUnlocked' : '')} ref={container}>
                <div className='rsbcSlider'
                    ref={slider}
                    onMouseDown={startDrag}
                    style={{ background: props.color }}
                    onTouchStart={startDrag}>
                    <span className='rsbcSliderText'>
                        {btnLoader ? (
                            <span className="btn-loader"></span>
                        ) : buttonTextSlid
                        }
                    </span>
                    <span className='rsbcSliderArrow'></span>
                    <span className='rsbcSliderCircle' style={{ background: props.color }}></span>
                </div>
                <div className='rsbcText'>{buttonText}</div>
            </div>
        </div>
    )
});

export default SwipeableButton;