import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from "react";

import { useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import { mobileApp } from "../../utils/MobileApp";
import DataContext from "../../utils/Context/DataContext";

let app_platform = "web";

((g: any) => {
  var h: any,
    a: any,
    k: any,
    p = "The Google Maps JavaScript API",
    c: any = "google",
    l = "importLibrary",
    q = "__ib__",
    m: any = document,
    b: any = window;
  b = b[c] || (b[c] = {});
  var d = b.maps || (b.maps = {}),
    r: any = new Set(),
    e = new URLSearchParams(),
    u = () =>
      h ||
      (h = new Promise(async (f: any, n: any) => {
        await (a = m.createElement("script"));
        e.set("libraries", [...r] + "");
        for (k in g)
          e.set(
            k.replace(/[A-Z]/g, (t: any) => "_" + t[0].toLowerCase()),
            g[k]
          );
        e.set("callback", c + ".maps." + q);
        a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
        d[q] = f;
        a.onerror = () => (h = n(Error(p + " could not load.")));
        a.nonce = m.querySelector("script[nonce]")?.nonce || "";
        m.head.append(a);
      }));
  d[l]
    ? console.warn(p + " only loads once. Ignoring:", g)
    : (d[l] = (f: any, ...n: any) => r.add(f) && u().then(() => d[l](f, ...n)));
})({ key: "AIzaSyCpb5cwbblyXgVG-ZsUdA4WdUSFWhBxgWE", v: "weekly" });

const myLatlng = { lat: 11.3836032, lng: 77.7650176 };
var directionsService: any;
let delivery_partner_marker_img_url = "https://cdn3.1superapp.com/superapp/deliveryboy.png";
let delivery_partner_marker_img_url_pin = "https://cdn3.1superapp.com/superapp/markerpin.png";

let map: any;
let mapStyleDark: any = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];
let mapStyle: any = [
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#d3d3d3",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        color: "#808080",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#b3b3b3",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        weight: 1.8,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#d7d7d7",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ebebeb",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#a7a7a7",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#efefef",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#696969",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#737373",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#d6d6d6",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {},
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
];

var delivery_person: any;
var user_person: any;
var res_loc: any;
let directionsRenderer: any;
var bounds: any;
// function initMap(): void {
async function initMap(): Promise<void> {
  const { Map }: any = await google.maps.importLibrary("maps");
  map = new Map(document.getElementById("order-map") as HTMLElement, {
    zoom: 12,
    center: myLatlng,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: false,
    mapTypeId: "roadmap",
    styles: mapStyle
  });
  directionsRenderer = new google.maps.DirectionsRenderer({
    map,
    suppressMarkers: true,
  });
  directionsService = new google.maps.DirectionsService();
  // map.setOptions({ styles: mapStyle });
  bounds = new google.maps.LatLngBounds();

  delivery_person = new google.maps.Marker({
    // position: myLatlng,

    icon: {
      url: delivery_partner_marker_img_url,
      scaledSize: new google.maps.Size(23, 35),
    },
    map,
    anchorPoint: new google.maps.Point(0, -17),
  });

  user_person = new google.maps.Marker({
    // position: myLatlng,

    icon: {
      url: "https://cdn3.1superapp.com/superapp/markerpindown.png",
      scaledSize: new google.maps.Size(23, 35),
    },
    map,
    anchorPoint: new google.maps.Point(0, -17),
  });

  res_loc = new google.maps.Marker({
    // position: myLatlng,

    icon: {
      url: "https://cdn3.1superapp.com/superapp/markerpinup.png",
      scaledSize: new google.maps.Size(23, 35),
    },
    map,
    anchorPoint: new google.maps.Point(0, -17),
  });

  directionsRenderer.setMap(map);

}

const outlet_initial_value = {
  channel_id: "",
};

let res_id: any;
let driver_id: any;

export default function Map(props: any) {
  const context: any = useContext(DataContext);
  let dp_marker:any;
  let status_id: any;
  let firstOpened = false;
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  let myModal: any;

  const [id_value, SerIdValue] = useState(useParams());
  const [outlet_data, SetOutletData] = useState(outlet_initial_value);
  const [open, setOpen] = useState(false);
  const [drive_org, setDriveOrg] = useState(false);

  const [type, setType] = useState("roadmap");
  const [del_lat, setDelLat] = useState("");
  const [del_lng, setDelLng] = useState("");
  const [page, setPage] = useState("");
  const [oldlatlng, SetOldLatLng] = useState([]);

  const [current_location, setCurrentLocation] = useState([]);
  const [userCurrentLocation, setUserCurrentLocation]: any = useState({
    type: null,
    lat: null,
    lng: null,
    heading: null,
  });
  const [showMakerDirections, setShowMakerDirections] = useState(false);
  const [directionType, setDirectionType] = useState("");
  const [curr_loc, SetCurrLoc]: any = useState({
    lat: null,
    lng: null,
  });
  const [foregroundLocationSync, setForegroundLocationSync] = useState(false);

  let statusId: any = useRef(null);

  let prevStatus: any = "",
    currentStatus: any = "";

  let loadedAllBound = false;

  function getLocation() {
    firstOpened = false;
    console.log("getLocation Out");
    if(foregroundLocationSync === false){
      if (context.app_data.application.platform == "web") {
        if (navigator.geolocation) {
          console.log("getLocation Out");
          navigator.geolocation.getCurrentPosition(showPosition);
        }
      } else if (
        context.app_data.application.platform == "android" ||
        context.app_data.application.platform == "ios"
      ) {
        mobileApp({
          function: "syncforegroundlocation",
          app_type: "react_web",
        });
        // mobileApp({
        //   function: "getlocationlatlng",
        //   app_type: "react_web",
        // });
      }
      setForegroundLocationSync(true);
    }
  }
  function showPosition(position: any) {
    if (
      props.status_id == 1 ||
      props.status_id == 0 ||
      (props.status_id >= 3 && props.status_id <= 9)
    ) {
      console.log("showPosition");
      SetCurrLoc({
        lat: parseFloat(position.coords.latitude),
        lng: parseFloat(position.coords.longitude),
      });
      
      setUserCurrentLocation({
        type: null,
        lat: parseFloat(position.coords.latitude),
        lng: parseFloat(position.coords.longitude),
        heading: parseInt(position.coords.heading),
      });
    }
  }

  function setUserCurrentMarker(latitude: any, longitude: any) {
    delivery_person?.setPosition({
      lat: latitude,
      lng: longitude,
    });
    if(!firstOpened){
      bounds?.extend(delivery_person.position);
      setShowMakerDirections(true);
    }
    firstOpened = true;
    // console.log("showPosition delivery_person", latitude);
  }

  function putCameraFromMobile(message: any) {
    if (message.data.function == "checkdeliverypartnerbglocation") {
      if (message.data.status) {
        
      } else {
        if (app_platform == "android" || app_platform == "ios") {
          mobileApp({
            function: "startdeliverypartnerbglocation",
            app_type: "react_web",
          });
        }
      }
    } else if (message.data.function == "getlocationlatlng") {
      setUserCurrentLocation({ type: 'getlocationlatlng', lat: message.data.lat, lng: message.data.lng, heading: (message?.full_data?.coords.heading)?parseInt(message.full_data.coords.heading):0, });
    } else if (message.data.function == "syncforegroundlocation") {
      setUserCurrentLocation({ type: 'syncforegroundlocation', lat: message.data.location.coords.latitude, lng: message.data.location.coords.longitude, heading: (message?.data?.location?.coords.heading)?parseInt(message.data.location.coords.heading):0, });
    }
  }

  useEffect(() => {
    app_platform = context.app_data.application.platform;
    document.addEventListener("message", putCameraFromMobile);
    
    initMap();

    return () => {
      mobileApp({
        function: "stopsyncforegroundlocation",
        app_type: "react_web",
      });
    //   setDirectionType("");
    //   setShowMakerDirections(false);
    //   setUserCurrentLocation({ lat: null, lng: null });
    }
  }, []);

  useEffect(() => {
    if (userCurrentLocation?.lat != null && userCurrentLocation?.lng != null && delivery_person) {
      setUserCurrentMarker(
        parseFloat(userCurrentLocation.lat),
        parseFloat(userCurrentLocation.lng)
      );
      
      if(userCurrentLocation.heading != null){
        // delivery_person?.setIcon({path: delivery_partner_marker_img_url});
        let deg = parseInt(userCurrentLocation.heading); // based on api heading value
        dp_marker = document.querySelector('img[src="'+delivery_partner_marker_img_url+'"]');
          if(dp_marker){
            // alert(userCurrentLocation.heading)
            dp_marker.style.transform = 'rotate(' + deg + 'deg)';
            dp_marker.parentElement.style.overflow = 'inherit';
            
            // dp_marker.parentElement.style.overflow = 'rotate(' + deg + 'deg)';
          }
      }
      //  else {
      //   delivery_person?.setIcon({path: delivery_partner_marker_img_url_pin});
      // }
    }
  }, [userCurrentLocation.lat, userCurrentLocation.lng, userCurrentLocation.heading, delivery_person]);

  useEffect(() => {
    if (showMakerDirections) {
      showDirections();
      console.log('showDirections');
      
    }
  }, [showMakerDirections, delivery_person, res_loc, user_person]);

  useEffect(() => {
    if (props) {
      status_id = props.status_id;

      if (props.loc[0].hasOwnProperty("lat") && !user_person?.getPosition()) {
        if(props.loc[0].lat != null && props.loc[0].lat){
          user_person?.setPosition({
            lat: parseFloat(props.loc[0].lat),
            lng: parseFloat(props.loc[0].lng),
          });
          bounds?.extend(user_person.position);
        }
      }
      if (props.loc[1].hasOwnProperty("lat") && !res_loc?.getPosition()) {
        if(props.loc[1].lat != null && props.loc[1].lat){
          res_loc?.setPosition({
            lat: parseFloat(props.loc[1].lat),
            lng: parseFloat(props.loc[1].lng),
          });
          bounds?.extend(res_loc.position);
          loadedAllBound = true;
          
          console.log(1);
        }
      }
      if (props.status_id > 5) {
        setUserCurrentLocation({ type: null, lat: null, lng: null, heading: null });
        setShowMakerDirections(false);
        setDirectionType("delivery");
        console.log(2);
      } else if (props.status_id > 0 && props.status_id <= 5) {
        setUserCurrentLocation({ type: null, lat: null, lng: null, heading: null });
        setShowMakerDirections(false);
        setDirectionType("pickup");
        console.log(3);
      } else {
        setUserCurrentLocation({ type: null, lat: null, lng: null, heading: null });
        setShowMakerDirections(false);
        setDirectionType("all");
        console.log(4);
      }
      
      if (
        props.status_id == 1 ||
        props.status_id == 0 ||
        (props.status_id >= 3 && props.status_id <= 9)
      ) {
        getLocation()
        // showDirections();
      }
    }
  }, [props]);

  useEffect(() => {
    console.log("directionType", res_loc?.getPosition()?.lat());
    if (directionType && directionType != "" && user_person?.getPosition() && res_loc?.getPosition()) {
      console.log("directionType", directionType);
      if (directionType == "pickup") {
        // getLocation();
        console.log("directionType pickup");
      } else if (directionType == "delivery") {
        // getLocation();
        console.log("directionType delivery");
      } else if (directionType == "all") {
        // getLocation();
      }
      console.log("directionType delivery_person", delivery_person);
    }
  }, [directionType, user_person, res_loc]);

  // useEffect(() => {
  //   if (prevStatus != currentStatus) {
  //     getLocation();
  //   }
  // }, [currentStatus]);

  function showDirections() {
    console.log("currentStatus", currentStatus);

    console.log("Show directions");
    console.log("delivery_person", delivery_person);
    console.log("currentStatus", user_person?.getPosition());
    // if (prevStatus != currentStatus) {

    // prevStatus = currentStatus;
    // setPrevStatus(currentStatus);
    if(delivery_person?.getPosition() && res_loc?.getPosition() && user_person?.getPosition()){
      directionsService?.route(
        {
          origin: delivery_person.getPosition(),
          destination:
            directionType == "pickup"
              ? res_loc.getPosition()
              : user_person.getPosition(),
          waypoints:
            directionType == "all"
              ? [{ location: res_loc.getPosition(), stopover: false }]
              : [],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        // {
        //   origin: delivery_person.getPosition(),
        //   destination: user_person.getPosition(),
        //   waypoints: [{ location: res_loc.getPosition(), stopover: false }],
        //   travelMode: google.maps.TravelMode.DRIVING,
        // },
        (result: any, status: any) => {
          if (status === google.maps.DirectionsStatus.OK) {
            // Display the route on the map using the google.maps.DirectionsRenderer class
            // directionsRenderer = new google.maps.DirectionsRenderer({
            //     map,
            //     suppressMarkers: true, // hide default markers
            // });
            directionsRenderer.setDirections(result);
          } else {
            console.log("Directions request failed due to " + status);
          }
        }
      );
    }
    // }
  }

  return (
    <div id="order-map" style={{ height: window.screen.height - 280 }}></div>
  );
}
