import { Link } from "react-router-dom";

export default function Signup() {
  return (
    <div className="d-flex flex-column bg-white p-3 text-center" style={{height: window.innerHeight}}>
      <div className="mt-auto">
        <img
          src="https://1superapp.com/assets/images/1superapp-logo-plain.png"
          className="img-fluid mb-2"
          width="60"
        ></img>
        <h4>Delivery Partner</h4>
      </div>

      <div className="fs-12 mt-auto mb-2">
        Tap "Get Started" to accept our{" "}
        <b>Terms, Data, Privacy and Cookies Policy</b>
      </div>

      <Link to={"/signup/request"} className="btn btn-primary w-100">
        Get Started
      </Link>
    </div>
  );
}
