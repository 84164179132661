import { useState, useEffect } from "react";

function PackageImage(props: any) {
  const [data, SetData]: any = useState({});
  const [imageUrl, SetImageUrl]: any = useState("");
  useEffect(() => {
    let image_url = "";
    if (props) {
      SetData(props.data);
      if (props?.outer_data?.image_base_url) {
        image_url += props.outer_data.image_base_url;
      }
      if (props?.data?.package_images.length > 0) {
        image_url += props.data.package_images[0];
        SetImageUrl(image_url);
      }
    }
  }, [props]);
  return (
    <div className="box-1 p-3 mb-3">
      <div className="fw-500 text-dark mb-2">Pickup</div>
      {data.package_images?.length > 0 ? (
        <>
          <div className="text-center">
            <img
              src={imageUrl}
              className="img-fluid"
              style={{ width: "50%" }}
            />
            <div>Package Image</div>
          </div>
        </>
      ) : (
        <div>Package image not uploaded</div>
      )}
    </div>
  );
}
export default PackageImage;
