import React, { useState, useEffect, useContext } from "react";

import { LazyLoader, ImageLazyLoader } from "../../utils/LazyLoader/index";

export default function Statistics(props: any) {
    const [statistics, SetStatistics]: any = useState({});

    useEffect(() => {
        if (props) {
            SetStatistics(props.statistics);
        }
    }, [props]);

    return (
        <div className="row">
            <div className="col-6 pe-2 mb-3">
                <div className="box-3 p-3 d-flex flex-column h-100">
                    <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/330ac397130f29b354ca21fa5d8fdead.png" />
                    <div className="fw-500 fs-16 mb-1 mt-auto">
                        <LazyLoader
                            show={statistics?.hasOwnProperty('today_earnings') ? false : true}
                            style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
                        />
                        {statistics?.hasOwnProperty('today_earnings') && (
                            <>&#8377;{statistics?.today_earnings}</>
                        )}
                    </div>
                    <div className="fw-500 text-grey-1">Today's earnings</div>
                </div>
            </div>
            <div className="col-6 ps-2 mb-3">
                <div className="box-3 p-3 d-flex flex-column h-100">
                    <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/5bb7bb7455b643dbf0cd78c9bed400cb.png" />
                    <div className="fw-500 fs-16 mb-1 mt-auto">
                        <LazyLoader
                            show={statistics?.hasOwnProperty('week_earnings') ? false : true}
                            style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
                        />
                        {statistics?.hasOwnProperty('week_earnings') && (
                            <>&#8377;{statistics?.week_earnings}</>
                        )}
                    </div>
                    <div className="fw-500 text-grey-1">Week's earnings</div>
                </div>
            </div>
            <div className="col-6 pe-2 mb-3">
                <div className="box-3 p-3 d-flex flex-column h-100">
                    <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/442e57815a0bc2b523ca81e6d8275577.png" />
                    <div className="fw-500 fs-16 mb-1 mt-auto">
                        <LazyLoader
                            show={statistics?.hasOwnProperty('login_duration') ? false : true}
                            style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
                        />
                        {statistics?.hasOwnProperty('login_duration') && (
                            <>{statistics?.login_duration}</>
                        )}
                    </div>
                    <div className="fw-500 text-grey-1">Login Duration</div>
                </div>
            </div>
        
        </div>
    );
};
