import React, { useState, useEffect, useContext } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";
import * as bootstrap from "bootstrap";

import DataContext from "../../utils/Context/DataContext";
import { AppHelper } from "../../utils/Helper";
import { api } from "../../utils/Api";
import SendbirdGroupChannelChat from "../../component/Sendbird/SendbirdGroupChannelChat";

let myModal: any;
export default function OrderChat(props: any) {
    const [order, setOrder]:any = useState({});
    const [close_data, SetCloseData] = useState({
        action: "close",
    });

    const navigate = useNavigate();
    const context: any = useContext(DataContext);
    const [id_value, SerIdValue] = useState(useParams());

    useEffect(() => {
        let myModal1: any = document.getElementById("Chat");
        myModal1.addEventListener("hidden.bs.modal", function (event: any) {
            props.close(close_data);
        });
        return () => {
            myModal?.hide();
        }
    }, []);

    useEffect(() => {
        if (props.open) {
            setOrder(props.data);
            let Chat: any = document.getElementById("Chat");
            myModal = new bootstrap.Modal(Chat, {});
            myModal.show();
        }
    }, [props.open]);

    return (
        <div
            className="modal fade"
            id="Chat"
            tabIndex={-1}
            aria-labelledby="ModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                <div className="modal-content border-0 br-0">
                    <div className="modal-body p-0 sendbird-hide-header">
                        <div className="chat-header">
                            <button type="button" className="btn text-dark px-3" 
                                onClick={() => {
                                    myModal.hide();
                                }}
                            >
                                <i className="fas fa-arrow-left"></i>
                            </button>
                            {order?.profile_url && (
                                <img className="img-fluid profile-image" src={order?.profile_url} />
                            )}
                            <h6 className="mb-0">{(order?.title)?order?.title:'Chat'}</h6>
                        </div>
                        {order?.sb_user_id && (
                            <SendbirdGroupChannelChat user_id={order.sb_user_id} channel_url={order.channel_url} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}