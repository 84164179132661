import { useState, useEffect } from "react";

function CollectCashDelivery(props: any) {
  const [data, SetData]: any = useState({});
  useEffect(() => {
    if (props) {
      SetData(props.data);
    }
  }, [props]);
  return (
    <>
      {data?.cod_amount > 0 && (
        <div className="box-1 p-3 mb-3">
          <div className="fw-500">Collect cash from delivery person</div>
          <div className="text-dark fs-20 fw-500">&#8377; {data.cod_amount}</div>
        </div>
      )}
    </>
  );
}
export default CollectCashDelivery;
