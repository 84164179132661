import { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { redirect, useNavigate } from "react-router-dom";

import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import { mobileApp } from "../../utils/MobileApp";
import AllowBgLocation from "./AllowBgLocation";
import Menu from "./Menu";
import OrderCard from "../CompanyOrders/OrderCard";
import Statistics from "./Statistics";
import { LazyLoader, ImageLazyLoader } from "../../utils/LazyLoader/index";

let timer: any,
  app_platform = "web";
export default function Company() {
  const navigate = useNavigate();
  const context: any = useContext(DataContext);
  app_platform = context.app_data.application.platform;
  const [params, setParams]: any = useState(useParams());
  const [company_data, setCompanyData]: any = useState({});
  const [bgLocationModal, setBgLocationModal]: any = useState({
    is_open: false,
    id: "",
  });
  const [startWorkPopup, setStartWorkPopup]: any = useState(false);
  const [startWorkApp, setStartWorkApp]: any = useState(false);
  const [startBtnLoader, setStartBtnLoader]: any = useState(false);
  const [startWorkAppLoc, setStartWorkAppLoc]: any = useState({lat: null, lng: null});
  const [orders_data, setOrdersData]: any = useState([]);
  const [companyLogo, setCompanyLogo]: any = useState();
  let startBtnLoaderTimer:any;

  function appMessageListner(message: any) {
    if (message.data.function == "startdeliverypartnerbglocation") {
      if (message.data.status) {
        if (!context.app_data.settings.background_location_allowed) {
          grantedBgLocation();
          setStartWorkApp(false);
        } else {
          // setStartWorkApp(true);
        }
        if(message?.data?.location?.status == 'success' && message?.data?.location?.full_data?.coords){
          setStartWorkAppLoc({
            lat: message?.data?.location?.full_data?.coords?.latitude,
            lng: message?.data?.location?.full_data?.coords?.longitude
          });
        }
        // update_company_status(true);
      }
    } else if (message.data.function == "getlocationlatlng") {
      if (message?.data?.status) {
        if (message?.data?.lat && message?.data?.lng) {
          setStartWorkAppLoc({
            lat: message?.data?.lat,
            lng: message?.data?.lng
          });
          
          // update_company_status(true, message?.data?.lat, message?.data?.lng);
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener("message", appMessageListner);
    init();
    return () => {
      if (timer) {
        clearInterval(timer);
      }
      if(startBtnLoaderTimer){
        clearTimeout(startBtnLoaderTimer);
      }
    };
  }, []);

  // useEffect(() => {
  //   if (startWorkApp && startWorkAppLoc?.lat == null) {
  //     mobileApp({
  //       function: "getlocationlatlng",
  //       app_type: "react_web",
  //       accuracy: "Lowest"
  //     });
  //     setStartBtnLoader(true);
  //   }
  // }, [startWorkApp, startWorkAppLoc]);
  useEffect(() => {
    if (startWorkApp == true && startWorkAppLoc && startWorkAppLoc?.lat != null) {
      update_company_status(true, startWorkAppLoc?.lat, startWorkAppLoc?.lng);
    }
  }, [startWorkApp, startWorkAppLoc]);
  useEffect(() => {
    if (startBtnLoader) {
      if(startBtnLoaderTimer){
        clearTimeout(startBtnLoaderTimer);
      }
      startBtnLoaderTimer = setTimeout(()=>{
        setStartBtnLoader(false);
      }, 15000)
    }
  }, [startBtnLoader]);
  useEffect(() => {
    if (
      startWorkPopup &&
      context.app_data.settings.background_location_allowed
    ) {
      startStopWorking("start");
    }
  }, [startWorkPopup, context.app_data.settings.background_location_allowed]);

  useEffect(() => {
    if (
      company_data.brand_id &&
      company_data.checked &&
      !company_data?.has_active_order &&
      !company_data?.reservation_blocked
    ) {
      unaccepted_orders();
      if (!timer) {
        timer = setInterval(() => unaccepted_orders(), 10000);
      }
    } else {
      if (timer) {
        clearInterval(timer);
      }
    }
    if (company_data.brand_id && company_data?.has_active_order) {
      order();
    }
  }, [company_data, company_data?.checked, company_data?.reservation_blocked]);
  useEffect(() => {
    if (context.app_data.image_base_url && company_data.logo) {
      setCompanyLogo(context.app_data.image_base_url + company_data.logo);
    }
  }, [company_data.logo, context.app_data.image_base_url]);

  async function init() {
    let data: any = await api("/company/view", {
      get: { brand_id: params.id },
    });
    if (data.status_code == 200) {
      setCompanyData(data.response.company);
      if (data.response?.company?.checked) {
        setStartWorkApp(true);
        // startBgLocation();
      } else {
        setStartWorkApp(false);
      }
      if(startBtnLoaderTimer){
        clearTimeout(startBtnLoaderTimer);
      }
      setStartBtnLoader(false);
    }
  }
  async function grantedBgLocation() {
    let pass_data = {
      post: {
        background_location_allowed: 1,
      },
    };
    let data: any = await api("/account/save_settings", pass_data);
    if (data.status_code == 200) {
      if (data.response?.settings) {
        context.SetAppData((prevValue: any) => {
          prevValue.settings = data.response.settings;
          return { ...prevValue };
        });
        if (data.response?.settings?.background_location_allowed) {
          setStartWorkApp(true);
        }
      }
    }
  }
  function startBgLocation(){
    setStartWorkAppLoc({lat: null, lng: null});
    var mobile_func = "startdeliverypartnerbglocation";
    mobileApp({
      function: mobile_func,
      app_type: "react_web",
      permission_description:
        "1SuperApp collects location data of the Delivery Partner to find nearby pickup drop orders, food delivery order, and to share your location to ordered customers even when the app is closed or not in use.",
      permission_description2:
        "To get new orders near you, allow to get your location in background",
    });
  }
  async function startStopWorking(status: any) {
    if (company_data?.brand_id) {
      if (app_platform == "android" || app_platform == "ios") {
        if (status == "start") {
          setStartBtnLoader(true);
          setStartWorkApp(true);
          startBgLocation();
        } else {
          setStartWorkApp(false);
          setStartWorkAppLoc({lat: null, lng: null});
          await update_company_status();
          var mobile_func = "stopdeliverypartnerbglocation";
          mobileApp({
            function: mobile_func,
            app_type: "react_web",
          });
        }
      } else if (app_platform == "web") {
        if (status == "start") {
          if (context.app_data.settings.background_location_allowed == false) {
            setBgLocationModal({
              is_open: true,
              // id: id,
            });
            // save_setting(context.app_data.settings.background_location_allowed);
          } else {
            // update_company_status(true);
          }
        } else {
          await update_company_status();
        }
      }
    }
  }
  async function update_company_status(s = false, lat = null, lng = null) {
    let pass_data: any = {},
      call = false;
    if (s && company_data?.brand_id) {
      pass_data = {
        post: {
          brand_id: company_data?.brand_id,
          lat: lat,
          lng: lng
        },
      };
      call = true;
    } else if (s == false) {
      call = true;
    }
    if (call) {
      let data: any = await api("/company/status", pass_data);
      if (data?.status_code == 200) {
        let message = data.response?.message;
        init();
        if (message != undefined) {
          toast.error(message);
        }
      }
    }
  }
  async function order() {
    let pass_data = {
      get: {
        company_id: company_data?.brand_id,
        view: "active",
        page: 1,
      },
    };
    let data: any = await api("/company/orders", pass_data);
    if (data.status_code == 200) {
      setOrdersData(data.response.data);
    }
  }
  async function unaccepted_orders() {
    let pass_data = {
      get: {
        company_id: company_data?.brand_id,
        view: "active",
        page: 1,
      },
    };
    let data: any = await api("/company/orders", pass_data);
    if (data.status_code == 200) {
      if (data?.response?.data.length > 0 && data?.response?.data[0].id) {
        navigate("/order_details/" + data.response.data[0].id + "/ring");
      } else if(data?.response?.cancelled_ring_orders.length > 0 && data?.response?.cancelled_ring_orders[0]) {
        navigate('/order/'+data.response.cancelled_ring_orders[0]);
      }
    }
  }
  function handleMenuClick(data: any) {
    if (data?.error_message) {
      toast.error(data.error_message);
    }
    if (data?.success_message) {
      toast.success(data.success_message);
    }
    if (data?.workingStopped) {
      setStartWorkApp(false);
    }
    if (data?.reloadData) {
      init();
    }
  }

  return (
    <div className="container py-3 mb-5">
      <Toaster />
      <div className="box-1 p-3">
        <div className="d-flex align-items-center">
          {company_data?.name ? (
            <>
              {companyLogo ? (
                <ImageLazyLoader
                  parentClassName="profile-div"
                  className="img-fluid"
                  src={companyLogo}
                />
              ) : (
                <ImageLazyLoader
                  parentClassName="profile-div"
                  className="img-fluid"
                  src={require("../../assets/img/profile.jpg")}
                />
              )}
            </>
          ) : (
            <LazyLoader
              className="profile-div"
            />
          )}
          <div className="ms-2 me-2 d-flex align-items-center">
            <h6 className="fw-500 mb-0">
              <LazyLoader
                show={company_data?.name ? false : true}
                style={{ height: "20px", width: "200px" }}
              />
              {company_data?.name}
            </h6>

            {company_data?.checked && (
              <i className="ms-1 fas fa-circle text-success fs-10"></i>
            )}
            {company_data?.has_active_order && (
              <i className="ms-2 fas fa-biking text-success"></i>
            )}
            {company_data?.reservation_blocked && (
              <i className="ms-2 fas fa-ban text-danger fs-12"></i>
            )}
          </div>
          {company_data?.name && (
            <div className="ms-auto">
              <div className="py-1 px-2">
                <Menu
                  company_data={company_data}
                  clicked={(data: any) => {
                    handleMenuClick(data);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {company_data?.brand_id && !company_data?.checked && (
          <div className="mt-3">
            <button
              className="btn btn-primary w-100 br-20"
              onClick={() => {
                startStopWorking("start");
              }}
            >
              {startBtnLoader ? (
                <span className="btn-loader"></span>
              ):(
                <span>Start Working</span>
              )}
            </button>
          </div>
        )}
      </div>
      
      {company_data?.brand_id &&
        company_data?.has_active_order &&
        orders_data.length != 0 && (
          <div className="mt-3">
            <h6>Current Orders</h6>
            {orders_data?.map((data: any, index: any) => (
              <OrderCard key={index} order_data={data} />
            ))}
          </div>
        )}
      {company_data?.brand_id &&
        !company_data?.has_active_order &&
        company_data?.checked && (
          <>
            {company_data?.reservation_blocked ? (
              <div className="box-1 p-5 pb-3 mt-3 text-center">
                <i className="fas fa-ban text-danger fs-30"></i>
                <div className="mt-4 fw-500">Reservation Blocked</div>
              </div>
            ) : (
              <div className="box-1 p-5 mt-3 text-center pt-70">
                <div className="circle pulse bg-primary mx-auto"></div>
                <div className="mt-4 fw-500">Searching Order</div>
              </div>
            )}
          </>
        )}

      <div className=" mt-3">
        <Statistics statistics={company_data?.statistics} />
      </div>

      <AllowBgLocation
        open={bgLocationModal.is_open}
        close={(data: any) => {
          setBgLocationModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            if (data.settings) {
              context.SetAppData((prevValue: any) => {
                prevValue.settings = data.settings;
                return { ...prevValue };
              });
              setStartWorkPopup(true);
            }
          }
        }}
      />
    </div>
  );
}
