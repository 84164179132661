import React, { useState, useEffect, useContext } from "react";
// import bootstrap from "../../../public/libraries/bootstrap/js/bootstrap";
import * as bootstrap from "bootstrap";

import { useParams } from "react-router-dom";

import DataContext from "../../utils/Context/DataContext";
import { api } from "../../utils/Api";
import { mobileApp } from "../../utils/MobileApp";

let myModal: any;
export default function ModalOrderDelayed(props: any) {
  const context: any = useContext(DataContext);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });

  const [id_value, SerIdValue] = useState(useParams());
  const [reasonList, setReasonList] = useState([]);
  const [order_detail, setOrder]:any = useState({delayed_message: []});
  const [reason, setReason] = useState('');
  const [others_en, setOthersEn] = useState(false);
  const [others, setOthers] = useState('');

  const [statusBtnLoader, setStatusBtnLoader] = useState(false);

  useEffect(() => {
    let myModal1: any = document.getElementById("OrderDelayedModal");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      if(props?.order?.delayed_message[0]?.reasons){
        setReasonList(props?.order?.delayed_message[0]?.reasons)
      }
      if(props?.order){
        setOrder(props?.order)
      }
      let OrderDelayedModal: any = document.getElementById("OrderDelayedModal");
      myModal = new bootstrap.Modal(OrderDelayedModal, {});
      myModal.show();
    }
  }, [props.open]);

  async function submit() {
    if(!statusBtnLoader && reason){
      setStatusBtnLoader(true);
      let pass_data:any = {
        post: {
          id: order_detail.delayed_message[0]?.id,
        },
      };
      if(reason != 'others'){
        pass_data.post.reason = reason
      }
      if(others != ''){
        pass_data.post.others = others
      }
      let data: any = await api("/company/ack_order_delayed", pass_data);
    
      if (data.status_code == 200) {
        let item: any = close_data;
        item.action = "save";
        item.value = data.response.data;
        SetCloseData(item);
        setStatusBtnLoader(false);
        // SetOutletData(outlet_initial_value);
    
        if (context.app_data.application.platform == "android") {
          const ring = {
            function: "refreshringnotifications",
            app_type: "react_web",
          };
          mobileApp(ring);
        }

        myModal.hide();
      } else {
        setStatusBtnLoader(false);
      }
    }
  }

  return (
    <div
      className="modal fade"
      id="OrderDelayedModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 br-20 mx-3">
          <div className="modal-body">
            <h6 className="mb-4 mt-2">It's taking longer than expected!</h6>
            <div className="mb-4">
              <label className="form-label mb-0">Reason For {(order_detail?.delayed_message.length > 0) ? order_detail?.delayed_message[0]?.message : 'delay'}</label>
              
              <select
                className="form-select"
                onChange={(e) => {
                  setReason(e.target.value);
                  if(e.target.value == 'others'){
                    setOthersEn(true)
                  } else {
                    setOthersEn(false)
                  }
                }}
              >
                <option value="">Please Select</option>
                {reasonList?.length > 0 && (
                  reasonList.map((d:any) => (
                    <option value={d.id}>{d.value}</option>
                  ))
                )}
                <option value={'others'}>Others</option>
              </select>
            </div>
            {others_en && (
              <div className="mb-4">
                <textarea className="form-control" rows={4} onChange={(e) => setOthers(e.target.value)} value={others} placeholder="Enter your reason" />
              </div>
            )}
            <div className="">
              <button
                type="button"
                className="btn btn-success fw-500 w-100 br-20"
                onClick={(e) => {
                  submit()
                }}
              >
                {statusBtnLoader ? (
                  <span className="btn-loader"></span>
                ) : (
                  <span>
                    Submit
                  </span>
                )}
              </button>
              <button
                type="button"
                className="btn text-muted btn-sm w-100 mt-3"
                data-bs-dismiss="modal"
                disabled={statusBtnLoader?true:false}
              >
                Skip
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
