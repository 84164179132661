import React, { useState, useEffect, useContext } from "react";

import { AppConfig } from "../../config";
import { LazyLoader, ImageLazyLoader } from "../../utils/LazyLoader/index";

export default function Settlement(props: any) {
    const [order_detail, SetOrderDetail]: any = useState({});

    useEffect(() => {
        if (props) {
            SetOrderDetail(props.settlement);
        }
    }, [props]);

    return (
        <>
            {order_detail?.status_id == 10 && (
                <div className="row">
                    <div className="col-12">
                        <div className="box-1 p-3">
                            <h6 className="mb-3">Settlement</h6>
                            <div className="timeline-1">
                                <div className="d-flex">
                                    <div className={order_detail?.settlement_eligible ? "timeline-dot active" : "timeline-dot"}>
                                        {order_detail?.settlement_eligible && (
                                            <img
                                                src={
                                                    AppConfig.CDN_Media_url +
                                                    "be453854bc89e35e154efa5e26814afa.svg"
                                                }
                                                className="img-fluid"
                                                width={10}
                                            />
                                        )}
                                    </div>
                                    <div className="w-100 ps-2">Settlement Eligible</div>
                                </div>
                                <div className="d-flex">
                                    <div className="timeline-line-container"><div className={order_detail?.settlement_approved ? "timeline-line active" : "timeline-line"}></div></div>
                                    <div className="w-100"></div>
                                </div>
                                <div className="d-flex">
                                    <div className={order_detail?.settlement_approved ? "timeline-dot active" : "timeline-dot"}>
                                        {order_detail?.settlement_approved && (
                                            <img
                                                src={
                                                    AppConfig.CDN_Media_url +
                                                    "be453854bc89e35e154efa5e26814afa.svg"
                                                }
                                                className="img-fluid"
                                                width={10}
                                            />
                                        )}
                                    </div>
                                    <div className="w-100 ps-2">Settlement Approved</div>
                                </div>
                                <div className="d-flex">
                                    <div className="timeline-line-container"><div className={order_detail?.settlement_paid ? "timeline-line active" : "timeline-line"}></div></div>
                                    <div className="w-100"></div>
                                </div>
                                <div className="d-flex">
                                    <div className={order_detail?.settlement_paid ? "timeline-dot active" : "timeline-dot"}>
                                        {order_detail?.settlement_paid && (
                                            <img
                                                src={
                                                    AppConfig.CDN_Media_url +
                                                    "be453854bc89e35e154efa5e26814afa.svg"
                                                }
                                                className="img-fluid"
                                                width={10}
                                            />
                                        )}
                                    </div>
                                    <div className="w-100 ps-2">
                                        Settlement Paid
                                        {order_detail?.settlement_paid && (
                                            <div>
                                                <div className="fs-12">
                                                    {order_detail?.settlement_paid_time}
                                                </div>
                                                <div className="fw-500 fs-15">
                                                    &#8377;{order_detail?.settlement_paid_amount}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
