import React, { useState, useEffect, useContext, useRef } from "react";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import { useNavigate } from "react-router-dom";

export default function SignupFileUpload(props: any) {
  const navigate = useNavigate();
  const context: any = useContext(DataContext);
  useEffect(() => {
    if (context.app_data.user_details) {
    //   setInput((prev: any) => {
    //     prev.name = context.app_data.user_details.name;
    //     if (context.app_data.user_details?.mobile) {
    //       prev.mobile = context.app_data.user_details.mobile;
    //     }
    //     return { ...prev };
    //   });
    }
  }, [context.app_data.user_details]);

  let thumb_url:any = {
    upload_img: require('../../assets/img/upload-img.png'),
    file_img: require('../../assets/img/file.png'),
    uploaded_file_img: require('../../assets/img/docs.png'),
  }
  const [id, setId]: any = useState('');
  const [localProps, setLocalProps]: any = useState({});
  let fileUploadPreviewTemp = {
    file_img: thumb_url.file_img,
    loading_file_img: false,
    name_file_img: props.title,
  };
  const [fileUploadPreview, setFileUploadPreview]: any = useState(fileUploadPreviewTemp);

  const hiddenFileInputProfilePic:any = useRef(null);
  const handleClickFileDoc = () => {
    if(!localProps?.status || localProps?.status == 0 || localProps?.status == 2){
      hiddenFileInputProfilePic.current.click();
    }
  };
  const handleChangeInputFile = async (event:any) => {
    const fileUploaded = event.target.files[0];
    if(fileUploaded){
      let img_type = false;
      if(fileUploaded?.type){
        if(fileUploaded?.type.match('image/')){
          img_type = true;
        }
      }
      
      let image_file_data:any = {
        file_name: fileUploaded.name,
      };
      if(img_type){
        var reader = new FileReader();
        var url = reader.readAsDataURL(fileUploaded);
    
        reader.onloadend = function (e:any) {
          image_file_data.image = [reader.result]
          updateFileUploadPreviewState(image_file_data);
        };
      } else {
        image_file_data.image = thumb_url.uploaded_file_img;
        updateFileUploadPreviewState(image_file_data);
      }

        await uploadFileGallery(fileUploaded);
    } else {
        if(!id){
            updateFileUploadPreviewState();
        }
    }
    event.target.value = null;
  };
  const removeFileDoc = () => {
    if(!localProps?.status || localProps?.status == 0 || localProps?.status == 2){
      setId('');
      setFileUploadPreview(fileUploadPreviewTemp);
    }
  };

  const updateFileUploadPreviewState = (d:any = {}) => {
    if(d){
      setFileUploadPreview((prev: any) => {
        if(d.file_name){
            prev.name_file_img = d.file_name;
            prev.loading_file_img = true;
        }
        if(d.image){
            prev.file_img = d.image;
        }
        return { ...prev };
      });
    } else {
      setFileUploadPreview(fileUploadPreviewTemp);
    }
  }

    const uploadFileGallery = async (fileUploaded:any) => {
        setId('');
        let pass_data: any = {
        post: {
            file: fileUploaded
        },
        };
        let data_res: any = await api("/company/upload_file", pass_data, false, true);
        if (data_res.status_code == 201 && data_res.response?.id) {
            setId(data_res.response?.id);
            setFileUploadPreview((prev: any) => {
                prev.loading_file_img = false;
                return { ...prev };
            });
        } else {
            setId('');
            setFileUploadPreview(fileUploadPreviewTemp);
        }
    }

    useEffect(() => {
        if (id) {
            props.add(id);
        } else {
            props.remove();
        }
      }, [id]);
    useEffect(() => {
      if (localProps.id) {
        setId(localProps.id);
      } else {
        setId('');
      }
    }, [localProps.id]);
    useEffect(() => {
      if (props) {
        
        setLocalProps(props);
      } else {
        setLocalProps({});
      }
    }, [props]);
    useEffect(() => {
      if ((localProps.id == localProps.old_id) && localProps?.name) {
        if(localProps?.name){
          setFileUploadPreview((prev: any) => {
              prev.name_file_img = localProps?.name;
              return { ...prev };
          });
        }
        if(localProps?.url && (localProps?.ext == '.jpg' || localProps?.ext == '.jpeg' || localProps?.ext == '.png' || localProps?.ext == '.gif' || localProps?.ext == '.webp')){
          setFileUploadPreview((prev: any) => {
              prev.file_img = localProps?.url;
              return { ...prev };
          });
        } else {
          setFileUploadPreview((prev: any) => {
              prev.file_img = thumb_url.uploaded_file_img;
              return { ...prev };
          });
        }
      } else {
        
      }
    }, [localProps]);
    
  return (
    <>
        <div className={"d-flex align-items-center bg-light-blue br-8 p-3 cursor"} onClick={() => { if(!id) handleClickFileDoc(); }}>
            <img className="img-fluid" width="30" src={fileUploadPreview.file_img} onClick={() => { if(id) handleClickFileDoc(); }}/>
            <div className="mx-1 fs-12 text-shrink" onClick={() => { if(id) handleClickFileDoc(); }}>{fileUploadPreview.name_file_img}</div>
            {((!localProps?.status || localProps?.status == 0 || localProps?.status == 2) && id && id != '' && id != null && id != 0) && (
            <div className="ms-auto" onClick={() => removeFileDoc()}>&times;</div>
            )}
            {(localProps?.status == 1 && id && id != '' && id != null && id != 0) && (
            <div className="ms-auto"><i className="fas fa-check-circle text-success"></i></div>
            )}
            {fileUploadPreview.loading_file_img && (
            <div className="ms-auto"><span className="btn-loader"></span></div>
            )}
        </div>
        <div className="mb-2 fs-12 text-danger">{(localProps.id == localProps.old_id && localProps?.url && localProps?.status == 2)?(localProps?.reason?localProps?.reason:"Update this document"):""}</div>
        <input
          type="file"
          className="d-none"
          onChange={handleChangeInputFile}
          ref={hiddenFileInputProfilePic}
        />
    </>
  );
}