import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import toast, { Toaster } from "react-hot-toast";
import { LazyLoader, ImageLazyLoader } from "../../utils/LazyLoader/index";

export default function Home(props: any) {
  const context: any = useContext(DataContext);
  const [status, SetStatus] = useState(false);
  const [is_menu_open, SetIsMenuOpen] = useState(false);
  const [companies_data, SetCompaniesData] = useState([]);
  const [noCompany, setNoCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statistics, SetStatistics]: any = useState({});
  const [map_position, SetMapPosition] = useState({
    center: {
      lat: 11.342423,
      lng: 77.728165,
    },
    zoom: 11,
  });
  const [order_task, SetOrderTaskModal] = useState({
    is_open: false,
  });
  const containerStyle = {
    width: "100%",
    height: "75vh",
  };
  const [imgBaseUrl, setImgBaseUrl]: any = useState();

  useEffect(() => {
    console.log("Home On mount :", props);

    init();
    return () => {
      context.SetAppData((prevValue: any) => {
        prevValue.home_page = false;
        return { ...prevValue };
      });
    }
  }, []);
  useEffect(() => {
    if (context.app_data.image_base_url) {
      setImgBaseUrl(context.app_data.image_base_url);
    }
  }, [context.app_data.image_base_url]);

  // const handleChange = (event) => {
  //     SetStatus(event.target.checked);
  //     console.log("check: ", status);
  // };

  async function init() {
    setLoading(true);
    let data: any = await api("/init");
    console.log("brand_users response :", data);
    if (data.status_code == 200) {
      if (data?.response?.new_application_status == 3) {
        if (window.location.origin + "/signup/request" != window.location.href) {
          window.location.href = "/signup/request";
        }
      } else if (data?.response?.companies && data?.response?.companies.length == 0) {
        if (window.location.origin + "/signup/success" != window.location.href && window.location.origin + "/signup/success/applied" != window.location.href) {
          window.location.href = "/signup/success";
        }
      } else {
        context.SetAppData((prevValue: any) => {
          prevValue.home_page = true;
          return { ...prevValue };
        });
      }

      SetStatistics(data.response?.statistics);

      SetCompaniesData(data.response.companies);
      if (data.response?.companies?.length == 0) {
        setNoCompany(true);
      }
    }
    setLoading(false);
  }

  async function update(id: any) {
    let pass_data = {
      post: {
        brand_id: id,
      },
    };
    let data: any;
    if (id == -1) {
      data = await api("/company/status");
    } else {
      data = await api("/company/status", pass_data);
    }
    console.log("brand_users response :", data);
    if (data.status_code == 200) {
      console.log("brand_users response :", data.response?.message);
      let message = data.response?.message;

      console.log(message);

      init();
      if (message != undefined) {
        toast.error(message);
      }
    }
  }

  return (
    <div className="container py-3">
      <Toaster />
      <div className="row">
        <div className="col-6 pe-2 mb-3">
          <div className="box-3 p-3 d-flex flex-column h-100">
            <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/330ac397130f29b354ca21fa5d8fdead.png" />
            <div className="fw-500 fs-16 mb-1 mt-auto">
              <LazyLoader
                show={statistics?.hasOwnProperty('today_earnings') ? false : true}
                style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
              />
              {statistics?.hasOwnProperty('today_earnings') && (
                <>&#8377;{statistics?.today_earnings}</>
              )}
            </div>
            <div className="fw-500 text-grey-1">Today's earnings</div>
          </div>
        </div>
        <div className="col-6 ps-2 mb-3">
          <div className="box-3 p-3 d-flex flex-column h-100">
            <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/5bb7bb7455b643dbf0cd78c9bed400cb.png" />
            <div className="fw-500 fs-16 mb-1 mt-auto">
              <LazyLoader
                show={statistics?.hasOwnProperty('week_earnings') ? false : true}
                style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
              />
              {statistics?.hasOwnProperty('week_earnings') && (
                <>&#8377;{statistics?.week_earnings}</>
              )}
            </div>
            <div className="fw-500 text-grey-1">Week's earnings</div>
          </div>
        </div>

        <div className="col-6 ps-2 mb-3">
                <div className="box-3 p-3 d-flex flex-column h-100">
                    <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/e11ca70fad70b78608cdb4aa1d927161.png" />
                    <div className="fw-500 fs-16 mb-1 mt-auto">
                        <LazyLoader
                            show={statistics?.hasOwnProperty('floating_cash') ? false : true}
                            style={{ height: "18px", width: "80px", marginBottom: "0.25rem" }}
                        />
                        {statistics?.hasOwnProperty('floating_cash') && (
                            <>&#8377;{statistics?.floating_cash}</>
                        )}
                    </div>
                    <div className="fw-500 text-grey-1">Floating Cash</div>
                </div>
            </div>
      </div>
      <h6>Your Companies</h6>
      {loading && (
        <div className="d-flex align-items-center box-1 p-3">
          <LazyLoader
            className="profile-div"
          />
          <div className="ms-2">
            <div className="fw-500">
              <LazyLoader
                show={true}
                style={{ height: "20px", width: "200px" }}
              />
            </div>
          </div>
        </div>
      )}
      {companies_data.map((ele: any, index: any) => (
        <Link
          to={"/company/" + ele.brand_id + ""}
          className="link-text"
          key={index}
        >
          <div className="d-flex align-items-center box-1 p-3">
            {imgBaseUrl && ele.logo ? (
              <ImageLazyLoader
                parentClassName="profile-div"
                className="img-fluid"
                src={imgBaseUrl + ele.logo}
              />
            ) : (
              <ImageLazyLoader
                parentClassName="profile-div"
                className="img-fluid"
                src={require("../../assets/img/profile.jpg")}
              />
            )}
            <div className="ms-2">
              <div className="fw-500">
                {ele.name}
                {ele.checked && (
                  <i className="ms-1 fas fa-circle text-success fs-10"></i>
                )}
                {ele.has_active_order && (
                  <i className="ms-2 fas fa-biking text-success"></i>
                )}
              </div>
            </div>
            <i className="ms-auto fas fa-chevron-right"></i>
          </div>
        </Link>
      ))}
      {noCompany && (
        <div className="box-1 p-3 pb-3 text-center">
          <img src={require("../../assets/icon/delay.png")} width="60" />
          <div className="mt-2 fw-500">No Company</div>
        </div>
      )}
    </div>
    // <div style={styles.pTop}>
    //   <Toaster />
    //   <div style={styles.containerTop}>
    //     {companies_data.map((ele: any, index: any) => (
    //       <Link to={"/orders/" + ele.brand_id + "/active"} style={styles.link}>
    //         <div style={styles.card} key={index}>
    //           <div style={styles.cursor}>
    //             <div style={styles.alignCenter}>
    //               <div className="p-1">
    //                 <img
    //                   src={require("../../assets/img/profile.jpg")}
    //                   style={styles.delbrandimg}
    //                   // className="delbrand-img"
    //                 />
    //               </div>
    //               <p style={styles.para}>{ele.name}</p>
    //               <div style={styles.msauto}>
    //                 {/* <MaterialIcons name="keyboard-arrow-right" size={16} color="black" /> */}
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </Link>
    //     ))}
    //   </div>
    // </div>
  );
}
