import { useState, useEffect } from "react";

function Package(props: any) {
  const [data, SetData]: any = useState({});
  useEffect(() => {
    if (props) {
      SetData(props.data);
    }
  }, [props]);
  return (
    <>
      {(data?.package_content || data?.package_value_amount) && (
        <div className="box-1 p-3 mb-3">
          <div className="d-flex">
            <div className="me-2">
              <div className="bg-circle cardboard-color">
                <i className="fas fa-box-open"></i>
              </div>
            </div>
            <div>
              <div className="fw-500">Package Details</div>
              <div className="text-muted">{data.package_content}</div>
              <div className="text-muted">
                {data.package_value_currency_symbol} {data.package_value_amount}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Package;
