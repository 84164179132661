import { useState, useEffect, useContext } from "react";
import * as bootstrap from "bootstrap";

import DataContext from "../../utils/Context/DataContext";

let myModal: any;
export default function ModalDialMobile(props: any) {
  const context: any = useContext(DataContext);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });

  useEffect(() => {
    let myModal1: any = document.getElementById("DialMobile");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      let cod: any = document.getElementById("DialMobile");
      myModal = new bootstrap.Modal(cod, {});
      myModal.show();
    }
  }, [props.open]);

  function openDialer(num: any) {
    if (context.app_data.application.platform == "android") {
      window.open("tel:" + num);
    } else {
      window.location.href = "tel:" + num;
    }
    myModal.hide();
  }

  return (
    <div
      className="modal fade"
      id="DialMobile"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 br-20 mx-3">
          <div className="modal-body">
            <h6 className="mb-3 mt-2">Call Via</h6>
            {props?.numbers?.length > 0 && (
              <div className="menu-list mb-2">
                {props?.numbers?.map((d: any, i: any) => (
                  <div className="menu-list-item" onClick={() => openDialer(d.number)} key={i}>
                    <label className="form-label mb-0">{d?.name ? d.name : 'Phone Number'}</label>
                    <div className="fs-15 fw-500">{d.number}</div>
                  </div>
                ))}
              </div>
            )}
            <div className="">
              <button
                type="button"
                className="btn text-muted btn-sm btn-light w-100 mt-3"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
