import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { api } from "../../utils/Api";

export default function Notification() {
  const [notification_data, SetNotificationData]: any = useState([]);
  const [next_page, SetNextPage] = useState(false);
  const [page_no, SetPageNo] = useState(1);

  useEffect(() => {
    get_notification();
  }, []);

  async function get_notification() {
    let pass_data: any = {
      get: {
        page: 1,
      },
    };

    let response: any = await api("/company/notifications", pass_data);
    console.log("/outlet/orders:", response.response);
    if (response.status_code == 200) {
      SetNotificationData(response.response.data);
      SetNextPage(response.response.next_page);
    }
  }

  async function update_view(id: any) {
    let pass_data: any = {
      get: {
        id: id,
      },
    };

    let response: any = await api("/company/notification_viewed", pass_data);
    console.log("/outlet/orders:", response.response);
    // if (response.status_code == 200) {
    //   SetNotificationData(response.response);
    // }
  }
  async function load_more() {
    let page_no_value = page_no + 1;

    SetPageNo(page_no_value);

    let pass_data: any = {
      get: {
        page: page_no_value,
      },
    };

    let data: any = await api("/company/notifications", pass_data);
    console.log("/outlet/orders:", data.response);
    if (data.status_code == 200) {
      SetNotificationData((prevValue: any) => {
        // data.response.data.map((item: any) => {
        //   prevValue.push(item);
        // });
        return [...prevValue, ...data.response.data];
      });

      SetNextPage(data.response.next_page);
    }
  }

  return (
    <div className="container py-3">
      <h6>Notifications</h6>
      {notification_data?.map(
        (ele: any, index: any) =>
          // (ele.click_location = "new_order" ? (
            <Link
              className="link-text mb-2 d-block"
              to={"/order/" + ele.order_id}
              onClick={() => {
                update_view(ele.id);
              }}
              key={index}
            >
              <div
                className={
                  ele.viewed
                    ? "box-1 p-3 bg-light"
                    : "box-1 p-3"
                }
              >
                <div className="fw-500">{ele.title}</div>
                <div className="fs-13">{ele.message}</div>
                <div className="fs-12 text-muted">{ele.time_formatted}</div>
              </div>
            </Link>
          // ) : (
          //   <div
          //     className="p-2"
          //     style={
          //       ele.viewed
          //         ? { backgroundColor: "white" }
          //         : { backgroundColor: "none" }
          //     }
          //     onClick={() => {
          //       update_view(ele.id);
          //     }}
          //   >
          //     <Link to={"/view_details/" + ele.order_id}>
          //       <b>{ele.title}</b>
          //       <p>{ele.message}</p>
          //     </Link>
          //   </div>
          // ))
      )}

      {next_page ? (
        <p
          className="text-prime fs-7 fw-bold text-center cursor mb-5"
          onClick={() => {
            load_more();
          }}
        >
          Load More
        </p>
      ) : null}
    </div>
  );
}
