export const AppConfig:any = {
  base_url: {
    api: 'https://'+ window.location.hostname +'/app',
    superapp: "https://",
  },
  user_agent: {
    superapp: {
      android: "",
      ios: "",
    },
  },
  CDN_Media_url : "https://cdn3.1superapp.com/media/",
  CDN_Image_url : "https://cdn2.1superapp.com/images/",
  environment : "Production",
  token: ""
};
