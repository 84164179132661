import { useState, useEffect } from "react";

function Products(props: any) {
  const [data, SetData]: any = useState({});
  useEffect(() => {
    if (props) {
      SetData(props.data);
    }
  }, [props]);
  return (
    <>
      {(data?.products?.length > 0) && (
        <div className="box-1 p-3 mb-3">
          <div className="border-bottom pb-1 mb-2 fw-500 text-dark">
            Ordered Items
          </div>
          <div className="fs-12">
            {data?.products?.map((data: any, index: any) => (
              <div className="d-flex mb-1" key={index}>
                <div className="me-1">
                  {data.quantity} x{" "}
                  {data.product_name ? data.product_name : data.variant_name}
                </div>
                <div className="ms-auto fw-500">₹ {data.amount}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
export default Products;
