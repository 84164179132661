import React, { useState, useEffect, useContext } from "react";
import { api } from "../../utils/Api";
import { Link, Outlet, useParams } from "react-router-dom";
import { AppConfig } from "../../config";
import { LazyLoader, ImageLazyLoader } from "../../utils/LazyLoader/index";
// import "../../assets/libraries/bootstrap/dist/js/bootstrap.bundle.js";
import ModalTime from "./ModalTime";

import { AppHelper } from "../../utils/Helper";
import {
  SwipeableDrawer,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import InfiniteScroll from "react-infinite-scroll-component";

import Skeleton from "react-loading-skeleton";
// import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";

// import ModalTime from "../../Outlets/Dashboard/ModalTime";

function Settlement() {

  const [id_value, SetIdValue] = useState(useParams());
  const [next_page, SetNextPage] = useState(false);
  const [page_no, SetPageNo] = useState(1);
  const [is_loading, SetIsLoading] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState("");
  const [filter_data, setFilterData] = useState("");
  const [product_list, setProductList] = useState([]);
  const [visible_content, SetVisibleContent] = useState(false);
  const [base_url, SetBaseUrl] = useState("");
  const [outlet_id, SetOutletId] = useState("");
  const [statisticsView, SetStatisticsView]: any = useState('lastmonth');
  const [statisticsViewFormatted, SetStatisticsViewFormatted]: any = useState('Last Month');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateShow, setStartDateShow] = useState("");
  const [endDateShow, setEndDateShow] = useState("");
  const [statistics, SetStatistics]: any = useState({});
  const [time_modal, setTimeModal] = useState({
    is_open: false,
    data: {},
  });
  useEffect(() => {
 
    // get_categories("from_top");
    // ProductList("", "", pass_data);
   let pass_data = {
                  sort: "",
                  page_no: page_no,
      from:"from_top"

                };
    ProductList("",pass_data)

    get_statistics();
    if(statisticsView == 'today'){
      SetStatisticsViewFormatted('Today')
  } else if(statisticsView == 'yesterday'){
      SetStatisticsViewFormatted('Yesterday')
  } else if(statisticsView == 'lastweek'){
      SetStatisticsViewFormatted('Last Week')
  }else if(statisticsView == 'week'){
      SetStatisticsViewFormatted('This Week')
  } else if(statisticsView == 'pastweek'){
      SetStatisticsViewFormatted('Past Week')
  }else if(statisticsView == 'pastmonth'){
      SetStatisticsViewFormatted('Past Month')
  } else if(statisticsView == 'lastmonth'){
      SetStatisticsViewFormatted('Last Month')
  }else if(statisticsView == 'month'){
      SetStatisticsViewFormatted('This Month')
  } else if(statisticsView == 'year'){
      SetStatisticsViewFormatted('This Year')
  }else if(statisticsView == 'lastyear'){
      SetStatisticsViewFormatted('Last Year')
  } else if(statisticsView == 'finyear'){
      SetStatisticsViewFormatted('This Financial Year')
  }else if(statisticsView == 'lastfinyear'){
      SetStatisticsViewFormatted('Last Financial Year')
  } else if(statisticsView == 'custom'){
      SetStatisticsViewFormatted('Custom')
  }
  },[
    statisticsView
])

  function convertDateTime(dateTimeStr :any) {
    const date = new Date(dateTimeStr);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();
    
    // const hours = String(date.getHours()).padStart(2, '0');
    // const minutes = String(date.getMinutes()).padStart(2, '0');
    // const seconds = '00'; // Set seconds to 00
    
    const formattedDateTime = `${day}-${month}-${year} `;
    return formattedDateTime;
  }



  async function get_statistics() {
    let pass_data = {
        get: {
           
            view: statisticsView,
        },
    };
    let data: any = await api("/company/settlement_statistics", pass_data);
    SetStatistics(data.response?.data);
    setStartDate(data.response?.start)
    setEndDate(data.response?.end)
    const convertedDateTime = convertDateTime(data.response?.start_display);

       

  
    const convertedEndDateTime = convertDateTime(data.response?.end_display);
    setStartDateShow(convertedDateTime)
    setEndDateShow(convertedEndDateTime)

}
//   async function get_categories(props: any) {

//     SetIsLoading(true)
//     let pass_data = {
//       get: {
//         brand: id_value.brand_id,
//       },
//     };

//     let response: any = await api("/brand/outlet_list", pass_data);
//     if (response.status_code == 200) {
//       // setOutlets

//       let pass_data;
//     //   if (props == "from_top") {
//     //     pass_data = {
//     //       sort: "",
//     //       page_no: 1,
//     //     };
//     //     SetPageNo(1);
//     //   } else {
//     //     pass_data = {
//     //       sort: "",
//     //       page_no: page_no,
//     //     };
//     //   }
//       console.log(response.response.outlets);
//       setOutlets(response.response.outlets);
//       // SetOutletId(response.response.outlets[0]?.id);
//       // setFilterData("");
//       ProductList("", "", pass_data);
 
//       SetIsLoading(false)
//       // SetVisibleContent(true);
//     }
//   }
const sort = (val:any) =>{

    SetPageNo(1)

    let send_data = {
      page_no: 1,
    };
    ProductList( val, send_data);


  }


  async function ProductList( search: any, get_data: any) {
    if(!get_data.load_more){
      SetIsLoading(true)

    }
    // console.log(send_data)
    let pass_data
    if (get_data.from == "from_top") {
        pass_data = {
            get: {
            
              page: 1,
              sort:search
              // search: search,
         
            },
          };
        SetPageNo(1);
      } else {
        pass_data = {
            get: {
            
              page: get_data.page_no,
              sort:search
              // search: search,
         
            },
          };
      }
   


    let response:any = await api("/company/settlements",pass_data);
    
    if (response.status_code == 200) {

      if (response.response.hasOwnProperty("data")) {
        // if (get_data.load_more) {
        //   setProductList((prevValue:any) => {
        //     response?.response?.data?.map((ele: any) => {
        //       prevValue.push(ele);
        //     });
        //     return [...prevValue];
        //   });
        // } else {
          setProductList(response.response.data);

          // SetOuterData(response.response);

          // disable();
        // }
        SetIsLoading(false)
      }
      SetVisibleContent(true);
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }

      SetBaseUrl(response.response.image_base_url)
   
    }
  }


//   const sort = (val:any) =>{

//     SetPageNo(1)

//     let send_data = {
//       page_no: 1,
//     };
//     ProductList(outlet_id, val, send_data);


//   }
  return (
    <div className="">
    {/* Header */}
    <div className="  row px-sm-0 px-md-3 pb-sm-2 mb-2">
   
      <div >
        <h6 className="ms-2  mb-2">Settlement</h6>
      </div>
      <div className="d-flex">

      {/* <div className="col-md-6 sm-pt-2 end">
       
        <div className="input-icons form-group p-2">
          <input
            type="text"
            className="form-control search-bar p-0"
            placeholder="Search here..."
            value={filter_data}
            onChange={handleInputChange}
          />
          <div className="cursor px-2 border-left">
            <img
              src={
                AppConfig.CDN_Media_url +
                "c241785c497fb98f5f7d4e587b3857ba.svg"
              }
              className="w-20px"
            />
          </div>
        </div>
      </div> */}
      <div className="ms-auto">
   
        <div className="d-flex">
   
        <div className="d-flex align-items-center border br-1 pr-2 bg-white">
                        <div className="dropdown">
                            <button className="btn bg-white text-muted btn-sm br-4 px-2 d-flex dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                 <ImageLazyLoader className="img-fluid" width={10} src={AppConfig.CDN_Media_url+"420485dfc5e7bf2426a897a723ad243c.svg"} /> 
                                <span className="ps-1">{statisticsViewFormatted}</span>
                            </button>
                            <ul className="dropdown-menu  dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('today'); }}>Today</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('yesterday'); }}>Yesterday</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastweek'); }}>Last Week</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('week'); }}>This Week</button></li>

                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('pastweek'); }}>Past Week</button></li>

                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('pastmonth'); }}>Past Month</button></li>

                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastmonth'); }}>Last Month</button></li>
                                
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('month'); }}>This Month</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('year'); }}>This Year</button></li>


                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastyear'); }}>Last Year</button></li>
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('finyear'); }}>This Financial Year</button></li>

                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastfinyear'); }}>Last Financial Year</button></li>
                                <li><button type="button" className="dropdown-item"
                                 onClick={() => {    let set_data = {
                                    start:startDate,
                                    end:endDate
                                       
                                        
                                      
                                        
                                      };
                                      setTimeModal({
                                        is_open: true,
                                        data: set_data,
                                      });}}>Custom</button></li>
                                
                            </ul>
                        </div>
                        <div className="text-secondary  me-2 fs-12 cursor pr-1" 
                              onClick={() => {
                                let set_data = {
                                start:startDate,
                                end:endDate,
                                from:"settlement",
                                outlet:outlet_id
                                   
                                    
                                  
                                    
                                  };
                                  setTimeModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                            }}
                        // data-toggle="modal" data-target="#customDate"
                        >{startDateShow}  - {endDateShow}</div>
                    </div>
        <div className="form-group d-flex align-items-center ps-0">
         
          {/* {console.log(appliction)} */}

          {/* {props.props!="brand"  &&
          <select
            data-show-content="true"
            className="form-control product-timing-location"
            id="exampleFormControlSelect1"
            value={selectedApplication}
            onChange={handleApplicationChange}
          >
            <option value="">
              All
              </option>
            {outlets?.map((ele, i) => (
              <option key={i} value={ele?.id}>
                {ele?.name}
              </option>
            ))}
          </select>
} */}
          <div className=" ms-auto pe-2">
            <img
              src={
                AppConfig.CDN_Media_url +
                "6b8041f390f1ce51def0c7659b39a408.svg"
              }
              className="w-10px"
            />
          </div>
        </div>
        <div className="dropdown ms-2">
            <button
              className="btn btn-white dropdown-toggle d-flex align-items-cetner"
              type="button"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={require("../../assets/icon/filter.png")}
                className="icon-14px"
              />
          
            </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => 
                      sort("asc")
                    }
                  >
                    <p className="mb-0 p-2">Oldest</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => sort("desc") }
                  >
                    <p className="mb-0 p-2">Newest</p>
                  </div>
                </a>
              </li>
          
            </ul>
      </div>

     
      </div>
      </div>

      </div>
    </div>
    <div className="mt-3 mx-3">
<div className="row">
  <div className="col-6">
    <div
                              className="card p-3 pb-0 "
                       
                            >
                       

                            <div>Total Paid</div>
                            <div>{statistics.total_settlement_amount}</div>

                            </div>
                            </div>
                            <div className="col-6">
                            <div
                              className="card p-3 pb-0"
                       
                            >
                       

                            <div>Current Balance</div>
                            <div>{statistics.total_settlement_pending_amount}</div>

                            </div>
                            </div>
                            </div>
                            </div>


    <InfiniteScroll
              dataLength={product_list.length}
              next={() => {
                let send_data = {
                  page_no: page_no,

                  load_more: true,
                };
                ProductList(filter_data, send_data);
              }}
              hasMore={next_page}
              loader={
                <div className="center m-5">
                  <div className="lds-dual-ring"></div>
                  <p className="text-prime small ps-2">Just a second ...</p>
                </div>
              }
            >
              <div className="mt-3 mx-3">
              {visible_content ?

  <div className="row">
    {product_list.map((ele: any) => (
                          <div className="col-md-4 mb-2">
                            <div
                              className="card p-3 pb-0"
                       
                            >
                            <div className="d-flex">
<div>
  <div>{ele?.outlet_name}</div>
  <div>₹ {ele?.amount}</div>

  <div>{ele?.notes}</div>

  
  
  
  </div>
<div className="ms-auto">
<div className="end">#{ele?.id}</div>
   <div>{ele?.time}</div>
   </div>


                            </div>

                              <div className="prods-card ">
                                <Accordion className="px-0">
                                  <AccordionSummary
                                    expandIcon={
                                      <img src={AppConfig.CDN_Media_url+"78f45bd73c51ade6a32e1185735e5439.svg"} className="w-10px" />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <p className="fs-7 fw-bold text-black">
                                    Document
                                    </p>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div className="w-100">
                                

                                        {ele?.documents?.map((doc_ele:any,i:any)=>(
                                            <div className="my-2">
                                      <a
                                    href={doc_ele?.full_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={doc_ele?.full_url}
                                      className="delivery_profile_img"
                                    />
                                  </a>
                                  <hr/>
                                  </div>
                             

 ) )}
                                    </div>

                                  </AccordionDetails>
                                </Accordion>

                                <Accordion className="px-0">
                                  <AccordionSummary
                                    expandIcon={
                                      <img src={AppConfig.CDN_Media_url+"78f45bd73c51ade6a32e1185735e5439.svg"} className="w-10px" />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <p className="fs-7 fw-bold text-black">
                                    Order List
                                    </p>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div className="w-100">
                                      <div className="">

                                        {ele?.orders?.map((or_ele:any,i:any)=>(
                                        
                                  <div > 
                               
                                  <div>{or_ele?.customer?.name}</div>
                                  <div>₹{or_ele?.amount}</div>

                                  <div>{or_ele?.notes}</div>

                                  <div>#{or_ele?.agent_order_id}</div>

                             
<hr/>
                                  </div>
 ) )}
                                    </div>
                                    </div>

                                  </AccordionDetails>
                                </Accordion>
                              </div>  
                            </div>
                            
                          </div>
                        ))}
                        </div>
                

:
  <div className="row">
  {Array.from({ length: 5 }).map((_, i) => (
   
       <div className="col-md-4 mb-2">
          <div className="card p-3 pb-0">
            <Skeleton height={30} width={100} />
            <Skeleton height={20} width={150} />
            <Skeleton height={20} width={200} />
            <Skeleton height={20} width={80} />
          
            <Skeleton height={30} width={100} />
        
          </div>
        </div>
    
  ))}
  </div>
             }
                     </div>
  
    </InfiniteScroll>

    {statistics != null &&
<ModalTime
        open={time_modal.is_open}
        data={time_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setTimeModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {
            SetStatistics(data.data?.data);
            
   
            setStartDate(data.data?.start)
            setEndDate(data.data?.end)
            const convertedDateTime = convertDateTime(data.data?.start_display);

            SetStatisticsViewFormatted("custom")
           

      
            const convertedEndDateTime = convertDateTime(data.data?.end_display);
            setStartDateShow(convertedDateTime)
            setEndDateShow(convertedEndDateTime)

            
          
     
          }
        }}
      />
    }
    </div>

  )
}

export default Settlement