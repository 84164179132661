import React, { useState, useEffect, useContext, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import { useNavigate } from "react-router-dom";

import SignupFormUpload from "./SignupFileUpload";

export default function ActivationForm(props: any) {
  const navigate = useNavigate();
  const context: any = useContext(DataContext);
  const [localProfileImg, setLocalProfileImg]: any = useState({});
  const [input, setInput]: any = useState({
    name: "",
    mobile: "",
    location: "Erode",
  });
  const handleInputs = (e: any) => {
    setInput((prev: any) => {
      prev[e.target.name] = e.target.value;
      return { ...prev };
    });
  };
  let thumb_url:any = {
    upload_img: require('../../assets/img/upload-img.png'),
    file_img: require('../../assets/img/file.png'),
    uploaded_file_img: require('../../assets/img/file.png'),
  }
  const [fileUploadPreview, setFileUploadPreview]: any = useState({
    click_location: '',

    profile_img: thumb_url.upload_img,
    driving_license_1: thumb_url.file_img,
    driving_license_2: thumb_url.file_img,
    rc_book_1: thumb_url.file_img,
    rc_book_2: thumb_url.file_img,
    aadhaar_1: thumb_url.file_img,
    aadhaar_2: thumb_url.file_img,
    pan_1: thumb_url.file_img,
    pan_2: thumb_url.file_img,

    loading_profile_img: false,

    name_profile_img: 'Upload',
  });

  const hiddenFileInputProfilePic:any = useRef(null);
  const handleClickFileDoc = (click_location:any) => {
    setFileUploadPreview((prev: any) => {
        prev.click_location = click_location;
        return { ...prev };
    });
    hiddenFileInputProfilePic.current.click();
  };
  
  const handleChangeInputFile = async (event:any) => {
    const fileUploaded = event.target.files[0];
    if(fileUploaded){
      let img_type = false;
      if(fileUploaded?.type){
        if(fileUploaded?.type.match('image/')){
          img_type = true;
        }
      }
      
      let image_file_data:any = {
        file_name: fileUploaded.name,
      };
      if(img_type){
        var reader = new FileReader();
        var url = reader.readAsDataURL(fileUploaded);
    
        reader.onloadend = function (e:any) {
          image_file_data.image = [reader.result]
          updateFileUploadPreviewState(image_file_data);
        };
      } else {
        image_file_data.image = thumb_url.uploaded_file_img;
        updateFileUploadPreviewState(image_file_data);
      }


      if(fileUploadPreview.click_location == 'profile_img'){
        uploadImageGallery(fileUploaded);
      }
    } else {
      if(fileUploadPreview?.click_location){
        if(!input[fileUploadPreview?.click_location]){
          updateFileUploadPreviewState();
        }
      }
    }
  };
  const updateFileUploadPreviewState = (d:any = {}) => {
    if(fileUploadPreview.click_location == 'profile_img'){
      if(d){
        setFileUploadPreview((prev: any) => {
          if(d.file_name){
            prev.name_profile_img = d.file_name;
          }
          if(d.image){
            prev.profile_img = d.image;
          }
          return { ...prev };
        });
      } else {
        resetFileUploadPreviewState(fileUploadPreview.click_location);
      }
    }
  }
  const resetFileUploadPreviewState = (click_location:any) => {
    if(click_location == 'profile_img'){
      setFileUploadPreview((prev: any) => {
          prev.name_profile_img = 'Upload';
          prev.profile_img = thumb_url.upload_img;
          return { ...prev };
      });
    }
  }
  const uploadImageGallery = async (fileUploaded:any) => {
    let pass_data: any = {
      post: {
        image: fileUploaded
      },
    };
    let data_res: any = await api("/company/upload_image", pass_data, false, true);
    if (data_res.status_code == 201 && data_res.response?.id) {
      setInput((prev: any) => {
        prev.profile_img = data_res.response.id;
        return { ...prev };
      });
    } else {
      setFileUploadPreview((prev: any) => {
          prev.name_profile_img = 'Upload';
          prev.profile_img = thumb_url.upload_img;
          return { ...prev };
      });
    }
  }
  


  useEffect(() => {
    if (context.app_data.previous_request?.name) {
      setInput((prev: any) => {
        prev.name = context.app_data.previous_request.name;
        if (context.app_data.previous_request?.mobile) {
          prev.mobile = context.app_data.previous_request.mobile;
        }
        prev.location = context.app_data.previous_request.location;
        prev.profile_img = context.app_data.previous_request.profile_img;
        prev.driving_license_1 = context.app_data.previous_request.driving_license_1;
        prev.driving_license_2 = context.app_data.previous_request.driving_license_2;
        prev.rc_book_1 = context.app_data.previous_request.rc_book_1;
        prev.rc_book_2 = context.app_data.previous_request.rc_book_2;
        prev.aadhaar_1 = context.app_data.previous_request.aadhaar_1;
        prev.aadhaar_2 = context.app_data.previous_request.aadhaar_2;
        prev.pan_1 = context.app_data.previous_request.pan_1;
        prev.pan_2 = context.app_data.previous_request.pan_2;
        return { ...prev };
      });
      setLocalProfileImg((prev: any) => {
        prev.profile_img = context.app_data.previous_request.profile_img;
        prev.profile_img_name = context.app_data.previous_request.profile_img_name;
        prev.profile_img_reason = context.app_data.previous_request.profile_img_reason;
        prev.profile_img_status = context.app_data.previous_request.profile_img_status;
        prev.profile_img_url = context.app_data.previous_request.profile_img_url;
        return { ...prev };
      });
      if(context.app_data.previous_request.profile_img_url){
        setFileUploadPreview((prev: any) => {
            prev.name_profile_img = context.app_data.previous_request.profile_img_name;
            prev.profile_img = context.app_data.previous_request.profile_img_url;
          return { ...prev };
        });
      }
    }
  }, [context.app_data.previous_request]);
  useEffect(() => {
    if (context.app_data.user_details) {
      setInput((prev: any) => {
        prev.name = context.app_data.user_details.name;
        if (context.app_data.user_details?.mobile) {
          prev.mobile = context.app_data.user_details.mobile;
        }
        return { ...prev };
      });
    }
  }, [context.app_data.user_details]);

  useEffect(() => {
    if (context.app_data.force_superapp_redirect) {
      window.location.href = 'https://1superapp.com/dashboard';
    }
  }, []);

  async function onSubmit(e: any) {
    e.preventDefault();
    let allow = true;
    let update = false;
    if(context.app_data.new_application_status == 3){
      allow = false;
      if(context.app_data.previous_request?.profile_img_status == 2 && input?.profile_img == context.app_data.previous_request.profile_img){
        allow = false;
        toast.error("Please update your profile picture");
      } else if(context.app_data.previous_request?.driving_license_1_status == 2 && input?.driving_license_1 == context.app_data.previous_request.driving_license_1){
        allow = false;
        toast.error("Please update your driving license");
      } else if(context.app_data.previous_request?.aadhaar_1_status == 2 && input?.aadhaar_1 == context.app_data.previous_request.aadhaar_1){
        allow = false;
        toast.error("Please update your aadhaar");
      } else if(context.app_data.previous_request?.pan_1_status == 2 && input?.pan_1 == context.app_data.previous_request.pan_1){
        allow = false;
        toast.error("Please update your pan");
      } else if(context.app_data.previous_request?.rc_book_1_status == 2 && context.app_data.previous_request?.rc_book_1 && input?.rc_book_1 == context.app_data.previous_request.rc_book_1){
        allow = false;
        toast.error("Please update your rc book");
      } else {
        allow = true;
        update = true;
      }
    }
    if(allow){
      if(input?.profile_img && (input?.driving_license_1) && (input?.aadhaar_1) && (input?.pan_1)){
        let pass_data: any = {
          post: input,
        };
        let data_res: any;
        if(update){
          data_res = await api("/account/signup_request", pass_data);
        } else {
          data_res = await api("/account/signup", pass_data);
        }
        if (data_res.status_code == 201) {
          context.SetAppData((prevValue: any) => {
            prevValue.force_superapp_redirect = true;
            return { ...prevValue };
          });
          if(update){
            navigate("/signup/success/re_applied");
          } else {
            navigate("/signup/success/applied");
          }
        }
      } else {
        if(!input?.profile_img){
          toast.error("Please upload your profile picture");
        } else if(!input?.driving_license_1){
          toast.error("Please upload your driving license");
        } else if(!input?.aadhaar_1){
          toast.error("Please upload your aadhaar");
        } else if(!input?.pan_1){
          toast.error("Please upload your pan");
        }
      }
    }
  }

  return (
    <form
      onSubmit={onSubmit}
      className="container bg-white p-3"
      // style={{ height: window.innerHeight }}
      style={context.app_data.force_superapp_redirect?{display: "none"}:{}}
    >
      <Toaster position="top-center" reverseOrder={false} />
      <div className="text-center mb-2 mt-4">
        <img
          src="https://1superapp.com/assets/images/1superapp-logo-plain.png"
          className="img-fluid mb-2"
          width="60"
        />
        <h4>Delivery Partner</h4>
      </div>
      <div className="text-center mb-3">
        <h6>Signup</h6>
      </div>
      {(context.app_data.previous_request.rejected_reason) && (
        <div className="mb-3 bg-danger text-white br-8 p-3 fs-12">{context.app_data.previous_request.rejected_reason}</div>
      )}
      <div className="mb-3">
        <label>Name*</label>
        <input
          type="text"
          className="form-control"
          name="name"
          placeholder="Name"
          value={input.name || ''}
          onChange={handleInputs}
          required
        />
      </div>
      <div className="mb-3">
        <label>Mobile*</label>
        <input
          type="number"
          className="form-control"
          name="mobile"
          placeholder="Mobile"
          value={input.mobile || ''}
          onChange={handleInputs}
          required
        />
      </div>
      <div className="mb-3">
        <label>City / Town*</label>
        <input
          type="text"
          className="form-control"
          name="location"
          placeholder="City / Town"
          value={input.location || ''}
          onChange={handleInputs}
          required
        />
      </div>
      <div className="mb-3">
        <label>Profile Picture*</label>
        <div className="text-center bg-light-blue br-8 p-3 cursor" onClick={() => handleClickFileDoc('profile_img')}>
          <img className="img-fluid" width={fileUploadPreview.profile_img == thumb_url.upload_img?"80":""} src={fileUploadPreview.profile_img}/>
          <div className="mt-1 fs-12">
            {fileUploadPreview.name_profile_img}
            {(localProfileImg?.profile_img_status == 1 && input?.profile_img) && (
            <i className="fas fa-check-circle text-success ms-1"></i>
            )}
          </div>
        </div>
        <div className="fs-12 text-danger">{(localProfileImg.profile_img == input?.profile_img && localProfileImg?.profile_img_url && localProfileImg?.profile_img_status == 2)?(localProfileImg?.profile_img_reason?localProfileImg?.profile_img_reason:"Update Profile Picture"):""}</div>
      </div>
      <div className="mb-3">
        <label>Driving License*</label>
        <div className="mb-1">
          <SignupFormUpload 
            title="Upload document 1"
            id={input.driving_license_1}
            old_id={context.app_data.previous_request?.driving_license_1}
            status={context.app_data.previous_request?.driving_license_1_status}
            reason={context.app_data.previous_request?.driving_license_1_reason}
            name={context.app_data.previous_request?.driving_license_1_name}
            url={context.app_data.previous_request?.driving_license_1_url}
            ext={context.app_data.previous_request?.driving_license_1_ext}
            add={(data: any) => {
              setInput((prev: any) => {
                prev.driving_license_1 = data;
                return { ...prev };
              });
            }}
            remove={() => {
              setInput((prev: any) => {
                prev.driving_license_1 = '';
                return { ...prev };
              });
            }}
          />
        </div>
        <div className="">
          <SignupFormUpload 
            title="Upload document 2"
            id={input.driving_license_2}
            old_id={context.app_data.previous_request?.driving_license_2}
            status={context.app_data.previous_request?.driving_license_1_status}
            reason={context.app_data.previous_request?.driving_license_1_reason}
            name={context.app_data.previous_request?.driving_license_2_name}
            url={context.app_data.previous_request?.driving_license_2_url}
            ext={context.app_data.previous_request?.driving_license_2_ext}
            add={(data: any) => {
              setInput((prev: any) => {
                prev.driving_license_2 = data;
                return { ...prev };
              });
            }}
            remove={() => {
              setInput((prev: any) => {
                prev.driving_license_2 = '';
                return { ...prev };
              });
            }}
          />
        </div>
      </div>
      <div className="mb-3">
        <label>Aadhaar*</label>
        <div className="mb-1">
          <SignupFormUpload 
            title="Upload document 1"
            id={input.aadhaar_1}
            old_id={context.app_data.previous_request?.aadhaar_1}
            status={context.app_data.previous_request?.aadhaar_1_status}
            reason={context.app_data.previous_request?.aadhaar_1_reason}
            name={context.app_data.previous_request?.aadhaar_1_name}
            url={context.app_data.previous_request?.aadhaar_1_url}
            ext={context.app_data.previous_request?.aadhaar_1_ext}
            add={(data: any) => {
              setInput((prev: any) => {
                prev.aadhaar_1 = data;
                return { ...prev };
              });
            }}
            remove={() => {
              setInput((prev: any) => {
                prev.aadhaar_1 = '';
                return { ...prev };
              });
            }}
          />
        </div>
        <div className="">
          <SignupFormUpload 
            title="Upload document 2"
            id={input.aadhaar_2}
            old_id={context.app_data.previous_request?.aadhaar_2}
            status={context.app_data.previous_request?.aadhaar_1_status}
            reason={context.app_data.previous_request?.aadhaar_1_reason}
            name={context.app_data.previous_request?.aadhaar_2_name}
            url={context.app_data.previous_request?.aadhaar_2_url}
            ext={context.app_data.previous_request?.aadhaar_2_ext}
            add={(data: any) => {
              setInput((prev: any) => {
                prev.aadhaar_2 = data;
                return { ...prev };
              });
            }}
            remove={() => {
              setInput((prev: any) => {
                prev.aadhaar_2 = '';
                return { ...prev };
              });
            }}
          />
        </div>
      </div>
      <div className="mb-3">
        <label>Pan*</label>
        <div className="mb-1">
          <SignupFormUpload 
            title="Upload document 1"
            id={input.pan_1}
            old_id={context.app_data.previous_request?.pan_1}
            status={context.app_data.previous_request?.pan_1_status}
            reason={context.app_data.previous_request?.pan_1_reason}
            name={context.app_data.previous_request?.pan_1_name}
            url={context.app_data.previous_request?.pan_1_url}
            ext={context.app_data.previous_request?.pan_1_ext}
            add={(data: any) => {
              setInput((prev: any) => {
                prev.pan_1 = data;
                return { ...prev };
              });
            }}
            remove={() => {
              setInput((prev: any) => {
                prev.pan_1 = '';
                return { ...prev };
              });
            }}
          />
        </div>
        <div className="">
          <SignupFormUpload 
            title="Upload document 2"
            id={input.pan_2}
            old_id={context.app_data.previous_request?.pan_2}
            status={context.app_data.previous_request?.pan_1_status}
            reason={context.app_data.previous_request?.pan_1_reason}
            name={context.app_data.previous_request?.pan_2_name}
            url={context.app_data.previous_request?.pan_2_url}
            ext={context.app_data.previous_request?.pan_2_ext}
            add={(data: any) => {
              setInput((prev: any) => {
                prev.pan_2 = data;
                return { ...prev };
              });
            }}
            remove={() => {
              setInput((prev: any) => {
                prev.pan_2 = '';
                return { ...prev };
              });
            }}
          />
        </div>
      </div>
      <div className="mb-3">
        <label>RC Book</label>
        <div className="mb-1">
          <SignupFormUpload 
            title="Upload document 1"
            id={input.rc_book_1}
            old_id={context.app_data.previous_request?.rc_book_1}
            status={context.app_data.previous_request?.rc_book_1_status}
            reason={context.app_data.previous_request?.rc_book_1_reason}
            name={context.app_data.previous_request?.rc_book_1_name}
            url={context.app_data.previous_request?.rc_book_1_url}
            ext={context.app_data.previous_request?.rc_book_1_ext}
            add={(data: any) => {
              setInput((prev: any) => {
                prev.rc_book_1 = data;
                return { ...prev };
              });
            }}
            remove={() => {
              setInput((prev: any) => {
                prev.rc_book_1 = '';
                return { ...prev };
              });
            }}
          />
        </div>
        <div className="">
          <SignupFormUpload 
            title="Upload document 2"
            id={input.rc_book_2}
            old_id={context.app_data.previous_request?.rc_book_2}
            status={context.app_data.previous_request?.rc_book_1_status}
            reason={context.app_data.previous_request?.rc_book_1_reason}
            name={context.app_data.previous_request?.rc_book_2_name}
            url={context.app_data.previous_request?.rc_book_2_url}
            ext={context.app_data.previous_request?.rc_book_2_ext}
            add={(data: any) => {
              setInput((prev: any) => {
                prev.rc_book_2 = data;
                return { ...prev };
              });
            }}
            remove={() => {
              setInput((prev: any) => {
                prev.rc_book_2 = '';
                return { ...prev };
              });
            }}
          />
        </div>
      </div>
      
      <input
          type="file"
          className="d-none"
          onChange={handleChangeInputFile}
          ref={hiddenFileInputProfilePic}
        />
      <button className="btn btn-primary w-100" type="submit">
        Submit
      </button>
    </form>
  );
}
