import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../utils/Context/DataContext";

// import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap.min.js";
import * as bootstrap from "bootstrap";

import { api } from "../../utils/Api";

let myModal: any;
export default function AllowBgLocation(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    bool: false,
  });

  useEffect(() => {
    let myModal1: any = document.getElementById("AllowBgLocation");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      let AllowBgLocation: any = document.getElementById("AllowBgLocation");
      myModal = new bootstrap.Modal(AllowBgLocation, {});
      myModal.show();
    }
  }, [props.open]);

  async function grantedBgLocation() {
    let pass_data = {
      post: {
        background_location_allowed: 1,
      },
    };
    let data: any = await api("/account/save_settings", pass_data);

    if (data.status_code == 200) {
      let item: any = close_data;
      item.action = "save";
      item.id = props.id;
      item.settings = data.response.settings;
      SetCloseData(item);
      myModal.hide();
    }
  }

  return (
    <div
      className="modal fade fade-up"
      id="AllowBgLocation"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-fullscreen">
        <div className="modal-content">
          <div className="modal-body py-4">
            <div className="d-flex flex-column h-100 text-center">
              <h5 className="mb-3">Background Location</h5>
              <div className="fs-13 text-muted">
                1SuperApp collects location data of the Delivery Partner to find nearby pickup drop orders, food delivery order, and to share your location to ordered customers even when the app is closed or not in use.
              </div>
              <div className="mt-auto">
                <img
                  className="img-fluid"
                  width="120"
                  src={require("../../assets/img/map.png")}
                />
              </div>
              <div className="pt-1 mt-auto">
                <button
                  type="button"
                  className="btn text-secondary w-100 fw-500 mb-2"
                  data-bs-dismiss="modal"
                >
                  Not Now
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-lg br-20 w-100 fw-500"
                  onClick={(e) => {
                    grantedBgLocation();
                  }}
                >
                  Allow Background Location
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
